<template>
  <component
    :is="componentTag"
    v-bind="componentAttrs"
    :class="getClassName"
    @click="setTab"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'Tab'
})
export default class Tab extends Vue {
  /**
   * Indíce interno para controle de renderização nos `TabPanels`
   */
  @Prop({ type: Number, default: 0 })
  private index!: number;

  /**
   * Marca a tab como ativa
   */
  @Prop({ type: Boolean, default: false })
  private actived!: boolean;

  /**
   * Caso a tab esteja desativada
   */
  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  /**
   * Transforma a tab em um link `<a>`. Útil para integração com Routers
   */
  @Prop({ type: String })
  private href?: string;

  private get componentTag() {
    return this.href ? 'a' : 'button';
  }

  private get componentAttrs() {
    const buttonAttrs = { type: 'button', disabled: this.disabled };
    const linkAttrs = {
      href: this.href,
      tabindex: this.disabled ? '-1' : '0',
      'aria-disabled': this.disabled
    };
    return this.componentTag === 'button' ? buttonAttrs : linkAttrs;
  }

  private get getClassName() {
    return [
      'tab-container',
      this.actived ? 'tab-container--actived' : '',
      this.disabled ? 'tab-container--disabled' : ''
    ];
  }

  private setTab() {
    this.$emit('set-tab', this.index);
  }
}
</script>

<style lang="less" scoped>
.tab-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 1;
  color: @text-primary;
  background: none;
  border: none;
  border-bottom: @size-border-x500 solid transparent;
  transition: border 0.1s linear;
  text-decoration: none;

  &:not(&--actived):not(&--disabled):hover {
    border-color: @neutral-primary;
  }
}

.tab-container--actived {
  border-color: @neutral-primary;

  &.tab-container--disabled {
    border-color: @element-disabled;
  }
}

.tab-container--disabled {
  color: @element-disabled;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
