<template>
  <div>
    <NavBar :transparent="isTransparent" sticky class="selection-step__nav-bar">
      <template #left>
        <ButtonIcon
          :colors="closeButtonColors"
          label="Voltar"
          icon="EA0030"
          @click="emitBack"
        />
      </template>
      <template #center>
        <p>{{ pageTitle }}</p>
      </template>
    </NavBar>
    <section class="selection-step__container">
      <div class="selection-step__content">
        <HeadingDisplay
          class="selection-step__header"
          :title="title"
          :subtitle="subtitle"
          :head="head"
        >
          <template #heading-component>
            <slot name="heading-component" />
          </template>
          <template #subtitle-component>
            <slot name="subtitle-component" />
          </template>
        </HeadingDisplay>
        <SelectionBox
          v-model="currentValue"
          :aria-label="ariaLabel"
          :aria-labelledby="ariaLabelledby"
          :type="type"
          :value="value"
          :options="options"
          :icon="icon"
          @change="emitChange"
        />
      </div>
    </section>
    <ButtonContainer class="selection-step__button-container">
      <template #button-primary>
        <ButtonPrimary
          size="large"
          :label="buttonLabel"
          :disabled="disabled"
          @click="emitSubmit"
        />
      </template>
    </ButtonContainer>
  </div>
</template>

<script lang="ts">
import {
  SelectionBox,
  SelectionBoxOption,
  SelectionBoxValue,
  SelectionBoxType
} from '@/components/selection-box';
import { NavBar } from '@/components/navbar';
import { ButtonPrimary } from '@/components/button';
import { HeadingDisplay } from '@/components/heading';
import { ButtonContainer } from '@/components/button-container';
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import ButtonIcon from '@/foundation/button-icon/ButtonIcon.vue';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import throttle from 'lodash.throttle';

@Component({
  components: {
    NavBar,
    ButtonIcon,
    HeadingDisplay,
    SelectionBox,
    ButtonContainer,
    ButtonPrimary
  }
})
export default class SelectionStep extends Vue {
  /**
   * Título da página a ser exibido na NavBar
   */
  @Prop({ type: String, required: true })
  readonly pageTitle!: string;

  /**
   * Título exibido no Heading Display
   */
  @Prop({ type: String, required: true })
  readonly title!: string;

  /**
   * Texto a ser exibido no Head | Subhead (acima do title)
   */
  @Prop({ type: [Array, String], default: '' })
  readonly head!: string[] | string;

  /**
   * Texto a ser exibido no Subtitle (abaixo do title)
   */
  @Prop({ type: String, default: '' })
  readonly subtitle!: string;

  /**
   * Label que irá descrever o contexto do grupo de seleção.
   * Deve ser utilizada quando não há uma label no documento para associar ao grupo.
   *
   * <b>Exemplo:</b>
   * ```<SelectionBox aria-label="Seleção de prazo de resgate>```
   * */
  @Prop({ type: String, required: false })
  readonly ariaLabel?: string;

  /** Id da label que irá descrever o contexto do grupo de seleção.
   * Deve ser utilizada quando há uma label presente no documento que pode ser associada a esse grupo.
   *
   * <b>Exemplo:</b>
   *```
   * <h2 id="selection-label">Seleção de prazo de resgate:</h2>
   * <SelectionBox aria-labelledby="selection-label">```
   */
  @Prop({ type: String, required: false })
  readonly ariaLabelledby?: string;

  /**
   * Tipo de seleção: ```single | multiple```
   * */
  @Prop({ type: String, default: SelectionBoxType.Single })
  readonly type!: SelectionBoxType;

  /**
   * Valor ou valores selecionados
   */
  @Prop({ type: [String, Number, Array], required: true })
  readonly value!: SelectionBoxValue;

  /**
   * Lista de opções para seleção
   */
  @Prop({ type: Array, required: true })
  readonly options!: SelectionBoxOption[];

  /**
   * O ícone a ser exibido quando a opção estiver selecionada
   */
  @Prop({ type: String, default: 'EF0070' })
  readonly icon!: string;

  /**
   * Texto a ser exibido no Botão
   */
  @Prop({ type: String, default: 'Continuar' })
  readonly buttonLabel!: string;

  /**
   * Informa se o botão deve estar desabilitado
   */
  @Prop({ type: Boolean, default: true })
  readonly disabled!: boolean;

  /**
   * Evento disparado ao clicar no botão de 'Voltar' na NavBar
   */
  @Emit('back')
  emitBack() {
    // Função que emite evento back
  }

  /**
   * Evento disparado ao clicar nas opções
   */
  @Emit('change')
  emitChange() {
    // Função que emite evento change
  }

  /**
   * Evento disparado ao clicar no botão de confirmação
   */
  @Emit('submit')
  emitSubmit() {
    // Função que emite evento submit
  }

  currentValue = this.value;
  scrollY = 0;

  scrolledThrottled: (() => void) | null = null;

  get isTransparent() {
    return this.scrollY === 0;
  }

  get closeButtonColors() {
    return {
      background: NebraskaColors.elementSecondary,
      content: NebraskaColors.elementPrimary,
      alphas: {
        default: 0,
        hover: 0.08,
        active: 0.08
      }
    };
  }

  scrolled() {
    this.scrollY = window ? window.scrollY : 0;
  }

  created() {
    this.scrolledThrottled = throttle(this.scrolled, 150);
    window.addEventListener('scroll', this.scrolledThrottled);
  }

  destroyed() {
    if (this.scrolledThrottled) {
      window.removeEventListener('scroll', this.scrolledThrottled);
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/_medias';

.selection-step {
  &__nav-bar,
  &__header,
  &__content {
    margin-bottom: @size-spacing-x800;
  }

  &__container {
    padding: 0 @size-spacing-x500;

    .to-lg({ padding: 0 @size-spacing-x400;});
  }

  &__content {
    max-width: 592px;
    margin: 0 auto @size-spacing-x1800;
  }

  &__button-container {
    background-color: @background-secondary;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 20;
  }
}
</style>
