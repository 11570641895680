<template>
  <div class="shortcut__container">
    <Button
      v-for="(data, index) in values"
      :key="index"
      data-testid="shortcut-value"
      :colors="ButtonColors"
      :disabled="disabled"
      class="shortcut__item"
      @click="handleClick(data)"
    >
      {{ data.formatted }}
    </Button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import Button from '@/foundation/button/Button.vue';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { ShortcutValueType } from './types';

@Component({
  components: {
    Button
  }
})
export default class ShortcutValue extends Vue {
  /**
   * Array com todas as opções da lista
   */
  @Prop({ type: Array, required: true })
  readonly values!: ShortcutValueType[];

  /**
   * Informa se os botões devem estar desabilitados
   */
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  /**
   * Evento disparado ao clicar nos botões
   */
  @Emit('click')
  public handleClick(_data: ShortcutValueType) {
    // Função emitida no click do botão
  }

  readonly ButtonColors = {
    background: NebraskaColors.backgroundTertiary,
    content: NebraskaColors.textPrimary,
    alphas: {
      default: 1,
      hover: 0.9,
      active: 0.8
    }
  };
}
</script>

<style lang="less" scoped>
.shortcut {
  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: @size-spacing-x150;
  }

  &__item {
    .text-p-5();
    padding: @size-spacing-x300 @size-spacing-x350;
    border-radius: @size-radius-x200;

    &:hover {
      background: @background-hover;
    }
  }
}
</style>
