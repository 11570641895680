<template>
  <div
    v-if="isOpen"
    autofocus
    tabindex="0"
    class="base-modal--container"
    @keydown.esc="closeModal"
  >
    <Overlay class="overlay" @click="closeModal" />
    <div class="base-modal--wrapper" :class="classSize">
      <div class="base-modal--header" data-e2e="modalHeader">
        <!-- @slot onteúdo posicionado na barra superior (geralmente o título) -->
        <slot name="header" />
      </div>

      <ScrollShadow
        ref="content"
        class="base-modal--content"
        :style="customStyles"
      >
        <!-- @slot conteúdo -->
        <slot name="content" />
      </ScrollShadow>

      <div class="footer" data-e2e="modalFooter">
        <!-- @slot conteúdo posicionado na barra inferior (geralmente botões de ação) -->
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Ref } from 'vue-property-decorator';
import { BaseModalPublicRef, ModalSizes } from './types';
import Overlay from '@/components/overlay/Overlay.vue';
import ScrollShadow from '@/components/scroll-shadow/ScrollShadow.vue';

@Component({
  name: 'BaseModal',
  components: { ScrollShadow, Overlay }
})
export default class BaseModal extends Vue implements BaseModalPublicRef {
  /**
   * Indica se a modal está aberta e deve ser renderizada
   */
  @Prop({ type: Boolean, default: false })
  public isOpen!: boolean;

  /**
   * Define o tamanho da janela do conteúdo
   */
  @Prop({ type: String, default: ModalSizes.SMALL })
  public size!: ModalSizes;

  /**
   * Define uma largura customizada para o slot de conteúdo
   * @deprecated
   */
  @Prop({ type: [Number, String], required: false })
  public customWidth?: number | string;

  /**
   * Define uma altura customizada para o slot de conteúdo
   * @deprecated
   */
  @Prop({ type: [Number, String], required: false })
  public customHeight?: number | string;

  @Ref('content')
  private modalContent!: ScrollShadow;

  /**
   * Evento disparado a cada ação relacionada ao fechamento do modal: interação, clique na camada de overlay, botão fechar, tecla ESC etc.
   */
  @Emit('close')
  private closeModal() {
    // Função que emite evento
  }

  private get classSize() {
    return `size-${this.size}`;
  }

  private get customStyles() {
    return {
      'max-height': this.customHeight && `${this.customHeight}px`,
      width: this.customWidth && `${this.customWidth}px`
    };
  }

  scrollContentTo(options: ScrollToOptions) {
    this.modalContent.$el.scroll(options);
  }
}
</script>

<style lang="less" scoped>
@mobile: ~'only screen and (max-width: 480px)';

button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.base-modal--container {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 5000;
}

.base-modal--header {
  position: relative;
}

.base-modal--wrapper {
  background: @background-secondary;
  border-radius: @size-radius-x300;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.15); // não temos token para isso ainda
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @media @mobile {
    width: 100% !important;
    height: 100% !important;
    max-width: none !important;
    max-height: none !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }

  &.size-lg {
    align-self: flex-end;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0;
    max-width: 1200px;
    margin: auto;
    width: 90%;
    height: ~'calc(100% - 24px)';
  }

  &.size-md {
    width: 100%;
    max-width: 550px;
    max-height: 80vh;
  }

  &.size-md-2 {
    max-width: 1050px;
    max-height: 90vh;
  }

  &.size-sm {
    max-width: 440px;
    max-height: 80vh;
  }

  &.size-custom {
    width: 960px;
    height: 832px;
  }
}

.base-modal--content {
  flex: 1 1;

  @media @mobile {
    max-height: none !important;
  }
}
</style>
