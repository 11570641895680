<template>
  <div class="input-wrapper" :class="{ error: hasError }">
    <div v-if="leftIconName" class="icon">
      <BaseIcon
        v-if="leftIconName"
        :icon="leftIconName"
        :colors="iconTextColor"
      />
    </div>
    <input
      ref="inputRef"
      v-bind="$attrs"
      :value="value"
      v-on="$listeners"
      @input="handleInput"
    />
    <div v-if="rightIconName" class="icon">
      <RoundedIcon
        v-if="rightIconName"
        :has-hover="rightIconHasFunction"
        :hover-color="NebraskaColors.elementDisabled"
        :icon-name="rightIconName"
        icon-size="24"
        main-color="transparent"
        :text-color="iconTextColor"
        @click.native="onRightIconClick"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Ref } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import RoundedIcon from '../rounded-icon/RoundedIcon.vue';
/**
 * @deprecated
 */
@Component({
  name: 'Input',
  inheritAttrs: false,
  components: {
    BaseIcon,
    RoundedIcon
  },
  model: {
    event: 'model'
  }
})
export default class Input extends Vue {
  @Prop({ type: String, required: true })
  private value!: string;

  @Prop({ type: String, required: false })
  private leftIconName?: string;

  @Prop({ type: String, required: false })
  private rightIconName?: string;

  @Prop({ type: Boolean, default: false })
  private rightIconHasFunction!: boolean;

  @Prop({ type: Boolean, required: false })
  private hasError?: boolean;

  @Prop({ type: Boolean, default: false })
  private isFocus!: boolean;

  @Ref()
  private inputRef!: HTMLInputElement;

  private NebraskaColors = NebraskaColors;

  private setFocus() {
    this.$nextTick(() => this.inputRef.focus());
  }

  private created() {
    if (this.isFocus) {
      this.setFocus();
    }
  }

  private onRightIconClick() {
    if (this.rightIconHasFunction) {
      this.iconClick();
    }
  }

  private get iconTextColor(): string {
    const inputHasDisabledAttr = !!this.$attrs.disabled;

    return inputHasDisabledAttr
      ? this.NebraskaColors.textPlaceholder
      : this.NebraskaColors.elementPrimary;
  }

  @Emit('icon-click')
  public iconClick() {
    // Função que emite evento
  }

  private handleInput(event: Event) {
    const input = event.target as HTMLInputElement;
    this.$emit('model', input.value);
    this.$emit('input', event);
  }
}
</script>

<style lang="less" scoped>
.input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: @size-border-x400 solid @background-primary;
  border-radius: @size-radius-x300;
  background-color: @background-primary;
  height: 58px;
  padding: 0 @size-spacing-x400;
  transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);

  &:focus-within {
    border: @size-border-x400 solid @neutral-primary;
  }

  &.error {
    border: @size-border-x400 solid @element-on-error;
  }

  input {
    height: 100%;
    width: 100%;
    flex-grow: 1;
    background: transparent;
    border: none;
    outline: none;
    padding: 0 @size-spacing-x150;
    color: @neutral-primary;

    &:disabled {
      color: @element-disabled !important;
    }

    /* Removes mobile unwanted highlight border on Chrome/Firefox*/
    &:-webkit-autofill {
      transition: background-color 0s ease-in-out 1000000s;
    }
  }
}

.right-icon-selectable {
  cursor: pointer;
  transition: 0.2s ease-in all;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 @size-spacing-x150;
}
</style>
