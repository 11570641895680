<template>
  <span
    data-testid="rounded-icon"
    data-cy="rounded-icon"
    :class="computedClass"
    :style="computedStyle"
    @mouseover="setHover(true)"
    @mouseleave="setHover(false)"
    v-on="$listeners"
  >
    <span
      v-if="text"
      class="text"
      :style="{
        'font-size': `${iconSize || 23}px`,
        width: `${backgroundSize}px`,
        height: `${backgroundSize}px`
      }"
    >
      {{ text }}
    </span>

    <BaseIcon
      v-if="!!iconName && !!!text"
      class="image"
      :icon="iconName"
      :width="iconSize"
      :height="iconSize"
      :colors="computedTextColor"
    />
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
/**
 * @deprecated
 */
@Component({
  name: 'RoundedIcon',
  components: { BaseIcon }
})
export default class RoundedIcon extends Vue {
  @Prop({ type: String, required: false }) public text?: string;

  @Prop({ type: String, required: false }) public mainColor?: string;

  @Prop({ type: String, required: false }) public hoverColor?: string;

  @Prop({ type: String, required: false }) public textColor?: string;

  @Prop({ type: String, required: false }) public hoverTextColor?: string;

  @Prop({ type: String, required: false }) public iconName?: string;

  @Prop({ type: String, required: false }) public iconSize?: string;

  @Prop({ type: String, default: '32' }) public backgroundSize?: string;

  @Prop({ type: Boolean, required: false }) public hasHover!: boolean;

  private hover = false;

  private get computedClass(): string[] {
    const computedClass = ['wrn-initial', 'rounded-item'];
    if (this.hasHover) {
      computedClass.push('animated-transition');
    }
    return computedClass;
  }

  private get computedStyle() {
    return {
      background: this.computedBGColor,
      color: this.computedTextColor,
      width: `${this.backgroundSize}px`,
      height: `${this.backgroundSize}px`
    };
  }

  private get computedBGColor(): string {
    let bgColor = this.mainColor || NebraskaColors.elementPrimary;

    if (this.hasHover && this.hover) {
      bgColor = this.hoverColor || '#fff';
    }

    return bgColor;
  }

  private get computedTextColor(): string {
    let color = this.textColor || NebraskaColors.textNegative;

    if (this.hasHover && this.hover) {
      color = this.hoverTextColor || NebraskaColors.elementPrimary;
    }

    return color;
  }

  private setHover(value: boolean): void {
    if (this.hasHover) {
      this.hover = value;
    }
  }
}
</script>

<style lang="less" scoped>
.rounded-item {
  .text-p-2();
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  padding: 0;
  position: relative;

  background-color: @background-overlay;
  border-radius: @size-radius-max;
  text-decoration: none;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}

.animated-transition {
  display: inline-block;
  cursor: pointer;
  transition: 0.2s cubic-bezier(0.55, 0, 0.1, 1);

  &:active {
    transform: translateY(@size-spacing-x150);
  }
}
</style>
