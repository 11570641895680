<template>
  <div class="columns-grid" :class="computedClasses" :style="computedStyle">
    <!-- @slot Conteúdo em colunas -->
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';

@Component({
  name: 'Grid'
})
export default class Grid extends Vue {
  /**
   * Array que define a quantidade e tamanho das colunas
   */
  @Prop({ type: Array })
  public columns?: number[];

  /**
   * Espaçamento entre as colunas
   */
  @Prop({ type: [Number, String] })
  public gutter?: number | string;

  @Ref()
  private readonly grid!: HTMLDivElement;

  private get computedClasses() {
    const gutter = this.gutter;
    const gridClasses: string[] = [];

    if (gutter && typeof gutter === 'string') {
      gridClasses.push(`columns-grid-${gutter}`);
    }

    return gridClasses.length > 0 ? gridClasses.join(' ') : '';
  }

  private get computedStyle() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const gridStyles: any = {};

    if (this.columns) {
      gridStyles['grid-template-columns'] = this.columns
        .map(col => `${col}fr`)
        .join(' ');
    }

    if (this.gutter && typeof this.gutter === 'number') {
      gridStyles['column-gap'] = `${this.gutter}px`;
    }

    return gridStyles;
  }
}
</script>

<style lang="less" scoped>
.columns-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  @sizes: xxxs xxs xs s m xm xxm xxxm l xl xxl xxxl xxxxl;
  each(@sizes, {
    &.columns-grid-@{value} {
      @spacing: "spacing-@{value}";
      column-gap: @@spacing;
    }
  });
}
</style>
