<template>
  <section class="message">
    <div v-if="loader" class="message__loader">
      <BaseIcon
        :spin="loader"
        class="loader--icon"
        icon="ic_spinner"
        :colors="NebraskaColors.elementSecondary"
        width="24"
        height="24"
        aria-label="Carregando"
      />
    </div>
    <div v-else class="message__content">
      <div v-if="$slots['icon']" class="message__icon" aria-hidden="true">
        <!-- @slot Conteúdo à esquerda  -->
        <slot name="icon" />
      </div>
      <div class="message__text">
        <component :is="titleElement" class="message__title">
          {{ title }}
        </component>
        <div
          v-if="$slots['description']"
          data-testid="description"
          class="message__description"
          aria-label="Descrição"
        >
          <!-- @slot Conteúdo da mensagem -->
          <slot name="description" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseText from '@/components/base-text/BaseText.vue';

@Component({
  name: 'Message',
  components: { BaseIcon, BaseText }
})
export default class Message extends Vue {
  /**
   * O título a ser exibido
   */
  @Prop({ type: String, required: false })
  readonly title?: string;

  /**
   * Coloca o message no estado "carregando"
   */
  @Prop({ type: Boolean, default: false })
  readonly loader!: boolean;

  /**
   * Define qual elemento será o título
   */
  @Prop({ type: String, default: 'p' })
  private titleElement!: string;

  readonly NebraskaColors = NebraskaColors;
}
</script>

<style lang="less" scoped>
.message {
  padding: @size-spacing-x500;
  border-radius: @size-radius-x200;
  background: @background-primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    align-items: center;
    justify-content: center;
    margin-right: @size-spacing-x500;
  }

  &__title {
    .text-p-4();
    margin: 0;
    font-weight: 600;
    margin-bottom: @size-spacing-x150;
    color: @element-primary;
  }

  &__description {
    .text-p-5();
    margin: 0;
    color: @element-secondary;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: @size-spacing-x350 0;
  }
}
</style>
