<template>
  <div v-show="actived">
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'TabPanel'
})
export default class TabPanel extends Vue {
  @Prop({ type: Boolean, default: false })
  private actived!: boolean;
}
</script>
