<template>
  <DonutChartFoundation
    :is-loading="isLoading"
    :has-error="hasError"
    :enable-hover="enableHover"
    :data-set="dataSet"
    :color-scheme="colorScheme"
    :border-color="borderColor"
    @on-error-click="onErrorClick"
    @hover-allocation="onHoverAllocation"
    @on-slice-click="onSliceClick"
  >
    <slot />
  </DonutChartFoundation>
</template>

<script lang="ts">
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { PointClickEventObject } from 'highcharts';
import {
  DonutChartFoundation,
  IDonutChartData
} from '@/charts/foundation/DonutChartFoundation';
import { defaultScheme } from '@/charts/helpers';

@Component({
  name: 'DonutChart',
  components: {
    DonutChartFoundation
  }
})
export default class DonutChart extends Vue {
  /**
   * Alterna a exibição do componente padrão de loading.
   */
  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  /**
   * Alterna a exibição do componente padrão de erro.
   */
  @Prop({ type: Boolean, default: false })
  readonly hasError!: boolean;

  /**
   * Habilita o efeito visual quando o usuário passa o mouse por cima dos slices do donut.
   */
  @Prop({ type: Boolean, default: true })
  readonly enableHover!: boolean;

  /**
   * Popula o gráfico com os dados fornecidos.
   *
   * @param {object[]} dataset Uma lista de objetos com os seguintes atributos:
   * - `name` Uma string com o nome que dá significado ao valor do objeto.
   * - `y` Um number que dá o valor que este objeto representa. A proporção do gráfico é definida pelo highcharts com este valor.
   * - `color` (opcional) Uma cor que represente o valor do objeto.
   * - `format` (opcional) Uma string adicional para gráficos específicos. Veja o componente `DonutChartAllocation`.
   */
  @Prop({
    type: Array,
    default: () => []
  })
  readonly dataSet!: IDonutChartData[];

  /**
   * Popula o gráfico com um esquema de cores. Esta propriedade é utilizada somente quando não são fornecidos os atributos de cor nos objetos da prop `dataSet`.
   * As cores serão exibidas em ordem sequencial.
   */
  @Prop({
    type: Array,
    default: () => defaultScheme
  })
  readonly colorScheme!: [];

  /**
   * Define a cor da borda das porções do gráfico.
   * Necessário ser alterada quando o fundo onde está o gráfico é diferente da cor borda.
   */
  @Prop({
    type: String,
    required: false
  })
  readonly borderColor?: string;

  /**
   * Emite o evento para o click no botão do componente ErrorLabel
   */
  @Emit('on-error-click')
  private onErrorClick() {
    // event on error label click
  }

  /**
   * Quando o hover está habilitado, este evento emite o índice do slice que está em estado de "hover", ou pode retornar null caso nenhum elemento apresente este estado.
   */
  @Emit('hover-allocation')
  private onHoverAllocation(indexHover: number | null) {
    return indexHover;
  }

  /**
   * Emite um evento quando o usuário clica em um slice do donut
   * Retorna o evento o objeto PointerEvent com as informações do slice clicado
   */
  @Emit('on-slice-click')
  private onSliceClick(event: PointClickEventObject) {
    return event;
  }
}
</script>
