<template>
  <ButtonLabel
    :tabindex="tabindex"
    :href="href"
    :disabled="disabled"
    :loading="loading"
    :colors="computedColors"
    :size="size"
    :icon="icon"
    :icon-position="iconPosition"
    data-testid="button-secondary"
    :class="computedOverImageClass"
    v-on="$listeners"
  >
    {{ label }}
  </ButtonLabel>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonLabel from '@/foundation/button-label/ButtonLabel.vue';
import {
  ButtonApplication,
  ButtonSizes,
  IconPosition
} from '@/foundation/types';
import { buttonSecondaryColors, secondaryOverImage } from '@/styles/Button';
import { ButtonColors } from '@/foundation/models/Button';

@Component({
  name: 'ButtonSecondary',
  components: { ButtonLabel }
})
export default class ButtonSecondary extends Vue {
  /**
   * O rótulo do botão
   */
  @Prop({ type: String, required: true })
  readonly label!: string;

  /**
   * Permite atribuir um link ao botão
   */
  @Prop({ type: String })
  readonly href?: string;

  /**
   * Desativa o botão
   */
  @Prop({ type: Boolean })
  readonly disabled?: boolean;

  /**
   * Coloca o botão no estado "carregando"
   */
  @Prop({ type: Boolean })
  readonly loading?: boolean;

  /**
   * Define qual a posição do elemento durante a navegação pelo teclado. Na maioria dos casos, não é necessário alterar o valor padrão.
   * @see {@link https://developer.mozilla.org/pt-BR/docs/Web/HTML/Global_attributes/tabindex}
   */
  @Prop({ type: Number })
  readonly tabindex?: number;

  /**
   * Define o tamanho do botão
   * @values regular, large
   */
  @Prop({ type: String, default: ButtonSizes.REGULAR })
  readonly size!: ButtonSizes;

  /**
   * O ícone a ser exibido
   */
  @Prop({ type: String })
  readonly icon?: string;

  /**
   * A posição do ícone
   * @values left, right
   */
  @Prop({
    type: String,
    default: IconPosition.LEFT
  })
  readonly iconPosition!: IconPosition;

  /**
   * Define qual a superfície onde o botão está inserido
   * @values normal, overImage
   */
  @Prop({
    type: String,
    default: ButtonApplication.NORMAL
  })
  readonly appliedOn!: ButtonApplication;

  private get computedColors(): ButtonColors {
    switch (this.appliedOn) {
      case ButtonApplication.OVER_IMAGE:
        return secondaryOverImage;
      case ButtonApplication.NORMAL:
      default:
        return buttonSecondaryColors;
    }
  }

  private get computedOverImageClass(): string {
    return this.appliedOn === ButtonApplication.OVER_IMAGE
      ? 'button-container--over-image'
      : '';
  }
}
</script>

<style lang="less" scoped>
.button--container:not(.button-container--over-image) {
  &:hover,
  &:active,
  &.loading {
    color: @theme-primary !important;
  }
}
</style>
