<template>
  <span :class="computedClasses">
    <BaseIcon
      v-if="icon"
      class="chip--icon"
      colors="currentColor"
      :width="computedIconSize"
      :icon="icon"
    />
    {{ label }}
  </span>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import { ChipSizes } from '@/foundation/types';

@Component({
  name: 'ChipFoundation',
  components: { BaseIcon }
})
export default class ChipFoundation extends Vue {
  /**
   * Texto exibido no Chip
   */
  @Prop({ type: String, required: true })
  readonly label!: string;

  /**
   * Nome do ícone utilizado no Chip
   * @see https://docs.dev.warren.com.br/nebraska/?path=/docs/assets-icons--all-icons
   */
  @Prop({ type: String, required: false })
  readonly icon?: string;

  /**
   * Define o tamanho do Chip
   * @values regular, large
   */
  @Prop({ type: String, default: ChipSizes.REGULAR })
  readonly size!: string;

  public get computedClasses() {
    return ['chip', `chip--size-${this.size}`];
  }

  public get computedIconSize() {
    return this.size === ChipSizes.LARGE ? '18' : '16';
  }
}
</script>

<style lang="less" scoped>
.chip {
  .text-p-6();
  padding: @size-spacing-x150 @size-spacing-x300;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: @size-radius-x100;
  color: @element-primary;

  &--icon {
    margin-right: @size-spacing-x150;
  }

  &--size-large {
    .text-p-5();
    border-radius: @size-radius-x200;
  }
}
</style>
