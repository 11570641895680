<template>
  <div class="data-table">
    <DataTableHeading
      v-if="!isChild"
      :has-checkbox="hasCheckbox"
      :has-heading="hasHeading"
    >
      <template #left>
        <!-- @slot Conteúdo à esquerda do cabeçalho da tabela mostrado quando checkbox está ativo -->
        <slot name="heading-left" />
      </template>
      <template #right>
        <!-- @slot Conteúdo à direita do cabeçalho da tabela -->
        <slot name="heading-right" />
      </template>
    </DataTableHeading>

    <Table v-if="dataTableStore.data" :header="getColumns" :is-child="isChild">
      <template #header>
        <TableRow
          is-header
          :columns="getColumns"
          :is-child="isChild"
          @on-tooltip-click="handleTooltipClick"
        />
      </template>
      <template #body>
        <template v-if="dataTableStore.status === DataTableStatus.Loading">
          <TableRow
            v-for="key in itemsPerPage"
            :key="key"
            data-testid="loading-row"
            :columns="Array(colspan).fill({})"
            is-loading
          />
        </template>
        <template
          v-for="row in rows"
          v-else-if="dataTableStore.status === DataTableStatus.Default"
        >
          <TableSubHeading
            v-if="row.type === 'subHeading'"
            :key="row._hash"
            :left-text="row.leftText"
            :right-text="row.rightText"
            :columns="getItemColumns(row)"
          />
          <TableRow
            v-else
            :key="`row-${row._hash}`"
            :data-testid="rowTestId"
            :columns="getItemColumns(row)"
            :show-divider="showDivider"
            :active="isOpen(row._hash)"
            :has-interaction="hasInteraction"
            :disabled="row.disabled"
            @on-button-click="emitOnButtonClick"
            @on-row-click="emitOnRowClick"
          />
          <TableRow
            v-if="childColumns"
            :key="`row-${row._hash}-child`"
            is-content
          >
            <td v-if="isOpen(row._hash)" :colspan="colspan">
              <DataTable
                is-child
                :columns="childColumns"
                :data-params="row"
                :load-data="loadDataChild"
                :show-divider="showDivider"
              />
            </td>
          </TableRow>
        </template>
      </template>
      <template #after>
        <template v-if="dataTableStore.status === DataTableStatus.Error">
          <!-- @slot Conteúdo renderizado quando a tabela está em estado erro -->
          <slot name="error">
            <DataTableFeedbackMessage
              title="Um erro foi identificado"
              description="Nenhum dado pode ser mostrado"
            />
          </slot>
        </template>
        <template
          v-else-if="
            notFound && dataTableStore.status === DataTableStatus.Default
          "
        >
          <!-- @slot Conteúdo renderizado quando a tabela está sem dados -->
          <slot name="empty">
            <DataTableFeedbackMessage
              title="Não há conteúdo para ser mostrado"
              description="Verifique os filtros ou tente recarregar a página"
            />
          </slot>
        </template>
      </template>
    </Table>
    <TooltipModal
      :is-open="isTooltipModalOpen"
      :columns="columns"
      :selected-column-index="tooltipSelectedColumnIndex"
      @close="toggleTooltipModal"
    />
  </div>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  ProvideReactive,
  Vue,
  Watch
} from 'vue-property-decorator';
import BaseCell from './TableCells/BaseCell.vue';
import DataTableHeading from './DataTableHeading.vue';
import DataTableFeedbackMessage from './DataTableFeedbackMessage.vue';
import HeaderCell from './TableCells/HeaderCell.vue';
import Table from './Table.vue';
import TableRow from './TableRow.vue';
import ToggleCell from './TableCells/ToggleCell.vue';
import TableSubHeading from './TableSubHeading.vue';
import TooltipModal from './TooltipModal.vue';

import { addHash, getItemColumns, getSortableFilter } from './DataTableHelpers';
import {
  Column,
  DataParams,
  DataTableStatus,
  DataTableStore,
  Filter,
  LoadData,
  Row,
  Sort,
  SortableFilter
} from './DataTableProps';

@Component({
  components: {
    BaseCell,
    DataTableFeedbackMessage,
    DataTableHeading,
    HeaderCell,
    Table,
    TableRow,
    ToggleCell,
    TableSubHeading,
    TooltipModal
  },
  name: 'DataTable'
})
export default class DataTable extends Vue {
  /**
   * Objeto usado para "ensinar" o componente à apresentar/parsear
   * os dados em cada célula.
   */
  @Prop({ required: false, type: Array, default: () => [] })
  private columns!: Column[];

  /**
   * Objeto usado para "ensinar" a Tabela filha de cada linha
   * à apresentar/parsear os dados em cada célula.
   */
  @Prop({ required: false, type: Array })
  private childColumns?: Column[];

  /**
   * Propriedade usada para indicar a quantidade de linhas
   * que a tabela deve renderizar na hora do loading
   */
  @Prop({ required: false, type: Number, default: 5 })
  private itemsPerPage!: number;

  /**
   * Objeto usado como parâmetro do método "getData".
   * Caso esse objeto mude, o método "getData" é executado novamente.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @Prop({ required: false, type: Object, default: () => {} })
  private dataParams!: DataParams;

  /**
   * Método usado para filtrar as linhas da tabela.
   * type Filter<Row=any> = (rows: Row[]) => Row[]
   */
  @Prop({ required: false, type: Function, default: null })
  private filter?: Filter | null;

  /**
   * Propriedade para ativar a opção de checkbox na tabela
   */
  @Prop({ required: false, type: Boolean, default: false })
  private hasCheckbox!: boolean;

  /**
   * Propriedade para ativar a opção de possuir o Action Bar superior na tabela, adicionando conteúdo referente a tabela.
   */
  @Prop({ required: false, type: Boolean, default: false })
  private hasHeading!: boolean;

  /**
   * Propriedade para ativar a opção de  tabela filhas, não é necessario ser declarada pois a utilização do `loadDataChild` já habilita essa opção.
   */
  @Prop({ required: false, type: Boolean, default: false })
  private isChild!: boolean;

  /**
   * Método assíncrono que é executado no ciclo mounted do
   * componente ou toda a vez que o dataParams mudar.
   */
  @Prop({ required: false, type: Function })
  private loadData?: LoadData;

  /**
   * Método assíncrono que é executado no ciclo mounted
   * da tabela filha, recebendo como parâmetro os dados da linha.
   */
  @Prop({ required: false, type: Function })
  private loadDataChild?: LoadData;

  /**
   * Flag usada para mostrar ou esconder uma linha divisória.
   * Tem efeito nas tabelas filhas também
   */
  @Prop({ type: Boolean, default: false, required: false })
  private showDivider!: boolean;

  /**
   * Flag usada para mostrar visualmente que a linha é clicável
   */
  @Prop({ type: Boolean, default: true })
  private hasInteraction!: boolean;

  /**
   * Uma forma de passar os dados para a tabela com maior controle, não sendo necessário
   * a utilização da prop loadData para fazer a requisição e setar os dados internamente
   */
  @Prop({ type: Array, required: false })
  readonly data?: Row[];

  /**
   * Informa o estado da tabela, se está está em estado de carregamento, erro ou padrão
   * @values default, error, loading
   */
  @Prop({
    type: String,
    required: false,
    validator(statusName: DataTableStatus) {
      const validStatus = Object.values(DataTableStatus);
      return validStatus.includes(statusName);
    }
  })
  readonly status?: DataTableStatus;

  /**
   * Evento emitido quando o usuário clica em uma coluna que é ordenável
   * @param activeColumnIndex Indíce da coluna que foi ordenada, se for -1 quer dizer que nenhuma coluna está ordenada
   * @param isOrderAsc Indica se a ordenação é ascendente, se for "false" quer dizer que é descendente
   * @param sort É o valor da propriedade sort da coluna que está sendo ordenada, esse valor pode ser informado através da prop columns
   */
  @Emit('on-sort')
  emitOnSort(_activeColumnIndex: number, _isOrderAsc: boolean, _sort: Sort) {
    // intentional blank function
  }

  /**
   * Evento emitido quando o usuário clica em um botão de uma coluna do tipo "button"
   * @param row dados do item da linha
   */
  @Emit('on-button-click')
  emitOnButtonClick(_row: Row) {
    // Função que emite evento on-button-click
  }

  /**
   * Evento emitido quando o usuário clica na Row
   * @param row dados do item da linha
   */
  @Emit('on-row-click')
  emitOnRowClick(_row: Row) {
    // Função que emite evento on-row-click
  }

  private checkeds: string[] = [];
  private tableData: Row[] = [];
  private openedItem: string | null = null;
  private activeSortableHeaderIndex = -1;
  private isActiveSortableHeaderAsc = true;
  private sortableFilter: SortableFilter | null = null;
  private loading = false;
  private internalStatus = DataTableStatus.Default;
  readonly DataTableStatus = DataTableStatus;
  isTooltipModalOpen = false;
  tooltipSelectedColumnIndex = -1;

  @ProvideReactive('dataTableStore')
  public get dataTableStore(): DataTableStore {
    return {
      checkeds: this.checkeds,
      data: this.data ? addHash(this.data) : this.tableData,
      openedItem: this.openedItem,
      activeSortableHeaderIndex: this.activeSortableHeaderIndex,
      isActiveSortableHeaderAsc: this.isActiveSortableHeaderAsc,
      isLoading: this.loading,
      status: this.status || this.internalStatus,
      sortableFilter: this.sortableFilter,
      setCheckeds: this.setCheckeds,
      setOpenedItem: this.setOpenedItem,
      setSortableFilter: this.setSortableFilter
    };
  }

  @Watch('dataParams')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public reload(newValue: any, oldValue: any) {
    const isNotSameData = JSON.stringify(newValue) !== JSON.stringify(oldValue);
    if (isNotSameData) this.loadItems();
  }

  @Watch('dataTableStore.checkeds')
  private emitDataCheckeds(checkeds: string[]) {
    const dataCheckeds = this.dataTableStore.data.filter(({ _hash }) =>
      checkeds.includes(_hash)
    );
    this.$emit('checked-rows', dataCheckeds);
  }

  private setCheckeds(checkeds: string[]) {
    this.checkeds = checkeds;
  }

  private setOpenedItem(state: null | string) {
    this.openedItem = state;
  }

  private setSortableFilter(
    activeColumnIndex: number,
    isOrderAsc: boolean,
    sort: Sort
  ) {
    this.activeSortableHeaderIndex = activeColumnIndex;
    const active = activeColumnIndex >= 0;
    this.isActiveSortableHeaderAsc = isOrderAsc;

    if (this.hasSortListener) {
      this.emitOnSort(activeColumnIndex, isOrderAsc, sort);
    } else {
      this.sortableFilter = getSortableFilter(active, isOrderAsc, sort);
    }
  }

  private async loadItems() {
    if (!this.loadData) return;
    this.setLoading(DataTableStatus.Loading);

    try {
      const items = await this.loadData(this.dataParams);
      this.tableData = addHash(items);
      this.setLoading(DataTableStatus.Default);
    } catch (error) {
      this.internalStatus = DataTableStatus.Error;
      this.tableData = [];
      this.$emit('error', error);
    }
  }

  private get colspan() {
    return this.getColumns.length;
  }

  private get getColumns() {
    const columns = [...this.columns];
    if (this.hasCheckbox) columns.unshift({ type: 'checkbox' });
    if (this.childColumns) columns.push({ type: 'child' });
    return columns;
  }

  private get notFound() {
    return this.rows.length === 0;
  }

  private get rows() {
    try {
      const filteredItems = this.filter
        ? this.filter(this.dataTableStore.data)
        : this.dataTableStore.data;
      const sortableFilter = this.hasSortListener
        ? null
        : this.dataTableStore.sortableFilter;

      return sortableFilter ? sortableFilter(filteredItems) : filteredItems;
    } catch (error) {
      console.error('Error: not apply filter', error);
      return this.dataTableStore.data;
    }
  }

  private created() {
    this.loadItems();
  }

  handleTooltipClick(columnIndex: number) {
    const column = this.columns[columnIndex];
    this.tooltipSelectedColumnIndex = columnIndex;

    if (typeof column.tooltip === 'object' && column.tooltip.listener) {
      column.tooltip.listener();
    }
    if (
      typeof column.tooltip === 'object' &&
      'title' in column.tooltip &&
      'text' in column.tooltip
    ) {
      this.toggleTooltipModal();
    }
  }

  toggleTooltipModal() {
    this.isTooltipModalOpen = !this.isTooltipModalOpen;
  }

  private getItemColumns(row: Row) {
    return getItemColumns(row, this.getColumns);
  }

  private get rowTestId() {
    return this.isChild ? 'content-child-row' : 'content-row';
  }

  private isOpen(_hash: string) {
    return this.dataTableStore.openedItem === _hash;
  }

  private get hasSortListener() {
    return !!this.$listeners['on-sort'];
  }

  private setLoading(
    status: DataTableStatus.Loading | DataTableStatus.Default
  ) {
    this.internalStatus = status;

    const isLoading = status === DataTableStatus.Loading;
    /**
     * Loading value event. Example:
     * `<MyComponent @isLoading="onIsLoading" />`
     * `onIsLoading(value: boolean) { ... }`
     * @event is-loading
     */
    this.$emit('is-loading', isLoading);
  }
}
</script>
