<template>
  <label :class="computedSwitchWrapperClasses">
    <div class="switch-wrapper__heading">
      <Heading class="switch-wrapper__label" :level="6">
        <!-- @slot Slot default relativo ao título  -->
        <slot />
      </Heading>
      <!-- @slot Slot relativo ao subtítulo -->
      <slot name="subtitle">
        <BaseText :color="NebraskaColors.textSecondary" size="sm">
          {{ subtitle }}
        </BaseText>
      </slot>
    </div>
    <input
      :id="id"
      class="switch-wrapper__control switch-wrapper__control--flat"
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      data-testid="toggle-switch-input"
      @change="$emit('input', $event.target.checked)"
    />
    <span class="switch-wrapper__label--container" />
  </label>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import Heading from '@/components/heading/heading/Heading.vue';
import BaseText from '@/components/base-text/BaseText.vue';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  name: 'ToggleSwitch',
  components: {
    Heading,
    BaseText
  }
})
export default class ToggleSwitch extends Vue {
  /**
   * Valor do input
   * @values true, false
   */
  @Prop({ type: Boolean, default: false })
  readonly value!: boolean;

  /**
   * ID do input/label
   * @deprecated
   */
  @Prop({ type: String, default: 'control__flat' })
  readonly id!: string;

  /**
   * Indica se o input está desativado
   * @values true, false
   */
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  /**
   * Permite desativar o divider superior
   * @values true, false
   */
  @Prop({ type: Boolean, default: false })
  readonly hideDivider!: boolean;

  /**
   * Texto a ser exibido no subtitle
   */
  @Prop({ type: String, required: false })
  readonly subtitle?: string;

  readonly NebraskaColors = NebraskaColors;

  public get computedSwitchWrapperClasses() {
    return ['switch-wrapper', { 'switch-wrapper__divider': !this.hideDivider }];
  }
}
</script>

<style lang="less" scoped>
.switch-wrapper {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 80px;

  &__divider {
    border-top: @size-border-x400 solid @divider-primary;
  }

  &__control {
    visibility: hidden;
    position: absolute;
  }

  &__label--container {
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
    min-width: 52px;
    height: 28px;
    display: inline-block;
    transition: background 0.4s;
    border-radius: @size-radius-x300;
    -webkit-box-sizing: unset;
    box-sizing: unset;
  }

  &__label--container:before,
  &__label--container:after {
    display: inline-block;
    position: absolute;
    content: '';
  }

  &__label--container:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: @element-secondary;
    border-radius: @size-radius-x300;
    transition: background 0.4s;
  }

  &__control--flat,
  &__label--container:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 20px;
    background-color: @background-secondary;
    border-radius: @size-radius-max;
    transition: margin 0.4s, background 0.4s;
  }

  &__control--flat:checked ~ .switch-wrapper__label--container {
    &:before {
      background-color: @status-positive;
    }
    &:after {
      background-color: @status-positive-background;
      margin-left: @size-spacing-x500;
    }
  }

  &__control--flat:disabled ~ &__label--container {
    &::before {
      background-color: @base-disabled;
    }
    &::after {
      background-color: @base-over-disabled;
    }
  }
}
</style>
