<template>
  <div ref="container" :aria-busy="isLoading" :class="containerClasses">
    <!-- Default -->
    <table v-if="!showMobileVersion" :class="tableClasses">
      <caption v-if="caption" class="table__caption">
        <Heading :level="2">
          {{ caption }}
        </Heading>
      </caption>
      <TableLoader v-if="isLoading" />
      <!--
        @slot The rows (`tr`) of the table (including headers).
        Inside the `tr` elements, is recomended the use of the `HeaderCell`
        and `Cell` components for the correct functioning. However,
        this component can also work with regular `th` and `td` element.
      -->
      <slot
        v-else
        name="default"
        :current-sorting-column="currentSortingColumn"
        :sorting-order="sortingOrder"
      />
    </table>

    <!-- Mobile -->
    <template v-else>
      <div v-if="caption" class="table__caption">
        <Heading :level="2">
          {{ caption }}
        </Heading>
      </div>
      <TableLoader v-if="isLoading" :is-mobile="true" />
      <!-- @slot Mobile content, displayed on smaller screens -->
      <slot v-else name="mobile" />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Provide } from 'vue-property-decorator';
import Heading from '@/components/heading/heading/Heading.vue';
import TableLoader from '@/components/tables/table-loader/TableLoader.vue';

type SortOrder = 'ascending' | 'descending';

@Component({
  name: 'Table',
  components: { Heading, TableLoader }
})
export default class Table extends Vue {
  /**
   * Container width breakpoint (in pixels). If the container is smaller than the breakpoint,
   * the mobile slot is displayed instead of the default slot.
   */
  @Prop({ type: Number, default: 640 })
  readonly breakpoint!: number;

  /**
   * Table caption (title)
   */
  @Prop({ type: String, required: false })
  readonly caption?: string;

  /**
   * If true, the rows will have a hover state indicating they can be clicked.
   */
  @Prop({ type: Boolean, default: false })
  readonly hasInteraction!: boolean;

  /**
   * If true, displays the TableLoader component
   */
  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  private currentSortingColumn = '';

  private hasMobileSlot = false;

  private showMobileVersion = false;

  private sortingOrder: SortOrder = 'ascending';

  @Provide()
  private changeSorting(key: string, sortingOrder?: SortOrder) {
    if (sortingOrder) {
      this.sortingOrder =
        sortingOrder === 'ascending' ? 'descending' : 'ascending';
    } else {
      this.currentSortingColumn = key;
    }
    /**
     * Change sorting event.
     * Example (script): `changeSorting(columnKey: string, sortingOrder: 'ascending'|'descending')`
     * @event change-sorting
     */
    this.$emit('change-sorting', key, this.sortingOrder);
  }

  private mounted() {
    this.hasMobileSlot = !!this.$slots.mobile;
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  private destroyed() {
    window.removeEventListener('resize', this.resize);
  }

  private resize() {
    const containerWidth = (this.$refs.container as HTMLElement).offsetWidth;
    this.showMobileVersion =
      this.hasMobileSlot && containerWidth <= this.breakpoint;
  }

  private get containerClasses() {
    return {
      'table-container': true,
      'table-container--mobile': this.showMobileVersion
    };
  }

  private get tableClasses() {
    return {
      table: true,
      'table--clickable': this.hasInteraction
    };
  }
}
</script>

<style lang="less" scoped>
.table-container {
  width: 100%;
  overflow-x: auto;
  color: @element-primary;
}

.table-container--mobile {
  overflow-x: unset;
}

.table {
  width: 100%;
  margin: auto;
  max-width: 1200px;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
}

.table ::v-deep tr {
  border-radius: @size-radius-x200;
}

.table ::v-deep tr:not(:first-of-type):hover td {
  background-color: @background-hover;
}

.table--clickable ::v-deep tr:not(:first-of-type):hover {
  cursor: pointer;
}

.table--clickable ::v-deep tr:not(:first-of-type):active td {
  opacity: 0.2;
}

.table__caption {
  padding: @size-spacing-x400 @size-spacing-x300;
  text-align: left;
  border-bottom: @size-border-x400 solid @divider-primary;
}
</style>
