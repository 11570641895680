<template>
  <tr>
    <th scope="col" :colspan="columns.length">
      <div class="sub-heading">
        <BaseText
          class="left-text"
          size="sm"
          as="span"
          :color="NebraskaColors.textSecondary"
          >{{ leftText }}</BaseText
        >
        <BaseText
          class="right-text"
          size="sm"
          :color="NebraskaColors.textSecondary"
          >{{ rightText }}</BaseText
        >
      </div>
    </th>
  </tr>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseText from '@/components/base-text/BaseText.vue';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Column } from './DataTableProps';

@Component({
  name: 'TableSubHeading',
  components: { BaseText }
})
export default class TableSubHeading extends Vue {
  @Prop({ required: false, type: String, default: '' })
  private leftText!: string;

  @Prop({ required: false, type: String, default: '' })
  private rightText!: string;

  @Prop({ required: false, type: Array, default: () => [] })
  private columns!: Column[];

  readonly NebraskaColors = NebraskaColors;
}
</script>

<style lang="less" scoped>
.sub-heading {
  display: flex;
  justify-content: space-between;
  padding: @size-spacing-x400 @size-spacing-x400 @size-spacing-x300;

  .left-text,
  .right-text {
    font-weight: bold;
  }
}
</style>
