<template>
  <div
    class="selection__box"
    :aria-label="ariaLabel"
    :aria-labelledby="ariaLabelledby"
    role="group"
  >
    <div
      v-for="(option, index) in options"
      :key="option.value"
      :class="[
        'selection__item',
        {
          'selection__item--selected': isOptionSelected(option)
        }
      ]"
      data-testid="selection-box-option"
    >
      <input
        :id="inputIds[index]"
        v-model="inputValue"
        :type="selectionOptionType"
        :value="option.value"
        :name="name"
        data-testid="selection-box-option-input"
      />
      <div class="selection__item__outline">
        <label :for="inputIds[index]">
          <div class="selection__item-content">
            <span class="selection__item-title">{{ option.title }}</span>
            <span v-if="option.subtitle" class="selection__item-subtitle">
              {{ option.subtitle }}
            </span>
          </div>
          <BaseIcon
            class="selection__item-icon"
            :icon="icon"
            width="24px"
            height="24px"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import {
  SelectionBoxOption,
  SelectionBoxValue,
  SelectionBoxType,
  SelectionBoxMultipleValue
} from './types';

@Component({
  name: 'SelectionBox',
  components: { BaseIcon },
  model: {
    prop: 'value',
    event: 'change'
  }
})
export default class SelectionBox extends Vue {
  /**
   * Label que irá descrever o contexto do grupo de selção.
   * Deve ser utilizada quando não há uma label no documento para associar ao grupo.
   *
   * <b>Exemplo:</b>
   * @example
   * ```<SelectionBox aria-label="Seleção de prazo de resgate:">```
   * */
  @Prop({ type: String })
  readonly ariaLabel?: string;

  /** Id da label que irá descrever o contexto do grupo de selção.
   * Deve ser utilizada quando há uma label presente no documento que pode ser associada a esse grupo.
   *
   * <b>Exemplo:</b>
   * @example
   *```
   * <h2 id="selection-label">Seleção de prazo de resgate:</h2>
   * <SelectionBox aria-labelledby="selection-label">```
   */
  @Prop({ type: String })
  readonly ariaLabelledby?: string;

  /**
   * Tipo de seleção
   * @values single, multiple
   * */
  @Prop({
    type: String,
    default: SelectionBoxType.Single,
    validator: (propValue: SelectionBoxType) => {
      return Object.values(SelectionBoxType).includes(propValue);
    }
  })
  readonly type!: SelectionBoxType;

  /** Valor ou valores selecionados  */
  @Prop({ type: [String, Number, Array], required: true })
  readonly value!: SelectionBoxValue;

  /** Lista de opções para seleção */
  @Prop({ type: Array, required: true })
  readonly options!: SelectionBoxOption[];

  /** o ícone a ser exibido quando a opção estiver selecionada */
  @Prop({ type: String, default: 'ic_check_simple' })
  readonly icon!: string;

  /** Evento emitido com os valores selecionados */
  @Emit('change')
  public emitChange(_value: SelectionBoxValue) {
    // Função que emite evento change
  }

  created() {
    this.validadeAriaLabelProp();
  }

  set inputValue(value: SelectionBoxValue) {
    this.emitChange(value);
  }

  get inputValue() {
    return this.value;
  }

  get isMultiselect() {
    return this.type === SelectionBoxType.Multiple;
  }

  get selectionOptionType() {
    return this.isMultiselect ? 'checkbox' : 'radio';
  }

  get inputIds() {
    return this.options.map(() => `input-id${this.generateRandomInt()}`);
  }

  /**
   * Retorna um valor único que é utilizado no atributo name dos inputs
   * para garantir que eles tenham o comportamento esperado através da
   * navegação pelo teclado
   * */
  get name() {
    return this.generateRandomInt();
  }

  isOptionSelected(option: SelectionBoxOption) {
    if (this.isMultiselect) {
      return (this.value as SelectionBoxMultipleValue).includes(option.value);
    } else {
      return this.value === option.value;
    }
  }

  private generateRandomInt() {
    return Math.random().toString(16).slice(2);
  }

  private validadeAriaLabelProp() {
    if (!this.ariaLabel && !this.ariaLabelledby) {
      // eslint-disable-next-line no-console
      console.error(
        'nebraska-web: é preciso passar uma das seguintes props: aria-label ou aria-labelledby.'
      );
      return;
    }

    if (this.ariaLabel && this.ariaLabelledby) {
      // eslint-disable-next-line no-console
      console.error(
        'nebraska-web: é permitido apenas uma das seguintes props: aria-label ou aria-labelledby.'
      );
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/_utils';
@import '../../styles/_medias';

.selection {
  &__box {
    position: relative;
    z-index: 0;
    border: @size-border-x400 solid @divider-primary;
    border-radius: @size-radius-x200;
    width: 100%;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 72px;
    color: @element-primary;
    .text-p-5();

    &-subtitle {
      color: @element-secondary;
    }

    &:hover {
      .background-with-opacity(@background-hover, 8%);
    }

    &:hover,
    &--selected {
      .selection__item-title {
        color: @theme-primary;
      }
    }

    &__outline {
      width: 100%;
      border-radius: @size-radius-x200;
    }

    &--selected {
      label {
        .background-with-opacity(@theme-primary, 8%);
      }

      .selection__item-icon {
        display: block;
      }
    }

    &:not(:first-of-type) {
      border-top: @size-border-x400 solid @divider-primary;
    }

    label {
      position: relative;
      z-index: 0;
      overflow: hidden;
      padding: @size-spacing-x500 @size-spacing-x400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      user-select: none;
    }

    &:first-of-type {
      label::before {
        border-radius: @size-radius-x200 @size-radius-x200 0 0;
      }
    }

    &:last-of-type {
      label::before {
        border-radius: 0 0 @size-radius-x200 @size-radius-x200;
      }
    }

    input {
      outline: none;
      position: absolute;
      appearance: none;
      width: 0;
      height: 0;
      padding: 0;
      margin: 0;
      border: none;
      left: 0;
      z-index: -1;

      &:focus ~ .selection__item__outline {
        outline: @size-border-x500 solid @element-primary;
      }

      &:focus:not(:focus-visible) ~ .selection__item__outline {
        outline: none;
      }

      &:focus-visible ~ .selection__item__outline {
        outline: @size-border-x500 solid @element-primary;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
    }

    &-icon {
      display: none;
      color: @theme-primary;
    }
  }
}
</style>
