<template>
  <li :class="computedListDisplayClasses">
    <div class="list-display__content">
      <div class="list-display__slot list-display__slot-title">
        <BaseText
          :color="NebraskaColors.elementSecondary"
          size="md"
          class="list-display__text"
        >
          <slot name="title-slot">
            {{ title }}
          </slot>
        </BaseText>
        <div class="list-display__button-container">
          <ButtonIconContextual
            v-if="showIcon"
            class="list-display__button"
            :icon="iconName"
            :disabled="buttonIconDisabled"
            :label="buttonIconLabel"
            size="extra-small"
            @click="buttonIconEvent"
          />
        </div>
      </div>
      <div class="list-display__slot">
        <component
          :is="getChipComponent"
          v-if="validateChipProperties"
          :label="chipLabel"
          :icon="chipIcon"
          :[chipType]="chipColor"
        />
        <BaseText
          v-else
          :color="valueColor"
          :class="computedValueClasses"
          size="md"
        >
          <slot name="value-slot">
            {{ value }}
          </slot>
        </BaseText>
      </div>
    </div>
    <div v-if="alignVertical" class="list-display__action-slot">
      <!-- @slot Slot para adicionar componentes derivados do ButtonIcon -->
      <slot name="action-slot" />
    </div>
  </li>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { BaseText } from '@/components/base-text';
import { ButtonIconContextual } from '@/components/button-icon';
import { ChipStatus, ChipContext } from '@/components/chip';
import { ChipType } from './types';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  name: 'ListDisplay',
  components: {
    BaseText,
    ChipContext,
    ChipStatus,
    ButtonIconContextual
  }
})
export default class ListDisplay extends Vue {
  /**
   * Título que será exibido no container esquerdo
   */
  @Prop({ type: String, required: true })
  readonly title!: string;

  /**
   * Value que será exibido no container direito
   */
  @Prop({ type: String })
  readonly value?: string;

  /**
   * Indica se o icon do container esquerdo deve aparecer ou não
   */
  @Prop({ type: Boolean, default: false })
  readonly showIcon!: boolean;

  /**
   * Código para icon personalizado no container esquerdo
   */
  @Prop({ type: String, default: 'EF0080' })
  readonly iconName!: string;

  /**
   * Define o tipo do Chip que será exibido no lugar do texto Value
   * @values context, status
   */
  @Prop({ type: String })
  readonly chipType!: ChipType;

  /**
   * Define o label do Chip que será exibido no lugar do texto Value.
   */
  @Prop({ type: String, default: '' })
  readonly chipLabel!: string;

  /**
   * Define o icon do Chip que será exibido no lugar do texto Value.
   */
  @Prop({ type: String })
  readonly chipIcon!: string;

  /**
   * Define a cor do Chip que será exibido no lugar do texto Value.
   * Verifique os padrões disponíveis em cada componente Chip.
   * @values positive, negative, alert, information, brand, neutral
   */
  @Prop({ type: String })
  readonly chipColor!: string;

  /**
   * Define o label para o ButtonIcon para fins de acessibilidade
   */
  @Prop({ type: String, default: '' })
  readonly buttonIconLabel!: string;

  /**
   * Define se o ButtonIcon está desabilitado
   */
  @Prop({ type: Boolean, default: false })
  readonly buttonIconDisabled!: boolean;

  /**
   * Define a cor para o texto do Value
   */
  @Prop({ type: String, default: NebraskaColors.elementPrimary })
  readonly valueColor!: string;

  /**
   * Define se o texto do Value vai ser bold
   */
  @Prop({ type: Boolean, default: false })
  readonly valueAsBold!: boolean;

  /**
   * Define se o alinhamento dos itens deve ser vertical
   */
  @Prop({ type: Boolean, default: false })
  readonly alignVertical!: boolean;

  /**
   * Define se o divider inferior deve ser exibido
   */
  @Prop({ type: Boolean, default: false })
  readonly hasDivider!: boolean;

  /**
   * Evento de click do botão, quando existente
   */
  @Emit('click')
  private buttonIconEvent() {
    // Função que emite evento click
  }

  readonly NebraskaColors = NebraskaColors;

  public get getChipComponent() {
    return this.chipType === ChipType.CONTEXT ? 'ChipContext' : 'ChipStatus';
  }

  public get validateChipProperties() {
    return (
      Object.values(ChipType).includes(this.chipType) && this.chipLabel.length
    );
  }

  get computedListDisplayClasses() {
    return [
      'list-display',
      {
        'list-display__align-vertical': this.alignVertical,
        'list-display__divider': this.hasDivider
      }
    ];
  }

  get computedValueClasses() {
    return {
      'list-display__text': true,
      'list-display__text--bold': this.valueAsBold
    };
  }
}
</script>

<style lang="less" scoped>
.list-display {
  display: inline-grid;
  grid-template-columns: auto max-content;
  width: 100%;

  &__content {
    display: inline-grid;
    grid-template-columns: auto max-content;
    justify-content: space-between;
    grid-gap: @size-spacing-x350;
  }

  &__button-container {
    display: flex;
    align-items: center;
    color: @element-secondary;
  }

  &__button {
    margin-left: @size-spacing-x75;
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-self: center;

    &--bold {
      font-weight: bold;
    }
  }

  &__slot {
    display: inherit;
  }

  &__slot-title {
    grid-template-columns: auto max-content;
  }

  &__align-vertical {
    grid-gap: @size-spacing-x350;

    .list-display__content {
      grid-template-columns: auto;
      grid-gap: @size-spacing-x75;
    }
  }

  &__divider {
    padding-bottom: @size-spacing-x350;
    border-bottom: @size-border-x400 solid @divider-primary;
  }

  &__action-slot {
    display: flex;
    align-items: center;
  }
}
</style>
