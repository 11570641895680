<template>
  <div class="form-field">
    <label v-if="label" class="text-p-5" :for="labelFor">{{ label }}</label>
    <slot name="default" />
    <transition name="onError">
      <p v-show="error" class="text-p-6 label-error">
        {{ error }}
      </p>
    </transition>
    <slot name="customError" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
/**
 * @deprecated
 */
@Component({
  name: 'FormField'
})
export default class FormField extends Vue {
  @Prop({ type: String, required: false })
  private labelFor!: string;

  @Prop({ type: String, default: '' })
  private label!: string;

  @Prop({ type: String, default: '' })
  private error!: string;
}
</script>

<style lang="less" scoped>
.form-field {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  label {
    color: @text-primary;
    margin-bottom: @size-spacing-x150;
  }

  .label-error {
    color: @element-on-error;
    margin-top: @size-spacing-x150;
  }
}

.onError-enter-active,
.onError-leave-active {
  transition: opacity 0.3s;
}
.onError-enter,
.onError-leave-to {
  opacity: 0;

  p {
    margin: 0;
  }
}
</style>
