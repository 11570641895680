<template>
  <div
    class="spinner-loader"
    :style="getSize"
    aria-busy="true"
    aria-live="polite"
  >
    <div
      v-for="item in 4"
      :key="`loader-${item}`"
      class="spinner-loader__item"
      :style="computedStyle"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  name: 'Loader'
})
export default class Loader extends Vue {
  @Prop({ type: String, default: NebraskaColors.elementSecondary })
  public color!: string;

  @Prop({ type: Number, default: 24 })
  public size!: number;

  private NebraskaColors = NebraskaColors;

  private get getSize() {
    return {
      width: `${this.size}px`,
      height: `${this.size}px`
    };
  }

  private get computedStyle() {
    return {
      ...this.getSize,
      'border-color': `${this.color} transparent transparent transparent`
    };
  }
}
</script>

<style lang="less" scoped>
.spinner-loader {
  display: inline-block;
  position: relative;
  .spinner-loader__item {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-radius: @size-radius-max;
    animation: loader-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-width: @size-border-x500;
    border-style: solid;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
