<template>
  <div class="donut-chart-foundation">
    <Chart :key="key" :class="computedLoadingClass" :options="chartOptions" />
    <div class="donut-chart-foundation__content">
      <!-- @slot Slot para qualquer conteúdo que deve ficar no centro do Donut.
      O Fallback do slot são os componentes de loading `BaseLoader` e error `ErrorLabel`. -->
      <slot>
        <ErrorLabel
          v-if="hasError"
          title="Erro ao carregar os dados."
          subtitle="Tentar novamente"
          @on-error-click="onErrorClick"
        />
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';
import { Options, Point, PointClickEventObject } from 'highcharts';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { IDonutChartData } from './';
import some from 'lodash.some';
import filter from 'lodash.filter';
import ErrorLabel from '@/charts/components/ErrorLabel/ErrorLabel.vue';

const loadingMockDataSet: IDonutChartData[] = [
  { name: '1', y: 25 },
  { name: '2', y: 25 },
  { name: '3', y: 25 },
  { name: '4', y: 25 },
  { name: '5', y: 25 }
];

@Component({
  name: 'DonutChartFoundation',
  components: { Chart, ErrorLabel }
})
export default class DonutChartFoundation extends Vue {
  /**
   * Alterna a exibição do componente padrão de loading.
   */
  @Prop({ type: Boolean, default: false })
  readonly isLoading!: boolean;

  /**
   * Alterna a exibição do componente padrão de erro.
   */
  @Prop({ type: Boolean, default: false })
  readonly hasError!: boolean;

  /**
   * Habilita o efeito visual quando o usuário passa o mouse por cima dos slices do donut.
   */
  @Prop({ type: Boolean, default: true })
  readonly enableHover!: boolean;

  /**
   * Popula o gráfico com os dados fornecidos.
   *
   * @param {object[]} dataset Uma lista de objetos com os seguintes atributos:
   * - `name` Uma string com o nome que dá significado ao valor do objeto.
   * - `y` Um number que dá o valor que este objeto representa. A proporção do gráfico é definida pelo highcharts com este valor.
   * - `color` (opcional) Uma cor que represente o valor do objeto.
   * - `format` (opcional) Uma string adicional para gráficos específicos. Veja o componente `DonutChartAllocation`.
   */
  @Prop({
    type: Array,
    default: () => []
  })
  readonly dataSet!: IDonutChartData[];

  /**
   * Popula o gráfico com um esquema de cores. Esta propriedade é utilizada somente quando não são fornecidos os atributos de cor nos objetos da prop `dataSet`.
   * As cores serão exibidas em ordem sequencial.
   */
  @Prop({
    type: Array,
    default: () => [NebraskaColors.elementDisabled]
  })
  readonly colorScheme!: [];

  /**
   * Define a cor da borda das porções do gráfico.
   * Necessário ser alterada quando o fundo onde está o gráfico é diferente da cor borda.
   */
  @Prop({
    type: String,
    default: NebraskaColors.backgroundSecondary
  })
  readonly borderColor!: string;

  /**
   * Quando o hover está habilitado, este evento emite o índice do slice que está em estado de "hover", ou pode retornar null caso nenhum elemento apresente este estado.
   */
  @Emit('hover-allocation')
  private hoverAllocation(indexHover: number | null) {
    return indexHover;
  }

  /**
   * Emite o evento para o click no botão do componente ErrorLabel
   */
  @Emit('on-error-click')
  private onErrorClick() {
    // event on error label click
  }

  /**
   * Emite um evento quando o usuário clica em um slice do donut
   * Retorna o evento o objeto PointerEvent com as informações do slice clicado
   */
  @Emit('on-slice-click')
  private onSliceClick(event: PointClickEventObject) {
    return event;
  }

  /**
   * Quando a key muda ela faz com que o componente que use ela re-renderize.
   * Aqui no caso o que a gente deseja é quando o gráfico saia do estado de loading e entre no estado carregado,
   * ele re-renderize o gráfico para forçar a animação de entrada do gráfico, se a gente não forçar a re-renderização
   * a troca do estado de carregamento para o estado carregado fica sem animação.
   */
  get key() {
    return String(this.isLoading);
  }

  private get dataSetColors() {
    if (this.isLoading || this.hasError) {
      return [NebraskaColors.elementDisabled];
    }

    const hasSomeColor = some(this.dataSet, 'color');
    return hasSomeColor
      ? (filter(this.dataSet, 'color') as unknown as string[])
      : this.colorScheme;
  }

  private get computedLoadingClass() {
    return this.isLoading && 'donut-chart-foundation--loading';
  }

  public get chartOptions(): Options {
    return {
      title: {
        text: ''
      },
      chart: {
        backgroundColor: 'transparent'
      },
      plotOptions: {
        pie: {
          animation: !this.isLoading,
          borderColor: this.borderColor,
          borderRadius: 0,
          size: '100%',
          innerSize: '90%'
        },
        series: {
          states: {
            hover: {
              enabled: !this.isLoading && this.enableHover
            },
            inactive: {
              opacity: this.serieOpacity
            }
          },
          point: {
            events: {
              click: event => {
                if (!(this.isLoading || this.hasError)) {
                  this.onSliceClick(event);
                }
              },
              mouseOver: event => {
                const eventTarget = event.target as Partial<Point>;
                this.hoverAllocation(eventTarget.index!);
              },
              mouseOut: () => {
                this.hoverAllocation(null);
              }
            }
          },
          borderWidth: 2,
          dataLabels: {
            enabled: false
          }
        }
      },
      tooltip: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      colors: this.dataSetColors,
      series: [
        {
          type: 'pie',
          data: this.isLoading ? loadingMockDataSet : this.dataSet
        }
      ]
    };
  }

  private get serieOpacity() {
    return !this.enableHover || this.isLoading ? 1 : 0.2;
  }
}
</script>

<style lang="less" scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.donut-chart-foundation {
  position: relative;

  ::v-deep .highcharts-empty-series {
    fill: @element-disabled;
    stroke-width: 1;
  }

  &--loading {
    ::v-deep svg {
      animation: spin 7s linear infinite;
      transform-origin: center;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: @size-spacing-x150;
  }

  .content-block {
    text-align: center;
    pointer-events: all;
  }
}
</style>
