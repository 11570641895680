<template>
  <BaseCell class="checkbox-cell">
    <Checkbox :value="_hash" :checked="isChecked" @change="handleChange" />
  </BaseCell>
</template>

<script lang="ts">
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import Checkbox from '@/components/checkbox/Checkbox.vue';
import BaseCell from './BaseCell.vue';
import { DataTableStore } from '../DataTableProps';

@Component({
  name: 'CheckboxCell',
  components: { BaseCell, Checkbox }
})
export default class CheckboxCell extends Vue {
  @InjectReactive('dataTableStore')
  private readonly dataTableStore!: DataTableStore;

  @Prop({ type: String, required: false, default: '' })
  private _hash!: string;

  handleChange(isChecked: boolean) {
    let checkeds = [...this.dataTableStore.checkeds];
    if (isChecked) {
      checkeds.push(this._hash);
    } else {
      checkeds = checkeds.filter((_hash: string) => _hash !== this._hash);
    }
    this.dataTableStore.setCheckeds(checkeds);
  }

  get isChecked() {
    return this.dataTableStore.checkeds.some(
      (_hash: string) => _hash === this._hash
    );
  }
}
</script>

<style lang="less" scoped>
.checkbox-cell {
  padding-left: 0;
  padding-right: 0;
  width: 48px;
}
</style>
