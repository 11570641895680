<template>
  <div
    data-testid="button-filter"
    :aria-disabled="disabled"
    :class="computedClassName"
    :aria-label="label"
    v-on="!disabled && $listeners"
  >
    <div v-if="$slots['left-slot']" ref="left-slot" class="left-slot">
      <!-- @slot Conteúdo à esquerda da label -->
      <slot name="left-slot" />
    </div>
    <div class="label">
      {{ label }}
    </div>
    <div v-if="$slots['right-slot']" class="right-slot">
      <!-- @slot Conteúdo à direita da label -->
      <slot name="right-slot" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Ref } from 'vue-property-decorator';

@Component({
  name: 'FilterFoundation'
})
export default class FilterFoundation extends Vue {
  /**
   * Texto exibido no Filter
   */
  @Prop({ type: String, required: true })
  readonly label!: string;

  /**
   * Desativa o botão
   */
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Ref('left-slot')
  readonly leftSlot?: HTMLDivElement;

  @Ref('right-slot')
  readonly rightSlot?: HTMLDivElement;

  /**
   * Filter ativado
   */
  @Prop({ type: Boolean, default: false })
  readonly active!: boolean;

  /**
   * Define as cores do filter
   */
  private get computedClassName() {
    const classes = ['filter--container'];
    if (this.disabled) classes.push('filter--disabled');
    else if (this.active) classes.push('filter--active');
    if (this.$slots['left-slot']) classes.push('left-content');
    if (this.$slots['right-slot']) classes.push('right-content');
    return classes;
  }
}
</script>

<style lang="less" scoped>
.filter--container {
  .text-p-5();
  @size: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: @size;
  border-radius: @size;
  border: @size-border-x400 solid @divider-primary;
  box-sizing: border-box;
  cursor: pointer;
  background-color: @base-primary;
  color: @text-primary;
  padding: 0 @size-spacing-x350;

  &.left-content {
    padding-left: @size-spacing-x350;
  }

  &.right-content {
    padding-right: @size-spacing-x350;
  }

  &:hover {
    background-color: @base-primary-darken;
  }

  &.filter--disabled {
    color: @base-over-disabled !important;
    pointer-events: none;
    background-color: @base-disabled !important;
    border: @size-border-x400 solid @base-disabled;

    .left-slot,
    .right-slot {
      color: @base-over-disabled;
    }
  }

  &.filter--active {
    background-color: @base-select;
    color: @text-negative;
    border: @size-border-x400 solid @base-select;

    &:hover {
      background-color: @base-select-darken;
    }
    .left-slot,
    .right-slot {
      color: @element-negative;
    }
  }

  > div + div {
    margin-left: @size-spacing-x150;
  }

  .left-slot,
  .right-slot {
    color: @element-secondary;
  }
}
</style>
