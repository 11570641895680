<template>
  <InputFoundation
    type="tel"
    data-testid="input-money"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    :required="required"
    :status-type="status"
    :value="inputValue"
    v-bind="inputAttr"
    :maxlength="maxLength"
    @blur="onBlur"
    @keypress="handleInput"
    v-on="{ ...$listeners, input: onInput }"
  >
    <template #leading-slot>
      <BaseIcon v-if="leadingIcon" :icon="leadingIcon" colors="currentColor" />
    </template>
    <template #trailing-slot>
      <StatusTypeIcon v-if="status" :status-type="status" />
    </template>
    <template #bottom-slot>
      <HelperText
        v-if="helperText"
        :id="helperTextId"
        :text="helperText"
        :status-type="status"
      />
    </template>
  </InputFoundation>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { HelperText } from '@/components/helper-text';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import InputFoundation from '@/foundation/input/InputFoundation.vue';
import { StatusTypeIcon } from '@/components/status-type-icon';
import { InputStatusType } from '@/foundation/types';

@Component({
  name: 'InputMoney',
  components: {
    InputFoundation,
    StatusTypeIcon,
    BaseIcon,
    HelperText
  }
})
export default class InputMoney extends Vue {
  /**
   * Valor inicial do input
   */
  @Prop({ type: [String, Number], required: false })
  private value?: string | number;

  /**
   * Rótulo (label) exibido no input.
   */
  @Prop({ type: String, required: false })
  private label?: string;

  /**
   * Texto exibido dentro do input, quando não há valor digitado
   */
  @Prop({ type: String, required: false })
  private placeholder?: string;

  /**
   * Caso o input esteja desativado
   */
  @Prop({ type: Boolean, required: false, default: false })
  private disabled!: boolean;

  /**
   * Indica que a entrada do usuário é obrigatória.
   */
  @Prop({ type: Boolean, required: false, default: false })
  private required!: boolean;

  /**
   * O ícone exibido à esquerda dentro do input utilizado dentro do leadingSlot.
   */
  @Prop({ type: String, required: false })
  private leadingIcon?: string;

  /**
   * A mensagem de apoio exibida abaixo do input utilizado dentro do bottomSlot.
   * É possível passar outro tipo de elemento dentro também.
   */
  @Prop({ type: String, required: false })
  private helperText?: string;

  /**
   * O Texto será exibido antes da entrada do usuário
   */
  @Prop({ type: String, default: 'R$', required: false })
  private prefix!: string;

  /**
   * Determina quantas casas decimais a máscara monetária vai possuir
   */
  @Prop({
    type: Number,
    default: 2,
    required: false,
    validator: (precision: number) => precision >= 1
  })
  private precision!: number;

  /**
   * O texto separador das casas de milhares
   */
  @Prop({ type: String, default: '.', required: false })
  private thousand!: string;

  /**
   * O texto separador das casas decimais
   */
  @Prop({ type: String, default: ',', required: false })
  private decimal!: string;

  /**
   * Defini os estados de sucesso e erro do input.
   */
  @Prop({ type: String, required: false })
  private status?: InputStatusType;

  /**
   * Permite a entrada de valores negativos
   */
  @Prop({ type: Boolean, default: false, required: false })
  private allowNegative!: boolean;

  /**
   * Permiti a entrada de valores negativ.
   */
  @Prop({ type: Boolean, default: false, required: false })
  private allowZero!: boolean;

  /**
   * Máximo de caracteres que podem conter no input
   */
  @Prop({ type: String, required: false, default: '21' })
  private maxLength!: string;

  private inputValue = '';

  private isNegative = false;

  @Emit('input')
  public input(value: string): number {
    return Number(value);
  }

  @Watch('value', { immediate: true })
  private watchValue(newValue?: string) {
    this.inputValue =
      newValue !== undefined
        ? this.formatValue(this.toFixedWithoutRounding(newValue))
        : '';
  }

  get helperTextId() {
    const randomId = Math.random().toString(16).slice(2);
    return `helper-text-${randomId}`;
  }

  get getPrecision(): number {
    return this.precision || 1;
  }

  get handleRegex(): RegExp {
    return this.allowNegative ? /\d|-/ : /\d/;
  }

  get inputAttr() {
    return this.helperText ? { 'aria-describedby': this.helperTextId } : '';
  }

  private handleInput(event: KeyboardEvent) {
    const key = event.key;
    if (!this.handleRegex.test(key) || (this.isNegative && /-/.test(key)))
      event.preventDefault();
  }

  private toFixedWithoutRounding(value: number | string): string {
    return (
      (parseFloat(value.toString()) * Math.pow(10, this.getPrecision)) /
      Math.pow(10, this.getPrecision)
    ).toFixed(this.getPrecision);
  }

  private onInput(value: string): void {
    this.updateValue(value);
  }

  private updateValue(value: string): void {
    this.isNegative = value.includes('-');
    const numberValue = this.applyPrecisionFormat(value);
    this.input(numberValue);
    this.inputValue = this.formatValue(numberValue);
  }

  private applyPrecisionFormat(value: string): string {
    let numbers = this.onlyNumbers(value);
    numbers = `${Array(this.getPrecision).fill('0').join('')}${numbers}`;
    const expression = new RegExp(`^([0-9]*?)([0-9]{1,${this.getPrecision}})$`);
    const valueFormatted = numbers.replace(expression, '$1.$2');
    const negative = this.isNegative && this.allowNegative ? '-' : '';
    return `${negative}${this.toFixedWithoutRounding(valueFormatted)}`;
  }

  private onBlur() {
    if (Number(this.onlyNumbers(this.inputValue)) === 0 && !this.allowZero)
      this.inputValue = '';
  }

  private onlyNumbers(value: string): string {
    return value.replace(/\D/g, '');
  }

  private formatValue(value: string) {
    const dividedValue = value.toString().split('.');
    dividedValue[0] = dividedValue[0].replace(
      /\B(?=(\d{3})+(?!\d))/g,
      this.thousand
    );
    return `${this.prefix} ${dividedValue.join(this.decimal)}`;
  }
}
</script>
