<template>
  <div class="mfa-container" data-testid="mfa-container">
    <MFAContentDefault data-testid="mfa-content-default" />
    <div class="inputs-container">
      <InputPin
        v-for="inputIndex in inputLength"
        :key="inputIndex"
        ref="input-pin"
        v-model="inputValue[inputIndex - 1]"
        :status-type="inputStatus"
        :required="true"
        :data-testid="`input-pin-${inputIndex}`"
        placeholder="0"
        @input="onChange($event, inputIndex)"
        @keyup.backspace="handleBackspace(inputIndex - 1)"
        @paste="pasteToken"
      />
    </div>

    <div v-if="hasSMSLink">
      <BaseText
        v-if="!showCountdown"
        size="sm"
        :colors="NebraskaColors.textPrimary"
      >
        <span v-if="shouldShowText">Se desejar, </span>
        <Link href="" @click.prevent="sendSms">{{ linkLabel }}</Link>
      </BaseText>
      <BaseText
        v-if="showCountdown"
        size="sm"
        :color="NebraskaColors.textPrimary"
      >
        Para receber um novo código, aguarde
        <Countdown
          :countdown-value="countdownValue"
          @finished="countdownTimeout"
        />
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit, Ref, Watch } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Link } from '@/components/link';
import { InputPin } from '@/components/input';
import { Countdown } from '@/components/countdown';
import { BaseText } from '@/components/base-text';
import { MFATemplateState } from './types';
import { MFAContentDefault } from './index';

@Component({
  name: 'MFATemplate',
  components: {
    BaseText,
    Link,
    Countdown,
    InputPin,
    MFAContentDefault
  }
})
export default class MFATemplate extends Vue {
  /**
   *  Valor de referência para o input-pin
   */
  @Ref('input-pin')
  private inputPin!: Vue[];

  /**
   *  Valor inicial do contador em Segundos
   */
  @Prop({ type: Number, required: false, default: 60 })
  private countdownValue!: number;

  /**
   *  Variável que controla se há erros
   */
  @Prop({ type: Boolean, default: false })
  private hasError!: boolean;

  /**
   *  Variável que controla se haverá o link na tela
   */
  @Prop({ type: Boolean, default: true })
  private hasSMSLink!: boolean;

  /**
   *  Variável usada para limpar inputs, caso essa prop mude para o valor true o input será limpo e o primeiro número receberá focus
   */
  @Prop({ type: Boolean, default: false, required: false })
  private clearInput!: boolean;

  /**
   *  v-model que controla os valores dos inputs
   */
  private inputValue: string[] = [];

  /**
   *  Número de inputs mostrados na tela
   */
  private inputLength = 6;

  /**
   *  Variável que define quando o countdown será mostrado ou não
   */
  private showCountdown = false;

  readonly NebraskaColors = NebraskaColors;

  /**
   *  Variável que define o estado atual do componente
   */
  private currentState = MFATemplateState.Initial;

  /**
   *  Evento disparado quando 'receba o código por SMS' é clicado
   */
  @Emit('send-sms')
  public sendSms() {
    this.showCountdown = true;
    this.currentState = MFATemplateState.WaitingToken;
    this.focusFirstInputPin();
  }

  /**
   *  Evento disparado quando contador chega a zero
   */
  @Emit('countdown-timeout')
  private countdownTimeout() {
    this.currentState = MFATemplateState.ResendToken;
    this.showCountdown = false;
  }

  /**
   *  Evento disparado quando todos os valores estão preenchidos
   */
  @Emit('completed')
  private completed() {
    return this.inputValue.join('');
  }

  @Watch('clearInput', { immediate: true })
  private watchClearInput(value: boolean) {
    if (value && this.inputPin && this.inputPin[0]) {
      this.inputValue = [];
      this.focusFirstInputPin();
    }
  }

  mounted() {
    this.focusFirstInputPin();
  }

  private focusFirstInputPin(): void {
    const firstInputPin = this.inputPin[0].$el.querySelector(
      'input'
    ) as HTMLElement;
    firstInputPin.focus();
  }

  /**
   *  Função chamada quando valores são copiados e colados no input
   */
  private pasteToken(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    if (!clipboardData) return;

    const pastedText = clipboardData.getData('text');

    if (Number(pastedText)) {
      const characters = pastedText.split('').filter(el => el !== null);
      this.inputValue = characters.slice(0, this.inputLength);
    }
  }

  /**
   *  Função disparada a cada valor válido digitado no input-pin
   */
  private onChange(value: string, inputIndex: number) {
    if (this.isInputValueValid) {
      this.completed();
    } else if (this.goToTheNextInputPin(value, inputIndex)) {
      (
        this.inputPin[inputIndex].$el.querySelector('input') as HTMLInputElement
      ).focus();
    }
  }

  /**
   *  Função disparada sempre que o usuário digitar a tecla: Backspace
   */
  private handleBackspace(inputIndex: number) {
    if (this.goToThePreviousInputPin(inputIndex)) {
      (
        this.inputPin[inputIndex - 1].$el.querySelector(
          'input'
        ) as HTMLInputElement
      ).focus();
    }
  }

  /**
   *  Função que testa se deve dar focus no InputPin anterior
   */
  private goToThePreviousInputPin(inputIndex: number): boolean {
    return inputIndex !== 0;
  }

  /**
   *  Função que controla o feedback de erro no input (borda vermelha)
   */
  private get inputStatus() {
    return this.hasError ? 'error' : 'default';
  }

  /**
   *  Função que testa se todos os dados dentro do v-model estão preenchidos
   */
  private get isInputValueValid(): boolean {
    return (
      this.inputValue.filter(value => Boolean(value)).length ===
      this.inputLength
    );
  }

  /**
   *  Computed que controla qual será o texto do link
   */
  get linkLabel() {
    switch (this.currentState) {
      case MFATemplateState.ResendToken:
        return 'Reenviar código';
      case MFATemplateState.Initial:
      default:
        return 'receba o código por SMS';
    }
  }

  /**
   *  Computed que controla se haverá ou não o texto antes do link
   */
  get shouldShowText() {
    return (
      !this.showCountdown && this.currentState === MFATemplateState.Initial
    );
  }

  /**
   *  Função que testa se deve dar focus no próximo InputPin
   */
  private goToTheNextInputPin(value: string, inputIndex: number): boolean {
    return value !== '' && inputIndex < this.inputLength;
  }
}
</script>

<style lang="less" scoped>
.mfa-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .inputs-container {
    margin: @size-spacing-x600;
    display: flex;
    justify-content: center;
    flex-wrap: no-wrap;

    @media screen and (max-width: 720px) {
      flex-wrap: wrap;
      max-width: 230px;
      margin: @size-spacing-x300 auto;
    }

    .input-pin-wrapper {
      margin: @size-spacing-x300;
      align-self: center;
    }
  }
}
</style>
