<template>
  <div class="footer">
    <div class="footer__content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Footer'
})
export default class Footer extends Vue {}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 150px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  background-color: @background-primary;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 480px) {
    height: 90px;
  }

  .footer__content {
    align-self: center;
  }
}
</style>
