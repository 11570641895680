<template>
  <Modal
    class="tooltip-modal"
    :is-open="isOpen"
    :show-back-button="false"
    size="md"
    title=""
    @close="emitClose"
  >
    <div class="tooltip-modal__content">
      <Heading class="tooltip-modal__heading" :level="3">{{
        tooltipData.title
      }}</Heading>
      <BaseText
        class="tooltip-modal__text"
        size="sm"
        :color="NebraskaColors.textSecondary"
        >{{ tooltipData.text }}</BaseText
      >
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { Modal } from '@/components/modal';
import { Column } from './DataTableProps';
import { BaseText } from '@/components/base-text';
import { Heading } from '@/components/heading';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  components: {
    Modal,
    BaseText,
    Heading
  }
})
export default class ComponentName extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  @Prop({ type: Array })
  readonly columns!: Column[];

  @Prop({ type: Number })
  readonly selectedColumnIndex!: number;

  @Emit('close')
  emitClose() {}

  readonly NebraskaColors = NebraskaColors;

  get selectColumn() {
    return this.columns[this.selectedColumnIndex];
  }

  get tooltipData() {
    return {
      title: this.selectColumn?.tooltip?.title || '',
      text: this.selectColumn?.tooltip?.text || ''
    };
  }
}
</script>

<style lang="less" scoped>
.tooltip-modal {
  /deep/.modal-content {
    padding: 0 @size-spacing-x900 @size-spacing-x900 @size-spacing-x900;
  }

  &__heading,
  &__text {
    text-align: left;
  }

  &__heading {
    margin-bottom: @size-spacing-x350;
  }

  &__content {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
  }
}
</style>
