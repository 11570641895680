<template>
  <component :is="component" :class="classes">
    <template v-if="!subtitle">
      <!-- @slot TableCell content -->
      <slot />
    </template>
    <template v-else>
      <div class="cell__title text-p-5">
        <slot />
      </div>
      <div class="cell__subtitle text-p-6">
        {{ subtitle }}
      </div>
    </template>
  </component>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'TableCell'
})
export default class TableCell extends Vue {
  /**
   * If true, will render a `<th>` element, instead of a `<td>`
   */
  @Prop({ type: Boolean, default: false })
  readonly isHeader!: boolean;

  /**
   * Subtitle of the cell
   */
  @Prop({ type: String, required: false })
  readonly subtitle?: string;

  get component() {
    return this.isHeader ? 'th' : 'td';
  }

  get classes() {
    return {
      cell: true,
      'text-p-5': !this.isHeader
    };
  }
}
</script>

<style lang="less" scoped>
.cell {
  box-sizing: border-box;
  height: 56px;
  padding: @size-spacing-x300 @size-spacing-x400;
  white-space: nowrap;
  vertical-align: top;
  color: @element-primary;
}

.cell:first-of-type {
  padding-left: @size-spacing-x300;
  border-top-left-radius: @size-radius-x200;
  border-bottom-left-radius: @size-radius-x200;
}

.cell:last-child {
  padding-right: @size-spacing-x300;
  border-top-right-radius: @size-radius-x200;
  border-bottom-right-radius: @size-radius-x200;
}

.cell__subtitle {
  color: @element-secondary;
}
</style>
