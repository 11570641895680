<template>
  <div :class="selectContainerStyle">
    <div
      v-click-outside="hideOptions"
      :class="selectWrapperStyle"
      :style="selectStyle"
    >
      <div
        class="select-title"
        :title="currentSelectLabel"
        @click="toggleOptions"
      >
        <p>{{ currentSelectLabel }}</p>
        <span class="select-icon">
          <BaseIcon
            icon="ic_filled_arrow"
            :colors="NebraskaColors.elementPrimary"
          />
        </span>
      </div>
      <ul
        v-show="showOptionsDisplay"
        class="select-options"
        :style="optionsPosition"
      >
        <li
          v-for="opt in options"
          :key="opt.label"
          tabindex="0"
          role="button"
          :class="{ selected: isSelected(opt) }"
          @click="() => selectOption(opt)"
        >
          {{ opt.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
/**
 * @deprecated
 */
interface IOption {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

@Component({
  name: 'Select',
  components: {
    BaseIcon
  },
  directives: {
    'click-outside': {
      bind: (el: HTMLElement, binding) => {
        const handler = (e: Event) => {
          if (!el.contains(e.target as Node) && el !== e.target) {
            binding.value(e);
          }
        };
        // add Event Listeners
        document.addEventListener('click', handler);
      },

      unbind: (el, binding) => {
        // Remove Event Listeners
        document.removeEventListener('click', (e: Event) => {
          if (!el.contains(e.target as Node) && el !== e.target) {
            binding.value(e);
          }
        });
      }
    }
  }
})
export default class Select extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @Prop({ type: Function, default: () => {} })
  private onSelect!: (opt: IOption) => unknown;

  @Prop({ type: Array, default: () => [] })
  private options!: Array<IOption>;

  @Prop({ type: Object, default: () => ({}) })
  private defaultValue!: IOption;

  @Prop({ type: String, default: 'Escolha uma opção' })
  private placeholder!: string;

  @Prop({ type: String, default: 'auto' })
  private width!: string;

  @Prop({ type: String, default: '32px' })
  private height!: string;

  @Prop({ type: String, default: 'right' })
  private optionsStartPosition!: 'left' | 'right';

  @Prop({ type: String, default: 'background-hover' })
  private backgroundHover!: string;

  private showOptionsVisibility = true;

  private showOptionsDisplay = false;

  private optionsCurrPos?: 'left' | 'right';

  private selected: IOption | null = null;

  private overflowScreenWidth = false;

  private NebraskaColors = NebraskaColors;

  public selectOption(opt: IOption) {
    this.selected = opt;
    this.onSelect(opt);
    this.hideOptions();
  }

  public toggleOptions() {
    this.showOptionsDisplay = !this.showOptionsDisplay;
  }

  public hideOptions() {
    if (this.showOptionsVisibility) {
      this.showOptionsDisplay = false;
    }
  }

  // When component is mounted we check if options list are overflowing screen, and if it is we repositionate
  // and add some mobile css to prevent it from breake when text is too big
  public mounted() {
    this.checkOptionsOverflow();
  }

  public isSelected(opt: IOption) {
    if (!this.selected) return this.defaultValue.value === opt.value;

    return this.selected.value === opt.value;
  }

  // check if has overflow in screen width and change start position if needed
  // or change the options to width 100% of parent element in devices with less than 640px width
  public checkOptionsOverflow() {
    this.showOptionsDisplay = true;
    this.showOptionsVisibility = false;

    setTimeout(() => {
      const options: Element = document.querySelector('.select-options')!;
      const wrapper: Element = document.querySelector('.select-wrapper')!;
      if (options && wrapper) {
        const wrapperRect = wrapper.getBoundingClientRect();
        const optionsRect = options.getBoundingClientRect();
        const elemEndPos = optionsRect.left + optionsRect.width;
        const availableWidth = window.innerWidth;
        const overflowLeft =
          optionsRect.x < 10 || wrapperRect.right - optionsRect.width < 10;
        const overflowRight = elemEndPos - availableWidth > -10;

        if (overflowLeft) {
          this.optionsCurrPos = 'left';
        } else if (overflowRight) {
          this.optionsCurrPos = 'right';
        }
        this.overflowScreenWidth = overflowLeft && overflowRight;

        if (!overflowLeft && !overflowRight) {
          this.optionsCurrPos = undefined;
        }
      }
      this.showOptionsDisplay = false;
      this.showOptionsVisibility = true;
    }, 50);
  }

  get computedOptionsVisibility() {
    return { display: this.showOptionsDisplay ? 'block' : 'none' };
  }

  get selectStyle() {
    return {
      '--select-bg-color': `var(--${this.backgroundHover})`,
      width: this.width,
      height: this.height
    };
  }

  get optionsPosition() {
    return {
      [this.optionsCurrPos ? this.optionsCurrPos : this.optionsStartPosition]:
        '0px',
      visibility: this.showOptionsVisibility ? 'visible' : 'hidden'
    };
  }

  get currentSelectLabel(): string {
    if (this.selected) return this.selected.label;
    if (this.defaultValue.label) return this.defaultValue.label;

    return this.placeholder;
  }

  get selectWrapperStyle() {
    return { 'select-wrapper': true, active: this.showOptionsDisplay };
  }

  get selectContainerStyle() {
    return {
      'select-container': true,
      'overflow-screen': this.overflowScreenWidth
    };
  }
}
</script>

<style lang="less" scoped>
.overflow-screen {
  @media only screen and (max-width: 640px) {
    position: relative;

    .select-wrapper {
      position: initial;

      .select-options {
        max-width: 100%;
        left: 0 !important;
        right: unset !important;

        > * {
          word-break: break-all;
        }
      }
    }
  }
}

.select-container {
  width: 100%;
}

.select-wrapper {
  position: relative;
  display: flex;
  border-radius: @size-radius-max;
  box-sizing: border-box;

  &:hover,
  &.active {
    background: var(--select-bg-color);
  }
}

.select-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: @size-spacing-x150 @size-spacing-x350;
  box-sizing: border-box;
  color: @neutral-primary;
  cursor: pointer;

  > span {
    margin-left: @size-spacing-x150;
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.select-icon {
  margin-right: calc(-1 * @size-spacing-x300);
  font-size: 0;
}

.select-options {
  position: absolute;
  margin: 0;
  top: 40px;
  width: auto;
  min-width: 100%;
  list-style: none;
  background: @background-tertiary;
  border-radius: @size-radius-x300;
  padding: @size-spacing-x150;
  box-sizing: border-box;
  max-height: 224px;
  overflow-x: auto;
  z-index: 9999;

  > * {
    padding: @size-spacing-x300 @size-spacing-x350;
    border-radius: @size-radius-x300;
    cursor: pointer;
    color: @neutral-primary;
    .text-p-5();

    &.selected {
      font-weight: bold;
    }

    &:hover {
      background-color: @background-hover;
    }
  }
}
</style>
