<template>
  <div>
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Provide } from 'vue-property-decorator';
import { TabsProps } from './TabsProps';

@Component({
  name: 'Tabs'
})
export default class Tabs extends Vue {
  @Prop({ type: Number, default: 0 })
  private selectedIndex!: number;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @Prop({ type: Function, default: () => {} })
  private onChange!: (value: number) => unknown;

  @Provide('tabsProps')
  private tabsProps: TabsProps = {
    selectedIndex: this.selectedIndex,
    setTab: this.setTab
  };

  private setTab(selectedIndex: number) {
    this.tabsProps.selectedIndex = selectedIndex;

    this.onChange(selectedIndex);
  }

  private updated() {
    this.tabsProps.selectedIndex = this.selectedIndex;
  }
}
</script>
