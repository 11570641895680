<template>
  <section class="content-display">
    <div
      v-if="lineColor"
      class="content-display__line"
      :style="{ backgroundColor: lineColor }"
    />
    <div :class="computedBlockStyle">
      <div
        v-for="list in blocksLength"
        :key="list"
        class="content-display__block"
      >
        <slot :name="`content-block-${list}`" />
      </div>
    </div>
    <FooterDisplay
      :footer-disabled="footerDisabled"
      :footer-href="footerHref"
      :footer-label="footerLabel"
      :footer-loading="footerLoading"
      :footer-type="footerType"
      :footer-content-aspect="contentAspect"
      @click="footerEvent"
    />
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import FooterDisplay from './footer-display/FooterDisplay.vue';

import { FooterTypes, ContentAspect } from './types';

@Component({
  name: 'ContentDisplay',
  components: {
    FooterDisplay
  }
})
export default class ContentDisplay extends Vue {
  /**
   * Define o tipo do botão a ser exibido no footer
   * @values caption, link, navigation ou button
   * @defaults navigation
   */
  @Prop({ type: String, default: FooterTypes.NAVIGATION })
  readonly footerType!: string;

  /**
   * Define a quantidade de slot que podem ser utilizadas
   */
  @Prop({ type: Number, default: 1 })
  readonly blocksLength!: number;

  /**
   * Define visualmente o espaçamento interno do entre as bordas e conteúdos.
   * Valores: 'inside' (default) ou 'outside'
   */
  @Prop({ type: String, default: ContentAspect.INSIDE })
  readonly contentAspect!: string;

  /**
   * Label a ser exibida no button ou caption
   */
  @Prop({ type: String, required: false })
  readonly footerLabel?: string;

  /**
   * Informa se o botão (quando houver) deve estar desabilitado
   */
  @Prop({ type: Boolean, default: false })
  readonly footerDisabled!: boolean;

  /**
   * Informa o href para o button caso a navegação seja via url
   */
  @Prop({ type: String, required: false })
  readonly footerHref?: string;

  /**
   * Informa se o estado do botão deve ser de loading
   */
  @Prop({ type: Boolean, default: false })
  readonly footerLoading!: boolean;

  /**
   * Informa a cor da linha renderizada a esquerda do componente
   * somente ao informar a cor a linha será mostrada
   */
  @Prop({ type: String, required: false })
  readonly lineColor!: string;

  /**
   * Evento de click do elemento do footer, quando existente
   */
  @Emit('click')
  private footerEvent() {
    // event emitter on button footer click
  }

  get computedBlockStyle() {
    return [
      'content-display__blocks',
      `content-display__${this.contentAspect}`
    ];
  }
}
</script>

<style lang="less" scoped>
.content-display {
  position: relative;
  border: @size-border-x400 solid @divider-primary;
  border-radius: @size-radius-x200;
  background-color: @background-secondary;
  overflow: hidden;

  &__line {
    position: absolute;
    top: 0;
    left: 0;
    width: @size-border-x600;
    height: 100%;
  }

  &__blocks {
    box-sizing: border-box;
    margin: @size-spacing-x500 @size-spacing-x600;
    display: grid;
    gap: @size-spacing-x500;
  }

  &__outside {
    margin: @size-spacing-x800 @size-spacing-x900 @size-spacing-x500;
  }
}
</style>
