<template>
  <div class="horizontal-slider__container">
    <div v-if="showLeftButton" class="horizontal-slider__button--left">
      <button
        aria-hidden="true"
        title="Anterior"
        class="horizontal-slider__button"
        type="button"
        @click="onLeftClick"
      >
        <RoundedIcon
          icon-name="ic_chevron_left"
          main-color="transparent"
          :hover-color="NebraskaColors.backgroundHover"
          icon-size="24px"
          has-hover
          :text-color="NebraskaColors.elementSecondary"
        />
      </button>
    </div>
    <div v-if="showRightButton" class="horizontal-slider__button--right">
      <button
        aria-hidden="true"
        title="Próximo"
        class="horizontal-slider__button"
        type="button"
        @click="onRightClick"
      >
        <RoundedIcon
          main-color="transparent"
          :hover-color="NebraskaColors.backgroundHover"
          icon-name="ic_chevron_right"
          icon-size="24px"
          has-hover
          :text-color="NebraskaColors.elementSecondary"
        />
      </button>
    </div>
    <div ref="slideRef" class="horizontal-slider__slider">
      <div ref="contentRef" class="horizontal-slider__content">
        <!-- @slot Todos os items que devem estar dentro do slider -->
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Ref } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { RoundedIcon } from '@/components/legacy';
import throttle from 'lodash.throttle';

@Component({
  name: 'HorizontalSlider',
  components: {
    RoundedIcon
  }
})
export default class HorizontalSlider extends Vue {
  @Ref('slideRef')
  private slideRef!: HTMLDivElement;

  @Ref('contentRef')
  private contentRef!: HTMLDivElement;

  private showLeftButton = true;

  private showRightButton = true;

  private NebraskaColors = NebraskaColors;

  private removeOnResizeListener!: () => void;

  mounted() {
    this.setButtonsAppearance();
    this.setScrollEvent();
    this.setResizeEvent();
  }

  destroyed() {
    this.removeOnResizeListener();
  }

  private setScrollEvent() {
    this.slideRef.addEventListener(
      'scroll',
      throttle(this.setButtonsAppearance, 150)
    );
  }

  private setResizeEvent() {
    const handleOnResize = throttle(this.setButtonsAppearance, 500);

    window.addEventListener('resize', handleOnResize);
    this.removeOnResizeListener = () => {
      window.removeEventListener('resize', handleOnResize);
    };
  }

  private getSlideWidth() {
    const slideRect = this.slideRef.getBoundingClientRect();

    return slideRect.width;
  }

  private setButtonsAppearance() {
    const contentRect = this.contentRef.getBoundingClientRect();
    const scrollLeft = this.slideRef.scrollLeft;
    const slideWidth = this.getSlideWidth();
    const canShowRightButton =
      Math.floor(contentRect.width - slideWidth) > scrollLeft;
    const canShowLeftButton = scrollLeft > 0;

    this.showRightButton = canShowRightButton;
    this.showLeftButton = canShowLeftButton;
  }

  private getWidthToScroll() {
    const slideWidth = this.getSlideWidth();

    return slideWidth * 0.5;
  }

  private scrollLeft(left: number) {
    this.slideRef.scrollTo({
      left,
      behavior: 'smooth'
    });
  }

  private onRightClick() {
    const widthToScroll = this.getWidthToScroll();
    const newScrollLeft = this.slideRef.scrollLeft + widthToScroll;

    this.scrollLeft(newScrollLeft);
  }

  private onLeftClick() {
    const widthToScroll = this.getWidthToScroll();
    const newScrollLeft = this.slideRef.scrollLeft - widthToScroll;

    this.scrollLeft(newScrollLeft);
  }
}
</script>

<style lang="less" scoped>
.horizontal-slider {
  &__container {
    position: relative;
    width: 100%;
  }
  &__button {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: @background-secondary;
    padding: 0;
    border: none;

    &--left::after,
    &--right::before {
      position: absolute;
      top: 0;
      content: '';
      height: 100%;
      width: 32px;
    }
    &--left,
    &--right {
      position: absolute;
      top: 0;
      height: 100%;
    }
    &--left {
      left: 0;
      &::after {
        right: calc(@size-spacing-x600 * -1);
        background: linear-gradient(
          90deg,
          @background-secondary 15%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
    &--right {
      right: 0;
      &::before {
        left: calc(@size-spacing-x600 * -1);
        background: linear-gradient(
          270deg,
          @background-secondary 15%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }
  &__slider {
    width: 100%;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__content {
    display: inline-flex;
    height: 100%;
    vertical-align: top;
  }
}
</style>
