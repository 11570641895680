<template>
  <BaseCell class="button-cell" align="right" v-bind="$attrs">
    <ButtonIconSecondary
      v-if="showButton"
      class="button-cell__button"
      :size="size"
      :icon="icon"
      :label="label"
      :disabled="disabled"
      :href="href"
      @click="emitButtonClick"
    />
  </BaseCell>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
  Emit,
  InjectReactive
} from 'vue-property-decorator';

import ButtonIconSecondary from '@/components/button-icon/button-icon-secondary/ButtonIconSecondary.vue';
import BaseCell from './BaseCell.vue';
import { DataTableStore } from '../DataTableProps';

@Component({
  components: { BaseCell, ButtonIconSecondary }
})
export default class ButtonCell extends Vue {
  @InjectReactive('dataTableStore')
  private readonly dataTableStore!: DataTableStore;

  /**
   * @internal Valor único que serve como key/id da row
   */
  @Prop({ type: String, required: true })
  readonly _hash!: string;

  /**
   * O nome do ícone a ser exibido
   */
  @Prop({ type: String, required: true })
  readonly icon!: string;

  /**
   * Define o tamanho do botão
   * @values small, regular, large
   */
  @Prop({ type: String, default: 'small' })
  readonly size!: string;

  /**
   * O rótulo do botão.
   * A label não é exibida, mas é adicionada por questão de acessibilidade
   */
  @Prop({ type: String, required: true })
  readonly label!: string;

  /**
   * Desativa o botão
   */
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  /**
   * Informa se o botão deve ser renderizado
   */
  @Prop({ type: Boolean, default: true })
  readonly showButton!: boolean;

  /**
   * Permite atribuir um link ao botão
   */
  @Prop({ type: String, required: false })
  readonly href?: string;

  /**
   * Evento emitido quando o usuário clica no botão
   * @param row dados do item da linha
   */
  @Emit('on-button-click')
  emitButtonClick() {
    return this.dataTableStore.data.find(row => row._hash === this._hash);
  }
}
</script>
