<template>
  <section
    role="alert"
    aria-label="Mensagem de aviso"
    :class="['context-message', `context-message--${type}`]"
  >
    <header>
      <strong class="context-message__title">{{ title }}</strong>
      <IconButton
        v-if="isClosable"
        icon-size="24"
        class="context-message__close-btn"
        aria-label="Fechar mensagem de aviso"
        icon-name="ic_cancel"
        data-testid="close-btn"
        icon-colors="currentColor"
        @click="emitClose"
      />
    </header>
    <p class="context-message__description">
      <!-- @slot Conteúdo da mensagem -->
      <slot />
    </p>
  </section>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import IconButton from '../icon-button/IconButton.vue';
/**
 * @deprecated
 */
@Component({
  name: 'ContextMessage',
  components: { IconButton }
})
export default class ContextMessage extends Vue {
  /**
   * O título a ser exibido
   */
  @Prop({ type: String, required: true })
  private title!: string;

  /**
   *  Define a cor da caixa conforme o tipo da mensagem
   *  @values alert, info, negative, success
   */
  @Prop({ type: String, required: true })
  private type!: 'alert' | 'info' | 'negative' | 'success';

  /**
   *  Define a exibição de um botão para fechar
   */
  @Prop({ type: Boolean, default: true })
  private isClosable!: boolean;

  /**
   *  Evento emitido ao clicar no botão de fechar
   */
  @Emit('onClose')
  private emitClose() {
    // Função que emite evento
  }
}
</script>

<style lang="less" scoped>
.context-message {
  padding: @size-spacing-x500;
  border-radius: @size-radius-x200;

  &--alert {
    color: @status-alert;
    background: @status-alert-background;
  }

  &--info {
    color: @status-info;
    background: @status-info-background;
  }

  &--negative {
    color: @status-negative;
    background: @status-negative-background;
  }

  &--success {
    color: @status-positive;
    background: @status-positive-background;
  }

  header {
    width: 100%;
    min-height: 24px;
    margin-bottom: @size-spacing-x350;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    @safe-space-after-title: @size-spacing-x350;
    padding-right: @safe-space-after-title;
  }

  &__close-btn {
    padding: 0;
    color: currentColor;
  }

  &__description {
    .text-p-5();
    margin: 0;

    * {
      color: currentColor;
    }
  }
}
</style>
