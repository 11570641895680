<template>
  <Button
    :class="`button-box-icon ${computedClassBlur}`"
    data-testid="button-box-icon"
    :colors="buttonBoxColors"
    :href="href"
    :disabled="disabled"
    :loading="loading"
    v-on="$listeners"
  >
    <div class="content">
      <InitialAvatarIcon
        size="large"
        :icon="icon"
        :class="`icon icon--${computedClass} icon--${type}`"
      />
      <section class="section">
        <span :class="`title title--${computedClass}`">
          {{ title }}
        </span>
        <span
          v-if="description"
          :class="`description description--${computedClass}`"
        >
          {{ description }}
        </span>
      </section>
    </div>
  </Button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/foundation/button/Button.vue';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import InitialAvatarIcon from '@/components/initial-avatar/initial-avatar-icon/InitialAvatarIcon.vue';
import { buttonBoxColors, buttonBoxBlurColors } from '@/styles/Button';

@Component({
  name: 'ButtonBoxDecide',
  components: { Button, BaseIcon, InitialAvatarIcon }
})
export default class ButtonBoxDecide extends Vue {
  /**
   * O ícone a ser exibido
   */
  @Prop({ type: String, required: true })
  readonly icon!: string;

  /**
   * O tipo do ícone a ser exibido
   */
  @Prop({ type: String, default: 'neutral' })
  readonly type!: 'neutral' | 'positive' | 'negative';

  /**
   * O título do botão
   */
  @Prop({ type: String, required: true })
  readonly title!: string;

  /**
   * Descrição do botão
   */
  @Prop({ type: String })
  readonly description?: string;

  /**
   *  Desativa o botão
   */
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  /**
   * Coloca o botão no estado "carregando"
   */
  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean;

  /**
   * Permite atribuir um link ao botão
   */
  @Prop({ type: String })
  readonly href?: string;

  /**
   *  Coloca o botão com estilo do tipo blur
   */
  @Prop({ type: Boolean, default: false })
  readonly isBlur!: boolean;

  readonly buttonBoxColors = this.isBlur
    ? buttonBoxBlurColors
    : buttonBoxColors;

  private get computedClass(): string {
    return this.disabled ? 'disabled' : '';
  }

  private get computedClassBlur(): string {
    return this.isBlur ? `blur blur--${this.computedClass}` : '';
  }
}
</script>

<style lang="less" scoped>
.button-box-icon {
  background: @base-primary;
  width: 100%;
  padding: @size-spacing-x700 @size-spacing-x600;
  border: @size-border-x400 solid @divider-primary;
  border-radius: @size-radius-x300;

  ::v-deep.button--container:hover {
    transform: none;
  }

  &.blur {
    backdrop-filter: blur(5px) saturate(1.4) brightness(1.01);
    background: rgba(255, 255, 255, 0.1);
    border: 0;

    &--disabled {
      opacity: 0.5;
    }

    .label,
    .icon {
      backdrop-filter: blur(5px) saturate(1.4) brightness(1.01);
      background: rgba(255, 255, 255, 0.1);
      color: @text-over-image;
    }
  }
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
}

.icon {
  padding: @size-spacing-x150;
  color: @element-primary;

  &--neutral {
    background-color: @background-tertiary;
    color: @element-primary;
  }

  &--positive {
    background-color: @status-positive-background;
    color: @status-positive;
  }

  &--negative {
    background-color: @status-negative-background;
    color: @status-negative;
  }

  &--disabled {
    background-color: @base-disabled;
    color: @base-over-disabled;
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: @size-spacing-x500;
  text-align: left;

  .title {
    .text-h-5();

    &--disabled,
    &--disabled > :deep(*) {
      color: @base-disabled !important;
    }
  }

  .description {
    .text-p-5();

    margin-top: @size-spacing-x150;
    color: @element-secondary;

    &--disabled {
      color: @base-disabled;
    }
  }
}
</style>
