<template>
  <button
    type="button"
    class="menu-item-area"
    :class="buttonClass"
    :aria-disabled="disabled"
    :aria-label="label"
    :tabindex="tabindex"
    data-testid="menu-item-foundation"
    @click="onClick"
  >
    <div class="left-icon">
      <BaseIcon
        v-if="leftIcon && isPrimary"
        :icon="leftIcon"
        data-testid="menu-item-left-icon"
      />
    </div>
    <p :class="labelClass">{{ label }}</p>
    <div class="right-icon">
      <BaseIcon
        v-if="shouldHaveChevronIcon"
        :icon="chevronIcon"
        data-testid="menu-item-right-icon"
      />
    </div>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import { SidebarItemFoundationType } from '@/foundation/sidebar-item-foundation/types';

@Component({
  components: {
    BaseIcon
  }
})
export default class SidebarItemFoundation extends Vue {
  /**
   * Texto exibido no Menu Item
   */
  @Prop({ type: String, required: true })
  public readonly label!: string;

  /**
   * Define se o Menu item está com o aspecto ativado
   */
  @Prop({ default: false, type: Boolean })
  public readonly active!: boolean;

  /**
   * Define se o Menu item está desativado para interação
   */
  @Prop({ default: false, type: Boolean })
  public readonly disabled!: boolean;

  /**
   * Ícone a esquerda dentro do SidebarItemFoundation
   */
  @Prop({ type: String, required: false })
  public readonly leftIcon?: string;

  /**
   * Habilita o ícone chevron para o tipo primário
   */
  @Prop({ type: Boolean, default: false })
  public readonly chevron!: boolean;

  /**
   * Defini se a label vai preencher o espaço deixado pelo o ícone caso ele não exista
   */
  @Prop({ type: Boolean, default: true })
  public readonly labelFillIconSpace!: boolean;

  /**
   * Defini o tipo do SidebarItemFoundation
   * @values primary, secondary
   */
  @Prop({ type: String, default: SidebarItemFoundationType.Primary })
  public readonly type!: SidebarItemFoundationType;

  /**
   * Define qual a posição do elemento durante a navegação pelo teclado.
   */
  @Prop({ type: Number, required: false })
  readonly tabindex?: number;

  /**
   * Evento de click.
   */
  @Emit('on-click')
  public onClickEvent() {
    // Função que emite evento on-click
  }

  public get isPrimary() {
    return this.type === SidebarItemFoundationType.Primary;
  }

  public get shouldHaveChevronIcon() {
    return this.isPrimary && this.chevron;
  }

  public get chevronIcon() {
    return this.active ? 'EA0150' : 'EA0165';
  }

  public get buttonClass() {
    return {
      active: this.active && !this.disabled,
      disabled: this.disabled,
      primary: this.isPrimary,
      secondary: !this.isPrimary,
      'chevron-active': this.active && this.shouldHaveChevronIcon
    };
  }

  public get labelClass() {
    return {
      'icon-spacing': this.leftIcon || !this.labelFillIconSpace
    };
  }

  public onClick() {
    this.onClickEvent();
  }
}
</script>

<style scoped lang="less">
.menu-item-area {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  border: @size-border-x500 solid transparent;
  border-radius: @size-radius-x100;
  background-color: @background-secondary;
  cursor: pointer;
  width: 100%;
  transition: all 0.2s;
  .text-p-4();

  p {
    position: absolute;
    &.icon-spacing {
      left: @size-spacing-x900;
    }
  }

  &:focus-within {
    border: @size-border-x400 solid @element-primary;
  }

  &:not(.disable):hover {
    background-color: @background-hover;
  }

  &.chevron-active {
    font-weight: 600;
  }

  &.primary {
    color: @element-primary;
    height: 50px;
    padding: @size-spacing-x350 @size-spacing-x400;
  }

  &.secondary {
    color: @element-secondary;
    height: 40px;
    padding: @size-spacing-x300 @size-spacing-x400;
    .text-p-5();
  }

  &:not(.chevron-active).active {
    color: @element-primary;
    background-color: @brand-secondary;
    font-weight: 600;

    &:hover {
      background-color: @brand-secondary-darken;
    }
  }

  &.disabled {
    color: @text-disabled;
    pointer-events: none;
  }
}
</style>
