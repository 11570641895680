<script lang="ts">
import { CreateElement } from 'vue';
import { Component, Vue, Inject } from 'vue-property-decorator';
import { TabsProps } from './TabsProps';

@Component({
  name: 'TabList'
})
export default class TabList extends Vue {
  @Inject('tabsProps')
  private readonly tabsProps!: TabsProps;

  public render(createElement: CreateElement) {
    const { selectedIndex, setTab } = this.tabsProps;

    const children = this.$slots
      .default!.filter(vNode => vNode.tag)
      .map((vNode, index) => {
        if (vNode.componentOptions) {
          return createElement(
            vNode.componentOptions.Ctor,
            {
              props: {
                index,
                actived: selectedIndex === index,
                ...vNode.componentOptions.propsData
              },
              on: {
                'set-tab': (tab: number) => {
                  setTab(tab);
                }
              }
            },
            vNode.componentOptions.children
          );
        }
        return undefined;
      });
    return createElement('div', { class: 'tabs-container' }, children);
  }
}
</script>

<style lang="less" scoped>
.tabs-container {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0;
}
</style>
