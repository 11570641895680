<template>
  <div
    v-if="withContainer"
    class="loader-indicator__container"
    role="progressbar"
    :style="{
      'min-height': `${minHeight}px`
    }"
  >
    <Loader :size="size" />
  </div>
  <Loader v-else :size="size" />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import Loader from './Loader.vue';

@Component({
  name: 'LoaderIndicator',
  components: {
    BaseIcon,
    Loader
  }
})
export default class LoaderIndicator extends Vue {
  /**
   * Tamanho do loader setado como String. Min: 24 | Max: 56
   */
  @Prop({
    type: String,
    default: '56',
    validator: (value: string) => Number(value) >= 24 && Number(value) <= 56
  })
  readonly size!: string;

  /**
   * Altura mínima do container quando "withContainer" for true
   */
  @Prop({ type: String, default: '400' })
  readonly minHeight!: string;

  /**
   * Define se o loader deve ser exibido dentro de um container.
   * Útil para loaders que são exibidos em tela inteira ou espaços pré-definidos
   */
  @Prop({ type: Boolean, default: false })
  readonly withContainer!: boolean;
}
</script>

<style lang="less" scoped>
.loader-indicator {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: transparent;
  }
}
</style>
