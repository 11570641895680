<template>
  <span class="tag text-p-6" :class="computedClassName">
    <slot />
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
/**
 * @deprecated
 */
@Component({
  name: 'Tag'
})
export default class Tag extends Vue {
  @Prop(Boolean) public error!: boolean;

  @Prop(Boolean) public success!: boolean;

  @Prop(Boolean) public alert!: boolean;

  @Prop(Boolean) public info!: boolean;

  private get computedClassName() {
    return [
      this.error ? 'tag--error' : '',
      this.success ? 'tag--success' : '',
      this.alert ? 'tag--alert' : '',
      this.info ? 'tag--info' : ''
    ];
  }
}
</script>

<style lang="less" scoped>
.tag {
  display: inline-block;
  overflow: hidden;
  padding: @size-spacing-x75 @size-spacing-x350;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: @size-radius-max;
  color: @theme-over-primary;

  &.tag--error {
    background: @status-negative-background;
    color: @status-negative;
  }

  &.tag--success {
    background: @status-positive-background;
    color: @status-positive;
  }

  &.tag--alert {
    background: @status-alert-background;
    color: @status-alert;
  }

  &.tag--info {
    background: @status-info-background;
    color: @status-info;
  }
}
</style>
