<template>
  <table class="table-display" :aria-describedby="tableDescription">
    <thead>
      <tr class="table-display__row">
        <th
          v-for="(column, index) in columns"
          :key="index"
          :class="[computedCellClass, 'table-display__cell--header']"
          :align="getCellAlignment(column)"
          :width="column.width"
        >
          {{ column.header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, rowIndex) in data"
        :key="getRowKey(row, rowIndex)"
        class="table-display__row"
      >
        <td
          v-for="(column, columnIndex) in columns"
          :key="columnIndex"
          :class="computedCellClass"
          :align="getCellAlignment(column)"
          :width="column.width"
        >
          <component
            :is="column.component"
            v-if="column.component"
            v-bind="getCustomComponentProps(column, row)"
          />
          <template v-else>
            {{ getCellValue(column.getter, row) }}
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

import {
  TableDisplayColumn,
  ColumnGetter,
  TableDisplaySizes,
  Row
} from './types';

@Component({})
export default class TableDisplay extends Vue {
  /**
   * Indica os dados que serão utilizadas por cada row da tabela.
   */
  @Prop({ type: Array, required: true })
  readonly data!: Row[];

  /**
   * Indica a descrição da tabela.
   */
  @Prop({
    type: String,
    required: false,
    default: 'Tabela com listagem simples'
  })
  readonly tableDescription!: string;

  /**
   * Indica as colunas da tabela, com seus respectivos nomes, getters e outras propriedades que possibilitam customizar cada coluna.
   */
  @Prop({ type: Array, required: true })
  readonly columns!: TableDisplayColumn[];

  /**
   * Indica o tamanho da tabela, alterando o tamanho dos textos e espaçamentos.
   * Values: small, medium, large
   */
  @Prop({
    type: String,
    default: TableDisplaySizes.MEDIUM,
    validator: (value: TableDisplaySizes) =>
      Object.values(TableDisplaySizes).includes(value)
  })
  readonly size!: TableDisplaySizes;

  /**
   * Indica uma função que será utilizada para gerar a key de cada row da tabela.
   */
  @Prop({
    type: Function,
    required: false
  })
  readonly generateRowKey!: (row: Row) => string;

  get computedCellClass() {
    return {
      'table-display__cell': true,
      'table-display__cell--small': this.size === TableDisplaySizes.SMALL,
      'table-display__cell--medium': this.size === TableDisplaySizes.MEDIUM,
      'table-display__cell--large': this.size === TableDisplaySizes.LARGE
    };
  }

  getCellValue(getter: ColumnGetter, row: Row) {
    if (typeof getter === 'function') {
      return getter(row);
    }
    return row[getter];
  }

  getCellAlignment(column: TableDisplayColumn) {
    return column.align || 'left';
  }

  getRowKey(row: Row, index: number) {
    if (this.generateRowKey) {
      return this.generateRowKey(row);
    }
    return index;
  }

  getCustomComponentProps(column: TableDisplayColumn, row: Row) {
    if (column.getComponentProps) {
      return column.getComponentProps(row);
    }
    return {};
  }
}
</script>

<style lang="less" scoped>
.table-display {
  width: 100%;

  &__cell {
    padding: @size-spacing-x300;
    color: @text-primary;

    &--header {
      color: @text-secondary;
      font-weight: normal;
    }

    &--small {
      .text-p-6();
    }

    &--medium {
      .text-p-5();
    }

    &--large {
      .text-p-4();
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}
</style>
