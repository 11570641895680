<template>
  <button
    class="visibility-toggle--container"
    :aria-label="buttonLabel"
    role="button"
    type="button"
    @click="!disabled && change()"
  >
    <RoundedIcon
      :has-hover="!disabled"
      :icon-name="iconName"
      icon-size="24"
      main-color="transparent"
      :hover-color="NebraskaColors.backgroundHover"
      :text-color="activeColor"
    />
  </button>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import RoundedIcon from '@/components/legacy/rounded-icon/RoundedIcon.vue';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  name: 'VisibilityToggle',
  components: { RoundedIcon }
})
export default class VisibilityToggle extends Vue {
  /**
   * O status atual do toggle
   */
  @Prop({
    type: Boolean,
    required: true
  })
  public hidden!: boolean;

  /**
   * Desativa o botão e as interações com o mouse
   */
  @Prop({
    type: Boolean,
    default: false
  })
  public disabled!: boolean;

  /**
   * Define o uso da cor (quando habilitado) através do componente pai, caso contrário o comportamento default é a cor elementPrimary
   */
  @Prop({
    type: Boolean,
    default: false
  })
  public useCurrentColor!: boolean;

  /**
   * Completa o aria-label do botão, que inicia com "Exibir" ou "Ocultar" (a depender do estado)
   */
  @Prop({
    type: String,
    default: ''
  })
  public subject!: string;

  private NebraskaColors = NebraskaColors;

  /**
   * Retorna o status oposto do toggle.
   * @return {boolean} O novo estado de visibilidade.
   */
  @Emit()
  public change(): boolean {
    return !this.hidden;
  }

  private get iconName(): string {
    return this.hidden ? 'EE0130' : 'EE0131';
  }

  private get buttonLabel(): string {
    let label = this.hidden ? 'Exibir' : 'Ocultar';

    label += ` ${this.subject}`;

    return label.trim();
  }

  public get activeColor() {
    if (this.disabled) return NebraskaColors.elementDisabled;
    return this.useCurrentColor
      ? 'currentColor'
      : NebraskaColors.elementPrimary;
  }
}
</script>

<style lang="less" scoped>
.visibility-toggle--container {
  all: unset;
}
</style>
