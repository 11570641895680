<template>
  <BaseCell
    class="text-p-5 toggle-cell"
    v-bind="$attrs"
    data-testid="base-cell"
  >
    <ButtonIconSecondary
      class="toggle-cell__icon"
      size="small"
      :icon="iconName"
      label="Abrir tabela filha"
      data-testid="toggle-button"
      @click="toggle"
    />
  </BaseCell>
</template>

<script lang="ts">
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import ButtonIconSecondary from '@/components/button-icon/button-icon-secondary/ButtonIconSecondary.vue';
import BaseCell from './BaseCell.vue';
import { DataTableStore } from '../DataTableProps';

@Component({
  name: 'ToggleCell',
  components: { BaseCell, ButtonIconSecondary }
})
export default class ToggleCell extends Vue {
  @InjectReactive('dataTableStore')
  private readonly dataTableStore!: DataTableStore;

  @Prop({ type: String, required: false, default: '' })
  private _hash!: string;

  private get iconColors() {
    return NebraskaColors.elementPrimary;
  }

  private get isOpen() {
    return this.dataTableStore.openedItem === this._hash;
  }

  private async toggle() {
    const state = this.isOpen ? null : this._hash;
    this.dataTableStore.setOpenedItem(state);
  }

  private get iconName() {
    return this.isOpen ? 'ic_chevron_up' : 'ic_chevron_down';
  }
}
</script>

<style lang="less" scoped>
.toggle-cell {
  padding: 0;
  width: 40px;
}
</style>
