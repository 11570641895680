<template>
  <div class="error-label">
    <BaseText size="sm" :color="NebraskaColors.elementSecondary">
      {{ title }}
    </BaseText>
    <Button
      class="error-label__button"
      size="xs"
      :colors="buttonColorScheme"
      @click="onErrorClick"
    >
      <BaseText class="error-label__text" size="sm">
        {{ subtitle }}
      </BaseText>
    </Button>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { BaseText } from '@/components/base-text';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import Button from '@/foundation/button/Button.vue';

@Component({
  components: { BaseText, Button }
})
export default class ErrorLabel extends Vue {
  readonly NebraskaColors = NebraskaColors;

  readonly buttonColorScheme = {
    background: 'transparent',
    content: NebraskaColors.elementPrimary,
    alphas: {
      default: 1,
      hover: 0.9,
      active: 0.8
    }
  };

  /**
   * Título abrangente para o componente de erro.
   */
  @Prop({ type: String })
  readonly title!: string;

  /**
   * Label a ser adicionada no botão, exibida visualmente como um subtítulo.
   */
  @Prop({ type: String })
  readonly subtitle!: string;

  @Emit('on-error-click')
  private onErrorClick() {
    // event emitter on subtitle click
  }
}
</script>

<style lang="less" scoped>
.error-label {
  text-align: center;
  pointer-events: initial;

  &__text {
    font-weight: bold;
  }
}
</style>
