<template>
  <div
    v-if="showLoader"
    class="loader"
    :minHeight="{ width: `${size}px`, height: `${size}px` }"
    :class="!color ? 'standard' : 'custom'"
    :style="{
      color: color,
      'background-color': background,
      'min-height': `${minHeight}px`
    }"
  >
    <div v-if="!whitelabel">
      <svg
        viewBox="0 0 48 47"
        class="loader-img"
        :style="{ width: `${size}px`, height: `${size}px` }"
      >
        <path
          d="M36 29.55c0 2.538-2.05 4.595-4.578 4.595a.962.962 0 0 1-.96-.964V13.007c0-.532.43-.964.96-.964h3.618c.53 0 .96.432.96.964v16.544zm-9.23 3.631c0 .533-.43.964-.961.964H22.19a.962.962 0 0 1-.96-.964V18.533c0-.532.43-.964.96-.964h3.618c.53 0 .96.432.96.964v14.648zm-9.232 0c0 .533-.43.964-.96.964H12.96a.962.962 0 0 1-.961-.964V15.787c0-.533.43-.964.96-.964h3.618c.53 0 .96.431.96.964V33.18zM30.461 0H17.538C7.852 0 0 7.88 0 17.602v27.623c0 .532.43.964.96.964h29.501C40.148 46.189 48 38.308 48 28.587V17.602C48 7.881 40.148 0 30.461 0z"
          :style="{ fill: color }"
        />
      </svg>
    </div>

    <div v-if="whitelabel">
      <svg
        class="loader-whitelabel-img lds-ellipsis animation--running"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 100 100"
        :style="{ width: `${size}px`, height: `${size}px`, fill: color }"
      >
        <circle cx="16" cy="50" r="10" class="animation--running">
          <animate
            attributeName="r"
            begin="0s"
            calcMode="spline"
            dur="2.3s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="10;0;0;0;0"
          />
          <animate
            attributeName="cx"
            begin="0s"
            calcMode="spline"
            dur="2.3s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="84;84;84;84;84"
          />
        </circle>
        <circle cx="16" cy="50" r="10" class="animation--running">
          <animate
            attributeName="r"
            begin="-1.15s"
            calcMode="spline"
            dur="2.3s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="0;10;10;10;0"
          />
          <animate
            attributeName="cx"
            begin="-1.15s"
            calcMode="spline"
            dur="2.3s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="16;16;50;84;84"
          />
        </circle>
        <circle cx="16" cy="50" r="10" class="animation--running">
          <animate
            attributeName="r"
            begin="-0.575s"
            calcMode="spline"
            dur="2.3s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="0;10;10;10;0"
          />
          <animate
            attributeName="cx"
            begin="-0.575s"
            calcMode="spline"
            dur="2.3s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="16;16;50;84;84"
          />
        </circle>
        <circle cx="16" cy="50" r="10" class="animation--running">
          <animate
            attributeName="r"
            begin="0s"
            calcMode="spline"
            dur="2.3s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="0;10;10;10;0"
          />
          <animate
            attributeName="cx"
            begin="0s"
            calcMode="spline"
            dur="2.3s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="16;16;50;84;84"
          />
        </circle>
        <circle cx="16" cy="50" r="10" class="animation--running">
          <animate
            attributeName="r"
            begin="0s"
            calcMode="spline"
            dur="2.3s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="0;0;10;10;10"
          />
          <animate
            attributeName="cx"
            begin="0s"
            calcMode="spline"
            dur="2.3s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            keyTimes="0;0.25;0.5;0.75;1"
            repeatCount="indefinite"
            values="16;16;16;50;84"
          />
        </circle>
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'BaseLoader'
})
export default class BaseLoader extends Vue {
  /**
   * Altera o desenho do loader para os três círculos, ao invés do logo da Warren
   */
  @Prop({ type: Boolean, default: false })
  public whitelabel!: boolean;

  /**
   * A cor de fundo da tela de loading
   */
  @Prop({ type: String, default: NebraskaColors.backgroundPrimary })
  public background!: string;

  /**
   * A cor do loader; quando definida, o desenho é alterado para os círculos
   */
  @Prop({ type: String, default: NebraskaColors.themePrimary })
  public color!: string;

  /**
   * Define o tamanho do ícone
   */
  @Prop({ type: String, default: '48' })
  public size!: string;

  /**
   * Define a altura mínima para a tela do loader
   */
  @Prop({ type: String, default: '400' })
  public minHeight!: string;

  /**
   * Define se o loader deve ser exibido na tela; pode ser também substituído por um `v-if` na view
   */
  @Prop({ type: Boolean, default: false, required: true })
  public showLoader!: boolean;
}
</script>

<style lang="less" scoped>
.loader-img path {
  animation: loader-animation-colors 10s linear infinite;

  *[loki-test] & {
    animation: none !important;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 400px;

  svg {
    display: block;
    width: 48px;
    height: 48px;
  }
}

.animation {
  &--running {
    animation-play-state: running;
    animation-delay: 0s;
  }
}

@keyframes loader-animation-colors {
  0% {
    fill: transparent;
  }
  12.5% {
    fill: #ee2e5d;
  }
  25% {
    fill: transparent;
  }
  37.5% {
    fill: #ffd000;
  }
  50% {
    fill: transparent;
  }
  62.5% {
    fill: #0581ff;
  }
  75% {
    fill: transparent;
  }
  87.5% {
    fill: #6960da;
  }
  100% {
    fill: transparent;
  }
}
</style>
