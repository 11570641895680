<template>
  <BaseIcon :icon="getBankIcon" width="56" height="56" :original="true" />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import { EBankCode, MapBankIcon } from '@/typings/Bank';

/**
 * @deprecated
 */
@Component({
  name: 'BankIcon',
  components: { BaseIcon }
})
export default class BankIcon extends Vue {
  /**
   * Código do banco usado para seleção da imagem
   */
  @Prop({ type: String, required: true })
  private bankCode!: EBankCode;

  private get getBankIcon() {
    return MapBankIcon.get(this.bankCode);
  }
}
</script>
