<template>
  <InitialAvatarFoundation
    :size="size"
    :border-radius="borderRadius"
    :class="computedClassName"
  >
    <span data-testid="character">{{ char }}</span>
  </InitialAvatarFoundation>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import InitialAvatarFoundation from '@/foundation/initial-avatar/InitialAvatarFoundation.vue';
import {
  InitialAvatarSizes,
  InitialAvatarBorderRadius
} from '@/foundation/types';

@Component({
  name: 'InitialAvatarChar',
  components: { InitialAvatarFoundation }
})
export default class InitialAvatarChar extends Vue {
  /**
   * O caractere a ser exibido
   */
  @Prop({ type: String })
  readonly char!: string;

  /**
   * Define o tamanho do InitialAvatar
   * @values extra-small, small, medium, large
   */
  @Prop({ type: String, default: InitialAvatarSizes.EXTRA_SMALL })
  readonly size!: string;

  /**
   * Define o border radius do InitialAvatar
   * @values none, extra-small, small, medium, large, extra-large, max
   */
  @Prop({ type: String, default: InitialAvatarBorderRadius.MAX })
  readonly borderRadius!: string;

  private get computedClassName() {
    const classes = ['initial-avatar-char'];
    if (
      this.size === InitialAvatarSizes.MEDIUM ||
      this.size === InitialAvatarSizes.LARGE
    )
      classes.push('initial-avatar-char--typography');
    return classes;
  }
}
</script>

<style lang="less" scoped>
.initial-avatar-char {
  .text-p-5();
  color: @element-secondary;
  background-color: @background-tertiary;

  &--typography {
    .text-p-3();
  }
}
</style>
