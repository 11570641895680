<template>
  <div class="table-container" :class="{ 'is-child': isChild }">
    <table class="table">
      <thead class="table__header">
        <slot name="header" />
      </thead>
      <tbody class="table__body">
        <slot name="body" />
      </tbody>
    </table>
    <!-- @slot Conteúdo fora e abaixo da tabela -->
    <slot name="after" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Table'
})
export default class Table extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  private isChild!: boolean;
}
</script>

<style lang="less" scoped>
.table-wrapper {
  width: 100%;
}

.table-container {
  overflow-x: auto;
  .table {
    width: 100%;
    border-collapse: collapse;
    border-color: transparent;
    border-spacing: 0;
    text-align: left;
    th,
    td {
      padding: 0;
      border: none;
    }
    .table-container {
      &.is-child {
        border-radius: @size-radius-x200;
        background-color: @background-primary;
        padding: @size-spacing-x400 @size-spacing-x900;
      }
    }
  }
}
</style>
