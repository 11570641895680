<template>
  <div :class="computedClasses">
    <div v-if="hasIcon" class="helper-view--icon-container">
      <slot name="icon">
        <!--
          @slot Slot para receber ícones
          @deprecated Use a prop `icon`
        -->
        <div class="helper-view--icon">
          <BaseIcon
            v-if="icon"
            width="32"
            height="32"
            :colors="computedIconColors"
            :icon="icon"
          />
        </div>
      </slot>
    </div>
    <div class="helper-view--title">
      <Heading as="p" :level="3">{{ title }}</Heading>
    </div>
    <div class="helper-view--description">
      <BaseText size="md" :color="descriptionColor">
        {{ description }}
      </BaseText>
    </div>
    <div v-if="buttonLabel" class="helper-view--button">
      <ButtonPrimary :label="buttonLabel" @click="helperViewButtonEvent" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import { Heading } from '@/components/heading';
import { BaseText } from '@/components/base-text';
import { ButtonPrimary } from '@/components/button';

enum MessageType {
  INFO = 'info',
  ALERT = 'alert',
  POSITIVE = 'positive',
  NEGATIVE = 'negative'
}

/**
 * @deprecated Use apenas o HelperViewStatus ou HelperViewContextual.
 */
@Component({
  name: 'HelperView',
  components: {
    Heading,
    BaseIcon,
    BaseText,
    ButtonPrimary
  }
})
export default class HelperView extends Vue {
  /**
   * Tipo do aviso a ser exibido
   * @values info, alert, positive, negative
   */
  @Prop({ type: String, required: false })
  readonly type?: MessageType;

  /**
   * O ícone a ser exibido
   */
  @Prop({ type: String, required: false })
  readonly icon?: string;

  /**
   * As cores do ícone
   * @deprecated Use a propriedade type
   */
  @Prop({
    type: [Array, String],
    required: false
  })
  readonly iconColors?: string | string[];

  /**
   * O texto do título
   */
  @Prop({ type: String, required: true })
  readonly title!: string;

  /**
   * O texto de apoio
   */
  @Prop({ type: String, required: true })
  readonly description!: string;

  /**
   * Label do botão
   */
  @Prop({ type: String })
  readonly buttonLabel?: string;

  private descriptionColor = NebraskaColors.textSecondary;

  /**
   * Evento de click do botão, quando existente
   */
  @Emit('click')
  private helperViewButtonEvent() {
    // Função que emite evento
  }

  private get hasIcon() {
    return typeof this.icon !== 'undefined' || !!this.$slots.icon;
  }

  private get computedIconColors() {
    return this.iconColors ? this.iconColors : 'currentColor';
  }

  private get computedClasses() {
    const classes = ['helper-view'];

    if (this.iconColors) classes.push('custom');
    if (this.type) classes.push(this.type);

    return classes;
  }
}
</script>

<style lang="less" scoped>
.helper-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &--title,
  &--description {
    max-width: 368px;
  }
}

.helper-view--icon {
  &-container {
    margin-bottom: @size-spacing-x400;
  }
  .helper-view:not(.custom) & {
    border-radius: @size-radius-max;
    padding: @size-spacing-x400;
  }
  .helper-view.info & {
    background: @status-info-background;
    color: @status-info;
  }
  .helper-view.alert & {
    background: @status-alert-background;
    color: @status-alert;
  }
  .helper-view.positive & {
    background: @status-positive-background;
    color: @status-positive;
  }
  .helper-view.negative & {
    background: @status-negative-background;
    color: @status-negative;
  }
}

.helper-view--description {
  margin-top: @size-spacing-x300;
}

.helper-view--button {
  margin-top: @size-spacing-x400;
}
</style>
