<template>
  <div
    class="row-item"
    :class="{ 'row-item__interaction': hasInteraction }"
    v-on="$listeners"
  >
    <div :class="{ 'row-divider-top': hasLine }">
      <div class="icon">
        <slot name="icon-left">
          <BaseIcon v-if="icon" :icon="icon" colors="currentColor" />
        </slot>
      </div>
      <div class="wrapper">
        <div
          v-if="hasLeftContainer"
          class="container-left"
          data-testid="left-content"
        >
          <div class="text-p-3">
            <template v-if="leftTitle">
              {{ leftTitle }}
            </template>
            <slot v-else name="top-left" />
          </div>
          <div class="text-p-5 subtitle">
            <template v-if="leftSubtitle">
              {{ leftSubtitle }}
            </template>
            <slot v-else name="bottom-left" />
          </div>
        </div>
        <div
          v-if="hasCenterContainer"
          class="container-center"
          data-testid="center-content"
        >
          <slot name="center" />
        </div>
        <div
          v-if="hasRightContainer"
          class="container-right"
          data-testid="right-content"
        >
          <div class="text-p-3">
            <template v-if="rightTitle">
              {{ rightTitle }}
            </template>
            <slot v-else name="top-right" />
          </div>
          <div class="text-p-5 subtitle">
            <template v-if="rightSubtitle">
              {{ rightSubtitle }}
            </template>
            <slot v-else name="bottom-right" />
          </div>
        </div>
        <div class="icon">
          <slot name="icon-right">
            <BaseIcon
              v-if="chevron"
              icon="ic_chevron"
              class="chevron"
              :colors="NebraskaColors.elementSecondary"
              @click="$emit('chevron-icon-clicked')"
            />
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';

@Component({
  name: 'Row',
  components: { BaseIcon }
})
export default class Row extends Vue {
  private NebraskaColors = NebraskaColors;

  /**
   * Name of the icon to show on the "icon-left" slot (BaseIcon).
   */
  @Prop({ type: String, required: false })
  private icon?: string;

  /**
   * If true, will show the chevron (arrow on the right)
   */
  @Prop({ type: Boolean, default: false })
  private chevron!: boolean;

  /**
   * If true, will indicate visually that the row can be clicked
   */
  @Prop({ type: Boolean, default: false })
  private hasInteraction!: boolean;

  /**
   * If false, will not show the divider line (default is true!)
   */
  @Prop({ type: Boolean, default: true })
  private hasLine!: boolean;

  /**
   * The top-left content. If defined, the "top-left" slot content will not be displayed.
   */
  @Prop({ type: String, required: false })
  private leftTitle?: string;

  /**
   * The bottom-left content. If defined, the "bottom-left" slot content will not be displayed.
   */
  @Prop({ type: String, required: false })
  private leftSubtitle?: string;

  /**
   * The top-right content. If defined, the "top-right" slot content will not be displayed.
   */
  @Prop({ type: String, required: false })
  private rightTitle?: string;

  /**
   * The bottom-right content. If defined, the "bottom-right" slot content will not be displayed.
   */
  @Prop({ type: String, required: false })
  private rightSubtitle?: string;

  private get hasCenterContainer(): boolean {
    return !!this.$slots.center;
  }

  private get hasLeftContainer(): boolean {
    return Boolean(
      this.leftTitle ||
        this.leftSubtitle ||
        this.$slots['top-left'] ||
        this.$slots['bottom-left']
    );
  }

  private get hasRightContainer(): boolean {
    return Boolean(
      this.rightTitle ||
        this.rightSubtitle ||
        this.$slots['top-right'] ||
        this.$slots['bottom-right']
    );
  }
}
</script>

<style lang="less" scoped>
.row-item {
  border-radius: @size-spacing-x300;
  color: @text-primary;
  transition: 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  background: @background-secondary;
  margin: 0px calc(-1 * @size-spacing-x300);
  padding: 0px @size-spacing-x300;
  > div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: @size-spacing-x350 0px;
  }

  &.row-item__interaction {
    &:hover {
      background: @background-hover;
      cursor: pointer;
    }
    &:active {
      opacity: 0.2;
    }
  }

  .subtitle {
    color: @text-secondary;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-content: center;
  .container-left,
  .container-right {
    flex: 1;
  }

  .container-left {
    text-align: left;
  }

  .container-right {
    text-align: right;
  }
}

.row-divider-top {
  border-top: @size-border-x400 solid @divider-primary;
}

.icon {
  margin-right: @size-spacing-x350;

  &:last-of-type {
    margin: @size-spacing-x75 0px 0px @size-spacing-x300;
  }
  &:empty {
    margin: 0 !important;
  }

  .chevron {
    margin-right: calc(-1 * @size-spacing-x150);
    margin-left: calc(-1 * @size-spacing-x150);
  }
}
</style>
