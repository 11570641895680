<template>
  <Button
    :class="`button-box-launch ${computedClassBlur}`"
    data-testid="button-box-launch"
    :colors="buttonBoxColors"
    :href="href"
    :disabled="disabled"
    :loading="loading"
    v-on="$listeners"
  >
    <span class="content">
      <span class="section">
        <BaseIcon :icon="icon" :class="`icon icon--${computedClass}`" />
        <span
          v-if="badgeLabel"
          :class="`badge-label badge-label--${computedClass}`"
        >
          {{ badgeLabel }}
        </span>
      </span>
      <span :class="`label label--${computedClass}`">{{ label }}</span>
    </span>
  </Button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/foundation/button/Button.vue';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import { buttonBoxColors, buttonBoxBlurColors } from '@/styles/Button';

@Component({
  name: 'ButtonBoxLaunch',
  components: { Button, BaseIcon }
})
export default class ButtonBoxLaunch extends Vue {
  /**
   *  Desativa o botão
   */
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  /**
   * Coloca o botão no estado "carregando"
   */
  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean;

  /**
   * O ícone a ser exibido
   */
  @Prop({ type: String, required: true })
  readonly icon!: string;

  /**
   * O título do botão
   */
  @Prop({ type: String, required: true })
  readonly label!: string;

  /**
   * Permite atribuir um link ao botão
   */
  @Prop({ type: String })
  readonly href?: string;

  /**
   * A badge com texto adicional
   */
  @Prop({ type: String })
  readonly badgeLabel?: string;

  /**
   *  Coloca o botão com estilo do tipo blur
   */
  @Prop({ type: Boolean, default: false })
  readonly isBlur!: boolean;

  readonly buttonBoxColors = this.isBlur
    ? buttonBoxBlurColors
    : buttonBoxColors;

  private get computedClass(): string {
    return this.disabled ? 'disabled' : '';
  }

  private get computedClassBlur(): string {
    return this.isBlur ? `blur blur--${this.computedClass}` : '';
  }
}
</script>

<style lang="less" scoped>
.button-box-launch {
  background: @base-primary;
  width: 132px;
  height: 132px;
  padding: @size-spacing-x400;
  border-radius: @size-radius-x300;
  display: inline-block;
  border: @size-border-x400 solid @divider-primary;

  &.blur {
    backdrop-filter: blur(5px) saturate(1.4) brightness(1.01);
    background: rgba(255, 255, 255, 0.1);
    border: 0;

    &:hover {
      transition: all ease 0.5s;
      transform: none;
    }

    &--disabled {
      opacity: 0.5;
    }

    .label,
    .icon {
      color: @text-over-image;
    }
  }

  ::v-deep .button--label {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
}

.icon {
  color: @element-primary;
  &--disabled {
    color: @base-disabled;
  }
}

.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.badge-label {
  .text-p-6();
  padding: 0 @size-spacing-x300;
  border-radius: @size-radius-x100;
  color: @element-primary;
  background-color: @theme-secondary;

  &--disabled {
    color: @base-over-disabled;
    background-color: @base-disabled;
  }
}

.label {
  .text-p-5();

  font-weight: 600;
  color: @text-primary;
  &--disabled {
    color: @base-disabled;
  }
}
</style>
