<template>
  <component :is="as" class="brand-image_container">
    <svgicon
      :icon="image"
      :width="width"
      :title="title"
      :class="`brand-image_${image}`"
      :data-testid="`brand-image-${image}`"
      :color="computedColor"
    />
  </component>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { EBrandImageSchemas, EBrandImageLogos } from './types';
import svgicon from 'vue-svgicon';

@Component({
  name: 'BrandImage',
  components: {
    svgicon
  }
})
export default class BrandImage extends Vue {
  /**
   *  Define a logo a ser exibida
   *  Utilizar o enum EBrandImageLogos
   */
  @Prop({ type: String, required: true })
  private image!: EBrandImageLogos;

  /**
   *  Define a cor da logo quando é secundária ou terciária
   *  Utilizar o enum EBrandImageSchemas
   */
  @Prop({ type: String, default: EBrandImageSchemas.DEFAULT })
  private schema!: EBrandImageSchemas;

  /**
   *  Fixa a cor do schema independentemente se o dark mode está ativo ou não.
   *  Caso essa prop seja true e o schema seja positivo, a cor branca sera fixa e vice-versa
   */
  @Prop({ type: Boolean, default: false })
  readonly fixedSchemaColor?: boolean;

  /**
   * Define uma descrição acessível para a logo
   */
  @Prop({ type: String, required: false })
  public title?: string;

  /**
   *  Define a largura do svg (a altura segue a proporção)
   */
  @Prop({ type: String, default: '300' })
  public width!: string;

  /**
   *  Define o componente "wrapper" do svg
   */
  @Prop({ type: String, default: 'span' })
  private as!: string;

  async created() {
    try {
      await import(
        /* webpackChunkName: "nebraska-icons" */
        /* webpackPrefetch: true */
        '@/brand-logos/vue-icons'
      );
    } catch (err) {
      console.error(err);
    }
  }

  get computedColor() {
    let color;
    switch (this.schema) {
      case EBrandImageSchemas.POSITIVE:
        color = this.fixedSchemaColor
          ? NebraskaColors.cinza0
          : NebraskaColors.elementPrimary;
        break;
      case EBrandImageSchemas.NEGATIVE:
        color = this.fixedSchemaColor
          ? NebraskaColors.carvao500
          : NebraskaColors.elementNegative;
        break;
      default:
        color = NebraskaColors.terracota500;
    }
    return color;
  }
}
</script>

<style lang="less" scoped>
.brand-image {
  &_container {
    display: block;
  }
}
</style>
