<template>
  <BaseModal ref="modal" :is-open="isOpen" :size="size" v-on="$listeners">
    <template #header>
      <NavBar class="navbar" background="none">
        <template v-if="showBackButton" #left>
          <ButtonIconContextual
            icon="EA0030"
            label="Voltar"
            size="small"
            class="modal-back-button"
            @click="backClick"
          />
        </template>
        <template v-if="title" #center>
          <p class="title" data-testid="modalTitle">
            {{ title }}
          </p>
        </template>
        <template #right>
          <ButtonIconContextual
            icon="EF0030"
            label="Fechar"
            size="small"
            class="modal-close-button"
            @click="closeModal"
          />
        </template>
      </NavBar>
    </template>
    <template #content>
      <div class="modal-content">
        <!-- @slot Conteúdo da modal -->
        <slot />
      </div>
    </template>
    <template #footer>
      <div v-if="$slots.buttons" class="modal-buttons" data-e2e="modalButtons">
        <!-- @slot Espaço para adição de botões; @deprecated, utilize apenas o slot buttonContainer -->
        <slot name="buttons" />
      </div>
      <div v-else-if="$slots.buttonContainer" data-e2e="modalButtons">
        <!-- @slot Espaço para adição do componente ButtonContainer -->
        <slot name="buttonContainer" />
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Ref } from 'vue-property-decorator';

import BaseModal from '@/foundation/base-modal/BaseModal.vue';
import { BaseModalPublicRef, ModalSizes } from '@/foundation/base-modal/types';
import NavBar from '@/components/navbar/NavBar.vue';
import ButtonIconContextual from '@/components/button-icon/button-icon-contextual/ButtonIconContextual.vue';

@Component({
  name: 'Modal',
  components: {
    BaseModal,
    ButtonIconContextual,
    NavBar
  }
})
export default class Modal extends Vue {
  @Prop({ type: String })
  readonly title?: string;

  /**
   * Indica se a modal está aberta e deve ser renderizada
   */
  @Prop({ type: Boolean, default: false })
  readonly isOpen!: boolean;

  /**
   * Indica se o botão de voltar deve ser exibido
   */
  @Prop({ type: Boolean })
  readonly showBackButton?: boolean;

  /**
   * Define o tamanho da janela do conteúdo
   */
  @Prop({ type: String, default: ModalSizes.SMALL })
  readonly size!: ModalSizes;

  @Ref('modal')
  private baseModal!: BaseModalPublicRef;

  /**
   * Evento disparado a cada ação relacionada ao fechamento do modal
   */
  @Emit('close')
  private closeModal() {
    // Função que emite evento
  }

  /**
   * Evento disparado quando o botão de voltar é clicado
   */
  @Emit('back-click')
  private backClick() {
    // Função que emite evento
  }

  scrollContentTo(options: ScrollToOptions) {
    this.baseModal.scrollContentTo(options);
  }
}
</script>

<style lang="less" scoped>
.navbar {
  background: @background-secondary;
  border-bottom: none;
}

.title {
  .text-p-5();
  font-weight: bold;
  color: @text-primary;
}

.modal-content {
  .text-p-3();
  color: @text-primary;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: auto;
  padding: @size-spacing-x500 @size-spacing-x600;
  text-align: center;
}

.modal-buttons {
  background: @background-primary;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 64px;
  padding: @size-spacing-x350 @size-spacing-x500;

  @media screen and (max-width: 640px) {
    flex-direction: column-reverse;
    padding: 0;
  }

  > * + * {
    margin-left: @size-spacing-x350;

    @media screen and (max-width: 640px) {
      margin: 0 !important;
    }
  }
}
</style>
