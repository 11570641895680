<template>
  <Message
    role="alert"
    aria-live="assertive"
    class="status-message"
    :title="title"
    :loader="loader"
  >
    <template #icon>
      <div
        :class="['status-message, status-message--icon', computedClassName]"
        aria-hidden="true"
      >
        <BaseIcon :icon="icon" />
      </div>
    </template>
    <template #description>
      <slot />
    </template>
  </Message>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Message from '@/foundation/message/Message.vue';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';

@Component({
  name: 'StatusMessage',
  components: { BaseIcon, Message }
})
export default class StatusMessage extends Vue {
  /**
   * O título a ser exibido
   */
  @Prop({ type: String, required: false })
  readonly title?: string;

  /**
   * O ícone a ser exibido
   */
  @Prop({ type: String, required: true })
  readonly icon!: string;

  /**
   * Coloca o StatusMessage no estado "carregando"
   */
  @Prop({ type: Boolean, default: false })
  readonly loader!: boolean;

  /**
   *  Define a cor do ícone conforme o tipo da mensagem
   *  @values alert, info, negative, success
   */
  @Prop({ type: String, required: true, default: 'info' })
  private type!: 'alert' | 'info' | 'negative' | 'success';

  private get computedClassName() {
    const baseClassName = 'status-message';
    return [`${baseClassName}--${this.type}`];
  }
}
</script>

<style lang="less" scoped>
.status-message {
  &--icon {
    padding: @size-spacing-x350;
    border-radius: @size-radius-max;
    color: @status-info;
    background-color: @status-info-background;
  }

  &--alert {
    color: @status-alert;
    background-color: @status-alert-background;
  }

  &--negative {
    color: @status-negative;
    background-color: @status-negative-background;
  }

  &--success {
    color: @status-positive;
    background-color: @status-positive-background;
  }
}
</style>
