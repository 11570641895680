<template>
  <div class="mfa-content">
    <Heading
      class="mfa-content__title"
      :level="2"
      data-testid="mfa-title-default"
    >
      Enviamos um código de verificação para seu dispositivo
    </Heading>
    <BaseText
      :color="NebraskaColors.textSecondary"
      data-testid="mfa-description-default"
      size="md"
    >
      Caso não tenha recebido o código, não tenha seu aplicativo Warren
      instalado ou esteja sem celular use a opção abaixo.
    </BaseText>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { BaseText } from '@/components/base-text';
import { Heading } from '@/components/heading';

@Component({
  name: 'MFAContentDefault',
  components: { BaseText, Heading }
})
export default class MFAContentDefault extends Vue {
  readonly NebraskaColors = NebraskaColors;
}
</script>

<style lang="less" scoped>
.mfa-content {
  max-width: 592px;
  text-align: center;
  &__title {
    margin-bottom: @size-spacing-x400;
  }
}
</style>
