<template>
  <component :is="as" :style="computedStyle" :class="size" class="base-text">
    <slot />
  </component>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  name: 'BaseText'
})
export default class BaseText extends Vue {
  @Prop({ type: String, default: 'lg' })
  private size!: string;

  @Prop({ type: String, default: NebraskaColors.elementSecondary })
  private color!: NebraskaColors;

  @Prop({ type: String, default: 'p' })
  private as!: string;

  private get computedStyle() {
    return `color: ${this.color};`;
  }
}
</script>

<style lang="less" scoped>
.base-text {
  margin: 0;
}

.xs,
.p6 {
  .text-p-6();
}

.sm,
.p5 {
  .text-p-5();
}

.md,
.p4 {
  .text-p-4();
}

.lg,
.p3 {
  .text-p-3();
}

.xl,
.p2 {
  .text-p-2();
}

.xxl,
.p1 {
  .text-p-1();
}
</style>
