<template>
  <BaseCell class="icon-cell" align="right" v-bind="$attrs">
    <component :is="is" class="button" :aria-label="ariaLabel">
      <BaseIcon :colors="computedColor" :icon="icon" />
    </component>
  </BaseCell>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

import BaseCell from './BaseCell.vue';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';

@Component({
  components: { BaseCell, BaseIcon }
})
export default class IconCell extends Vue {
  /**
   * A cor do ícone a ser exibido
   */
  @Prop({
    type: String,
    required: false,
    default: NebraskaColors.elementSecondary
  })
  readonly color!: NebraskaColors;

  /**
   * O nome do ícone a ser exibido
   */
  @Prop({ type: String, required: false, default: 'EA0170' })
  readonly icon!: string;

  /**
   * O rótulo do botão
   * A label não é exibida, mas é adicionada por questão de acessibilidade
   */
  @Prop({ type: String, required: true })
  readonly ariaLabel!: string;

  @Inject({ from: 'disabled', default: false })
  private readonly disabled!: boolean;

  get is() {
    return 'button';
  }

  get computedColor() {
    return this.disabled ? NebraskaColors.elementDisabled : this.color;
  }
}
</script>

<style lang="less" scoped>
.button {
  all: unset;

  &:focus {
    box-shadow: 0px 0px 0px 2px @element-primary,
      0px 0px 1px 0 inset @element-primary;
    outline: none;
  }
}
</style>
