<template>
  <div class="overlay" v-on="$listeners" />
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'Overlay'
})
export default class Overlay extends Vue {}
</script>

<style lang="less" scoped>
.overlay {
  background-color: @background-overlay;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>
