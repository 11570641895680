<template>
  <BaseIcon
    icon="EE0170"
    :colors="NebraskaColors.elementSecondary"
    :width="size"
    :height="size"
    spin
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  name: 'Loader',
  components: {
    BaseIcon
  }
})
export default class Loader extends Vue {
  /**
   * Tamanho do loader setado como String. Min: 24 | Max: 56
   */
  @Prop({
    type: String,
    default: '56',
    validator: (value: string) => Number(value) >= 24 && Number(value) <= 56
  })
  readonly size!: string;

  readonly NebraskaColors = NebraskaColors;
}
</script>
