<template>
  <button
    :disabled="disabled"
    :class="{ 'simple-button--disabled': disabled }"
    data-testid="simple-button-action"
    class="simple-button"
    type="button"
    v-on="$listeners"
  >
    <!-- @slot Espaço padrão para adicionar rótulo -->
    <slot />
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
/**
 * @deprecated
 */
@Component({
  name: 'ModalButton'
})
export default class ModalButton extends Vue {
  /** Para desativar o botão */
  @Prop({ type: Boolean, default: false })
  public disabled!: boolean;
}
</script>

<style lang="less" scoped>
.simple-button {
  background: transparent;
  border: 0;
  color: @text-primary;
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
  padding: @size-spacing-x350;
  text-align: center;
  transition: 0.2s cubic-bezier(0.55, 0, 0.1, 1);
  width: 100%;

  &:active {
    transform: translateY(4px);
  }

  @media screen and (max-width: 640px) {
    display: block;
  }
}

.simple-button--disabled {
  color: @text-disabled !important;
  cursor: not-allowed;
}
</style>
