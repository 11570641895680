<template>
  <span class="caption-foundation">
    <span
      class="caption-foundation--trace"
      :style="{ backgroundColor: color }"
    />
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  name: 'CaptionFoundation'
})
export default class CaptionFoundation extends Vue {
  readonly NebraskaColors = NebraskaColors;

  /**
   * A cor do Caption a ser exibido
   */
  @Prop({
    type: String,
    default: NebraskaColors.elementPrimary
  })
  readonly color?: NebraskaColors;
}
</script>

<style lang="less" scoped>
.caption-foundation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 16px;

  &--trace {
    border-radius: @size-radius-max;
    width: 4px;
    height: 12px;
  }
}
</style>
