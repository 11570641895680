<template>
  <ul class="list">
    <li v-for="option in options" :key="option.value">
      <FilterOption
        v-model="selectedValues"
        :value="option.value"
        :label="option.label"
        :disabled="option.disabled"
        :type="computedInputType"
        :icon="option.icon"
      />
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  IFilterOption,
  IFilterOptionValue,
  FilterOptionType
} from '../filter-option/types';

import FilterOption from '../filter-option/FilterOption.vue';

@Component({
  name: 'FilterList',
  components: { FilterOption },
  model: {
    prop: 'checked',
    event: 'change'
  }
})
export default class FilterList extends Vue {
  /**
   * Recebe uma string ou um array com os valores selecionados
   */
  @Prop({ type: [Array, String, Boolean], required: false })
  readonly checked!: IFilterOptionValue;

  /**
   * Habilita a seleção de múltiplas opções
   */
  @Prop({ type: Boolean, default: false })
  readonly multiple!: boolean;

  /**
   * Array com todas as opções da lista
   */
  @Prop({ type: Array })
  readonly options!: IFilterOption[];

  /**
   * Evento emitido a cada click/mudança de estado
   */
  @Emit('change')
  public change(values: IFilterOptionValue) {
    return values;
  }

  private set selectedValues(values: IFilterOptionValue) {
    this.change(values);
  }

  private get selectedValues(): IFilterOptionValue {
    return this.checked;
  }

  private get computedInputType(): FilterOptionType {
    return this.multiple ? FilterOptionType.CHECKBOX : FilterOptionType.RADIO;
  }
}
</script>

<style lang="less" scoped>
.list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  li + li {
    margin-left: @size-spacing-x300;
  }
}
</style>
