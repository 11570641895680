<template>
  <button :aria-label="ariaLabel" type="button" v-on="$listeners">
    <BaseIcon
      :style="computedColors"
      :colors="iconColors"
      :icon="iconName"
      :width="iconSize"
      :height="iconSize"
      aria-hidden="true"
    />
  </button>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
/**
 * @deprecated
 */
@Component({
  name: 'IconButton',
  components: {
    BaseIcon
  }
})
export default class IconButton extends Vue {
  @Prop({ type: String, default: NebraskaColors.elementPrimary })
  private iconColors!: string;

  @Prop({ type: String }) public ariaLabel!: string;

  @Prop({ type: String }) public iconSize?: string;

  @Prop({ type: String }) private iconName!: string;

  private NebraskaColors = NebraskaColors;

  private get computedColors() {
    return {
      color: this.computedTextColor
    };
  }

  private get computedTextColor(): string {
    const color = this.iconColors;

    return color;
  }
}
</script>

<style lang="less" scoped>
button {
  background: none;
  border: none;
  cursor: pointer;
}
</style>
