<template>
  <Button
    :class="computedClassName"
    :tabindex="tabindex"
    :href="href"
    :disabled="disabled"
    :loading="loading"
    :aria-label="label"
    :colors="colors"
    :icon-size="size"
    data-testid="button-icon"
    v-on="$listeners"
  >
    <span class="content">
      <BaseIcon
        v-if="icon"
        class="icon"
        colors="currentColor"
        :icon="icon"
        :width="computedIconSize"
      />
      <span v-else class="label" data-testid="character">{{ char }}</span>
    </span>
  </Button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import Button from '@/foundation/button/Button.vue';
import { ButtonSizes } from '@/foundation/types';
import { ButtonColors } from '../models/Button';

@Component({
  name: 'ButtonIcon',
  components: { BaseIcon, Button }
})
export default class ButtonIcon extends Vue {
  /**
   * O rótulo do botão
   * A label não é exibida, mas é adicionada por questão de acessibilidade
   */
  @Prop({ type: String, required: true })
  readonly label!: string;

  /**
   * Permite atribuir um link ao botão
   */
  @Prop({ type: String, required: false })
  readonly href?: string;

  /**
   * Desativa o botão
   */
  @Prop({ type: Boolean, required: false })
  readonly disabled?: boolean;

  /**
   * Coloca o botão no estado "carregando"
   */
  @Prop({ type: Boolean, required: false })
  readonly loading?: boolean;

  /**
   * Define qual a posição do elemento durante a navegação pelo teclado. Na maioria dos casos, não é necessário alterar o valor padrão.
   * @see {@link https://developer.mozilla.org/pt-BR/docs/Web/HTML/Global_attributes/tabindex}
   */
  @Prop({ type: Number, required: false })
  readonly tabindex?: number;

  /**
   * Define as cores do botão
   */
  @Prop({
    type: Object,
    required: false
  })
  readonly colors?: ButtonColors;

  /**
   * Define o tamanho do botão
   * @values extra-small, small, regular, large
   */
  @Prop({
    type: String,
    default: ButtonSizes.REGULAR
  })
  readonly size!: string;

  /**
   * O ícone a ser exibido.
   * O `icon` tem precedência ao `char`
   */
  @Prop({
    type: String,
    required: false
  })
  readonly icon?: string;

  /**
   * O caractere a ser exibido
   */
  @Prop({
    type: String,
    required: false
  })
  readonly char?: string;

  private get computedClassName() {
    return ['button-icon', this.size];
  }

  private get computedIconSize() {
    return this.size === ButtonSizes.EXTRA_SMALL ? '16' : '24';
  }
}
</script>

<style lang="less" scoped>
.button-icon {
  width: 48px;
  height: 48px;
  border-radius: @size-radius-max;
  .text-p-4();

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.extra-small {
    width: 24px;
    height: 24px;
    .text-p-6();
  }

  &.small {
    width: 32px;
    height: 32px;
    .text-p-5();
  }

  &.extra-small,
  &.small {
    font-weight: bold;
  }

  &.large {
    width: 64px;
    height: 64px;
  }
}
</style>
