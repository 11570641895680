<template>
  <nav class="pagination-wrapper" :aria-label="ariaLabelTitle">
    <ul class="pagination">
      <li
        :class="[
          'pagination__item',
          'pagination__control',
          { 'pagination--disabled': isFirstPageSelected }
        ]"
      >
        <ButtonIconContextual
          label="Ir para a página anterior"
          :disabled="isFirstPageSelected"
          :tabindex="isFirstPageSelected ? -1 : 0"
          icon="EA0160"
          :size="ButtonSizes.EXTRA_SMALL"
          @click="prevPage"
        />
      </li>

      <li
        v-for="(page, index) in pages"
        :key="index"
        :class="[
          'pagination__item',
          {
            'pagination--active': page.selected,
            'pagination--disabled': page.disabled
          }
        ]"
      >
        <BaseText v-if="page.breakView" size="md" as="span"> … </BaseText>
        <ButtonIconContextual
          v-else
          class="pagination--button-icon-contextual"
          :aria-current="!!page.selected"
          :label="pageAriaLabel(page)"
          :tabindex="0"
          data-testid="pagination-item"
          :size="ButtonSizes.SMALL"
          :char="String(page.content)"
          @click="handlePageSelected(page.index + 1)"
        />
      </li>

      <li
        :class="[
          'pagination__item',
          'pagination__control',
          { 'pagination--disabled': isLastPageSelected }
        ]"
      >
        <ButtonIconContextual
          :disabled="isLastPageSelected"
          label="Ir para a próxima página"
          :tabindex="isLastPageSelected ? -1 : 0"
          icon="EA0170"
          :size="ButtonSizes.EXTRA_SMALL"
          @click.prevent="nextPage"
        />
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { Vue, Component, Prop, Model, Watch } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import BaseText from '@/components/base-text/BaseText.vue';
import { ButtonSizes } from '@/foundation/types';
import { Page } from './types';
import ButtonIconContextual from '@/components/button-icon/button-icon-contextual/ButtonIconContextual.vue';

@Component({
  name: 'Pagination',
  components: { BaseIcon, BaseText, ButtonIconContextual }
})
export default class Pagination extends Vue {
  /**
   * Valor da página atual
   */
  @Model('page-changed', { type: Number })
  readonly currentPage?: number;

  /**
   * Define o total de páginas disponíveis
   */
  @Prop({ type: Number, default: 1 })
  readonly totalPages!: number;

  /**
   * Define a página inicial
   * @deprecated Utilizar a prop `currentPage` no lugar
   */
  @Prop({ type: Number })
  readonly initialPage?: number;

  /**
   * Define o total de páginas disponíveis
   */
  @Prop({ type: String, default: 'Navegação de páginas' })
  readonly ariaLabelTitle!: string;

  readonly ButtonSizes = ButtonSizes;
  private innerValue = 1;
  private marginPages = 1;
  private pageRange = 3;

  get selected() {
    return this.currentPage || this.innerValue;
  }

  set selected(value: number) {
    this.innerValue = value;
  }

  get isFirstPageSelected() {
    return this.selected === 1;
  }

  get isLastPageSelected() {
    return this.selected === this.totalPages || this.totalPages === 0;
  }

  private setPageItem(index: number, items: Record<number, Page>) {
    const page = {
      index,
      content: index + 1,
      selected: index === this.selected - 1
    };
    items[index] = page;
  }

  private setBreakView(index: number, items: Record<number, Page>) {
    const breakView = {
      index: 0,
      disabled: true,
      breakView: true
    };
    items[index] = breakView;
  }

  private setLoopThruSelectedRange(items: Record<number, Page>) {
    const halfPageRange = Math.floor(this.pageRange / 2);
    let selectedRangeLow = 0;
    if (this.selected - halfPageRange > 0) {
      selectedRangeLow = this.selected - 1 - halfPageRange;
    }
    let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
    if (selectedRangeHigh >= this.totalPages) {
      selectedRangeHigh = this.totalPages - 1;
      selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
    }
    for (
      let i = selectedRangeLow;
      i <= selectedRangeHigh && i <= this.totalPages - 1;
      i++
    ) {
      this.setPageItem(i, items);
    }
    // Check if there is breakView in the left of selected range
    if (selectedRangeLow > this.marginPages) {
      this.setBreakView(selectedRangeLow - 1, items);
    }
    // Check if there is breakView in the right of selected range
    if (selectedRangeHigh + 1 < this.totalPages - this.marginPages) {
      this.setBreakView(selectedRangeHigh + 1, items);
    }
  }

  setLoopThruHighEndOfMarginPages(items: Record<number, Page>) {
    for (
      let i = this.totalPages - 1;
      i >= this.totalPages - this.marginPages;
      i--
    ) {
      this.setPageItem(i, items);
    }
  }

  setLoopThruLowEndOfMarginPages(items: Record<number, Page>) {
    for (let i = 0; i < this.marginPages; i++) this.setPageItem(i, items);
  }

  setDefaultLoop(items: Record<number, Page>) {
    for (let index = 0; index < this.totalPages; index++) {
      const page = {
        index,
        content: index + 1,
        selected: index === this.selected - 1
      };
      items[index] = page;
    }
  }

  // TODO: Refatorar essa lógica para deixar mais simples de dar manutenção, escondendo os itens em vez de remover da lista
  get pages(): Page[] {
    const items: Record<number, Page> = {};
    if (this.totalPages <= this.pageRange) this.setDefaultLoop(items);
    else {
      this.setLoopThruLowEndOfMarginPages(items);
      this.setLoopThruSelectedRange(items);
      this.setLoopThruHighEndOfMarginPages(items);
    }
    return Object.values(items);
  }

  @Watch('initialPage', { immediate: true })
  private watchInitialPage(newValue?: number, oldValue?: number) {
    if (!newValue || newValue === oldValue) return;
    this.handlePageSelected(newValue);
  }

  pageAriaLabel(page: Page) {
    return page.selected
      ? `Página atual, página ${page.content}`
      : `Ir para a página ${page.content}`;
  }

  handlePageSelected(selected: number) {
    if (this.selected === selected) return;

    this.innerValue = selected;

    /**
     * Evento de troca de página selecionada
     * @property {number} selected página selecionada
     */
    this.$emit('page-changed', selected);
  }

  prevPage() {
    if (this.selected <= 1) return;

    this.handlePageSelected(this.selected - 1);
  }

  nextPage() {
    if (this.selected >= this.totalPages) return;

    this.handlePageSelected(this.selected + 1);
  }
}
</script>

<style lang="less" scoped>
.pagination {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  &-wrapper {
    display: flex;
    justify-content: center;
  }

  &__item {
    min-width: 22px;
    height: 32px;
    border-radius: @size-radius-max;
    margin-left: @size-spacing-x350;
    color: @element-secondary;
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep .base-text {
      color: inherit !important;
    }
  }

  &--button-icon-contextual {
    font-weight: 400 !important;
  }

  &__control {
    color: @text-secondary !important;

    &:first-of-type {
      margin-left: 0;
    }

    &:not(&--disabled) {
      color: @text-primary;
    }
  }

  &--active {
    color: @element-primary !important;
    background-color: @background-hover !important;
  }

  &--disabled {
    pointer-events: none;
    cursor: default;
    color: @base-over-disabled !important;
  }
}
</style>
