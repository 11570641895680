<template>
  <div class="filter-select">
    <FilterFoundation
      ref="filterRef"
      v-click-outside="handleClickOutside"
      tabindex="0"
      class="filter-select--button"
      :active="isActive"
      :disabled="disabled"
      :label="filterLabel"
      @click="toggleMenu"
      @keypress.enter="toggleMenu"
    >
      <template #left-slot>
        <div class="slot-icon">
          <CaptionFoundation
            v-if="computedShowCaption"
            class="caption-foundation-active"
            data-testid="caption-foundation"
            :color="computedShowCaption"
          />
          <BadgeCounter
            v-else-if="BadgeCounterValue && isMultiselect"
            size="small"
            :value="BadgeCounterValue"
            :active="true"
          />
          <BaseIcon
            v-else-if="leftIconName"
            colors="currentColor"
            width="20"
            height="20"
            :icon="leftIconName"
          />
        </div>
      </template>
      <template #right-slot>
        <div class="slot-icon">
          <BaseIcon
            colors="currentColor"
            width="20"
            height="20"
            :icon="rightIconName"
          />
        </div>
      </template>
    </FilterFoundation>
    <component :is="menuWrapperComponent">
      <Menu
        ref="menuRef"
        v-model="selectedValue"
        :is-open="isOpen"
        class="menu"
        :options="options"
        :label="label"
        :type="type"
        :button-label="buttonLabel"
        @click-button="appliedFilters"
        @close="handleClickOutside"
      />
    </component>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Ref } from 'vue-property-decorator';
import {
  computePosition,
  offset,
  autoPlacement,
  autoUpdate,
  Placement
} from '@floating-ui/dom';

import { Portal } from '@linusborg/vue-simple-portal';
import FilterFoundation from '@/foundation/filter/FilterFoundation.vue';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import Menu from '@/foundation/menu/Menu.vue';
import {
  IMenu,
  IMenuValue,
  MenuType,
  MenuValue
} from '@/foundation/menu/types';
import CaptionFoundation from '@/foundation/caption-foundation/CaptionFoundation.vue';
import BadgeCounter from '@/foundation/badge-counter/BadgeCounter.vue';

@Component({
  name: 'FilterSelect',
  components: {
    FilterFoundation,
    BaseIcon,
    Menu,
    CaptionFoundation,
    BadgeCounter,
    Portal
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  directives: {
    'click-outside': {
      bind: (el: HTMLElement, binding) => {
        const handler = (e: Event) => {
          if (!el.contains(e.target as Node) && el !== e.target) {
            binding.value(e);
          }
        };
        // add Event Listeners
        document.addEventListener('click', handler);
      },

      unbind: (el, binding) => {
        // Remove Event Listeners
        document.removeEventListener('click', (e: Event) => {
          if (!el.contains(e.target as Node) && el !== e.target) {
            binding.value(e);
          }
        });
      }
    }
  }
})
export default class FilterSelect extends Vue {
  /**
   * Texto exibido no Filter
   */
  @Prop({ type: String, required: true })
  readonly label!: string;

  /**
   * ìcone a esquerda do Filter
   */
  @Prop({ type: String, required: false })
  readonly leftIconName?: string;

  /**
   * Desativa o Filter
   */
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  /**
   * Indica o value do Filter
   */
  @Prop({ type: [String, Number, Array, Object], required: true })
  readonly value!: IMenuValue;

  /**
   * Tipo do Menu a ser exibido
   * @values single, multiple
   */
  @Prop({ type: String, default: MenuType.Single })
  readonly type!: MenuType;

  /**
   * O rótulo do Botão quando existe no Menu múltiplo
   */
  @Prop({ type: String })
  readonly buttonLabel?: string;

  /**
   * Prop usada quando deseja passar a label da opção selecionada como label do Filter
   */
  @Prop({ type: Boolean, default: false })
  readonly useOptionLabel!: boolean;

  /**
   * Lista de opções
   */
  @Prop({ type: Array, required: true })
  readonly options!: IMenu[];

  /**
   * Mostrar o Caption fixo, passando um token de cor independente da opção selecionada
   */
  @Prop({ type: String })
  readonly defaultCaptionColor!: string;

  /**
   * Renderiza o Menu do FilterSelect com Portal movendo ele para o body da página para ser usado em casos
   * que o FilterSelect esteja dentro de um elemento com overflow: hidden
   */
  @Prop({ type: Boolean, default: false })
  readonly renderMenuWithPortal!: boolean;

  /**
   * Posicionamentos permitidos para o cálculo de onde o Menu será posicionado usando o floating-ui.
   * Quer dizer que entre esses posicionamentos o floating-ui irá calcular quais deles tem o maior espaço para renderizar o Menu
   */
  @Prop({ type: Array, default: () => ['bottom-start', 'bottom-end'] })
  readonly menuAllowedPlacements!: Placement[];

  @Ref('filterRef')
  readonly filterRef!: Vue;

  @Ref('menuRef')
  readonly menuRef!: Vue;

  private isOpen = false;

  cleanupComputeMenuPosition: (() => void) | null = null;
  setAutoUpdateInterval: NodeJS.Timer | null = null;

  /**
   * Evento que emit o value
   */
  @Emit('change')
  public change(value: MenuValue) {
    return value;
  }

  get menuWrapperComponent() {
    return this.renderMenuWithPortal ? Portal : 'div';
  }

  public toggleMenu() {
    this.isOpen ? this.closeMenu() : this.openMenu();
  }

  private openMenu() {
    this.isOpen = true;
  }

  async computeMenuPosition() {
    try {
      const menu = this.menuRef.$el as HTMLElement;
      const data = await computePosition(this.filterRef.$el, menu, {
        middleware: [
          offset(4),
          autoPlacement({
            allowedPlacements: this.menuAllowedPlacements
          })
        ]
      });

      menu.style.top = `${data.y}px`;
      menu.style.left = `${data.x}px`;
      menu.style.position = 'absolute';
    } catch (e) {
      console.error(e);
    }
  }

  private closeMenu(removeItemsNotSelected = true) {
    if (removeItemsNotSelected && this.isMultiselect) {
      this.arrMultiple = this.value;
    }
    this.isOpen = false;
  }

  get isMultiselect() {
    return this.type === MenuType.Multiple;
  }

  public handleClickOutside() {
    if (this.type === MenuType.Single) {
      this.isOpen = false;
      return false;
    }
  }

  setAutoUpdate() {
    const menu = this.menuRef.$el as HTMLElement;
    this.cleanupComputeMenuPosition = autoUpdate(
      this.filterRef.$el,
      menu,
      this.computeMenuPosition
    );
  }

  async mounted() {
    // In some cases even inside the mounted cycle the menuRef and filterRef still doesn`t exist, to be sure that
    // we will execute what we need using these refs we are using a set interval to loop until these ref are rendered
    if (this.menuRef?.$el && this.filterRef?.$el) {
      this.setAutoUpdate();
    } else {
      this.setAutoUpdateInterval = setInterval(() => {
        if (this.menuRef?.$el && this.filterRef?.$el) {
          clearInterval(this.setAutoUpdateInterval as unknown as number);
          this.setAutoUpdate();
        }
      }, 50);
    }
  }

  destroyed() {
    if (this.cleanupComputeMenuPosition) this.cleanupComputeMenuPosition();
    if (this.setAutoUpdateInterval) clearInterval(this.setAutoUpdateInterval);
  }

  private get isActive() {
    const isSelectedValueDefined = typeof this.selectedValue !== 'undefined';
    const isNumber = typeof this.selectedValue === 'number';
    const captionColor = Boolean(this.selectedCaptionColor);
    const hasSelectedValueLenght = Object.values(this.selectedValue).length > 0;
    return (
      (isSelectedValueDefined &&
        (isNumber || hasSelectedValueLenght || captionColor)) ||
      this.isOpen
    );
  }

  public get rightIconName() {
    return this.isOpen ? 'EA0150' : 'EA0165';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private arrMultiple: any = [];

  private set selectedValue(value: MenuValue) {
    if (this.isMultiselect) {
      this.arrMultiple = value;
    } else {
      this.change(value);
    }
  }

  private get selectedValue() {
    if (this.isMultiselect) {
      return this.arrMultiple;
    }
    return this.value;
  }

  private get selectedCaptionColor() {
    const value = this.value as IMenu;
    if (value && value.captionColor) return value.captionColor;
    return undefined;
  }

  get computedShowCaption() {
    if (this.selectedCaptionColor) {
      return this.selectedCaptionColor;
    }
    return this.defaultCaptionColor;
  }

  get filterLabel() {
    const value = this.value as IMenu;
    if (this.useOptionLabel && value) {
      return this.options.find(
        option => option.value === value || option.value === value.value
      )!.label;
    }
    return this.label;
  }

  get BadgeCounterValue() {
    return Object.values(this.selectedValue).length;
  }

  public appliedFilters() {
    this.change(this.arrMultiple);
    this.closeMenu(false);
  }
}
</script>

<style lang="less" scoped>
*[loki-test] .filter-select--button {
  border: 0px;
}

.menu {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  max-height: 352px;
  overflow-y: auto;
}

.filter-select {
  display: block;
  user-select: none;

  .filter-select--button:focus {
    outline: 0;
  }

  .slot-icon {
    line-height: 0;
  }
}
</style>
