<template>
  <HelperViewFoundation
    :class="computedHelperProps.class"
    :icon="computedHelperProps.icon"
    :title="title"
    :description="description"
    :button-label="buttonLabel"
    :button-disabled="buttonDisabled"
    :button-loading="buttonLoading"
    @click="helperViewButtonEvent"
  />
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import HelperViewFoundation from '@/foundation/helper-view/HelperViewFoundation.vue';
import { StatusColors } from './types';

@Component({
  name: 'HelperViewStatus',
  components: {
    HelperViewFoundation
  }
})
export default class HelperViewStatus extends Vue {
  /**
   * O título principal do componente
   */
  @Prop({ type: String, required: false })
  readonly title?: string;

  /**
   * O texto de apoio do componente
   */
  @Prop({ type: String, required: false })
  readonly description?: string;

  /**
   * Label do botão de ação
   */
  @Prop({ type: String, required: false })
  readonly buttonLabel?: string;

  /**
   * Habilita ou desabilita o botão de ação
   */
  @Prop({ type: Boolean, default: false })
  readonly buttonDisabled!: boolean;

  /**
   * Coloca o botão no estado de carregamento
   */
  @Prop({ type: Boolean, default: false })
  readonly buttonLoading!: boolean;

  /**
   * Define a cor de status do HelperView
   * @values positive, negative, alert, information
   */
  @Prop({ type: String, default: StatusColors.POSITIVE })
  readonly status!: string;

  /**
   * Evento de click do botão, quando existente
   */
  @Emit('click')
  private helperViewButtonEvent() {
    // event emitter on button footer click
  }

  private get computedHelperProps() {
    const helperStatusProps = {
      icon: 'EF0070',
      class: ['helper-status', `helper-status--${this.status}`]
    };

    switch (this.status) {
      case StatusColors.NEGATIVE:
        helperStatusProps.icon = 'EF0060';
        break;
      case StatusColors.ALERT:
        helperStatusProps.icon = 'EF0062';
        break;
      case StatusColors.INFORMATION:
        helperStatusProps.icon = 'DA0012';
        break;
    }

    return helperStatusProps;
  }
}
</script>

<style lang="less" scoped>
.initialAvatar(@background, @color) {
  ::v-deep .initial-avatar-icon {
    background: @background;
    color: @color;
  }
}

.helper-status {
  &--positive {
    .initialAvatar(@status-positive-background, @status-positive);
  }
  &--negative {
    .initialAvatar(@status-negative-background, @status-negative);
  }
  &--information {
    .initialAvatar(@status-info-background, @status-info);
  }
  &--alert {
    .initialAvatar(@status-alert-background, @status-alert);
  }
}
</style>
