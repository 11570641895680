<template>
  <span class="base-icon--container">
    <svgicon
      :icon="icon"
      :width="width"
      :height="height"
      :color="computedColors"
      :alt="alt"
      :title="alt"
      :class="computedClassName"
      :original="original"
      :data-testid="`base-icon-${icon}`"
      :aria-hidden="computedAriaHidden"
      role="img"
    />
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import svgicon from 'vue-svgicon';

@Component({
  name: 'BaseIcon',
  components: { svgicon }
})
export default class BaseIcon extends Vue {
  @Prop({ type: String, required: true }) public icon!: string;

  @Prop({ type: String, default: '24' }) public height!: string;

  @Prop({ type: String, default: '24' }) public width!: string;

  @Prop({ type: [String, Array], default: 'currentColor' }) public colors!:
    | string
    | string[];

  @Prop({ type: String, required: false }) public alt?: string;

  @Prop({ type: Boolean, required: false }) public spin?: boolean;

  @Prop({ type: Boolean, required: false }) public original?: boolean;

  async created() {
    try {
      await import(
        /* webpackChunkName: "nebraska-icons" */
        /* webpackPrefetch: true */
        '@/icons/vue-icons'
      );
    } catch (err) {
      console.error(err);
    }

    try {
      await import(
        /* webpackChunkName: "nebraska-icons" */
        /* webpackPrefetch: true */
        '@/warren-icons/vue-icons'
      );
    } catch (err) {
      console.error(err);
    }
  }

  get computedClassName() {
    return this.spin ? ['spin'] : '';
  }

  get computedColors() {
    if (this.original) return null;

    const reduceColors = (colors: string[]) =>
      colors.reduce((previous, current) => `${previous} ${current}`, '');

    return Array.isArray(this.colors)
      ? reduceColors(this.colors).trim()
      : this.colors;
  }

  get computedAriaHidden() {
    return !this.alt;
  }
}
</script>

<style lang="less" scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.base-icon--container {
  display: block;
  font-size: 0;
  line-height: 1em;
}

.svg-icon {
  color: inherit;
}

.svg-fill {
  fill: currentColor;
  stroke: none;
}

.svg-up {
  transform: rotate(0deg);
}

.svg-right {
  transform: rotate(90deg);
}

.svg-down {
  transform: rotate(180deg);
}

.svg-left {
  transform: rotate(-90deg);
}

.spin {
  animation: spin 1.2s infinite linear;
}
</style>
