<template>
  <span data-testid="countdown">{{ minutes }}:{{ seconds }}</span>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component({
  name: 'Countdown'
})
export default class Countdown extends Vue {
  /**
   *  Valor inicial do contador em Segundos
   */
  @Prop({ type: Number, required: false, default: 60 })
  private countdownValue!: number;

  /**
   *  Variável que cria o timer como um estado
   */
  private timer!: ReturnType<typeof setInterval>;

  /**
   *  Variável que controla o valor que será mostrado em tela
   */
  private showValue = this.countdownValue;

  /**
   *  Evento emitido quando o contador chega a zero
   */
  @Emit('finished')
  public finished() {
    // Função que emite evento
  }

  /**
   *  Valida quando o contador chega a zero
   */
  @Watch('showValue')
  private checkShowValue() {
    if (this.showValue === 0) {
      clearInterval(this.timer);
      this.finished();
    }
  }

  /**
   *  Dado computado que retorna os minutos formatados para 00:00
   */
  private get minutes(): string {
    const minutes = Math.floor(this.showValue / 60);
    return minutes.toLocaleString([], { minimumIntegerDigits: 2 });
  }

  /**
   *  Dado computado que retorna os segundos formatados para 00:00
   */
  private get seconds(): string {
    const seconds = Math.floor(this.showValue % 60);
    return seconds.toLocaleString([], { minimumIntegerDigits: 2 });
  }

  private mounted() {
    this.initCountdown();
  }

  private initCountdown(): void {
    this.timer = setInterval(() => {
      this.showValue--;
    }, 1000);
  }

  private beforeDestroy() {
    this.clearTimer();
  }

  private clearTimer() {
    clearInterval(this.timer);
  }
}
</script>
