<template>
  <div class="container">
    <button
      tabindex="-1"
      class="increase-decrease-item"
      :aria-disabled="isDecreaseDisabled"
      aria-label="Diminuir"
      data-testid="decrease-testid"
      type="button"
      @click="!isDecreaseDisabled && decrease()"
    >
      <BaseIcon icon="EF0020" :colors="iconColor(isDecreaseDisabled)" />
    </button>
    <div class="divider" />
    <button
      tabindex="-1"
      class="increase-decrease-item"
      :aria-disabled="isIncreaseDisabled"
      aria-label="Aumentar"
      data-testid="increase-testid"
      type="button"
      @click="!isIncreaseDisabled && increase()"
    >
      <BaseIcon icon="EF0010" :colors="iconColor(isIncreaseDisabled)" />
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';

export enum DisabledStatus {
  NONE,
  ALL,
  INCREASE,
  DECREASE
}

@Component({
  name: 'IncreaseDecreaseButton',
  components: {
    BaseIcon
  }
})
export default class IncreaseDecreaseButton extends Vue {
  private NebraskaColors = NebraskaColors;

  @Prop({ type: Number, default: DisabledStatus.NONE })
  private disabled!: DisabledStatus;

  private iconColor(isDisabled: boolean) {
    return isDisabled
      ? this.NebraskaColors.elementDisabled
      : this.NebraskaColors.elementPrimary;
  }

  private get isDecreaseDisabled(): boolean {
    return (
      this.disabled === DisabledStatus.ALL ||
      this.disabled === DisabledStatus.DECREASE
    );
  }

  private get isIncreaseDisabled(): boolean {
    return (
      this.disabled === DisabledStatus.ALL ||
      this.disabled === DisabledStatus.INCREASE
    );
  }

  /**
   * Evento disparado pelo botão `mais`
   */
  @Emit('increase')
  increase() {
    // Função que emite evento
  }

  /**
   * Evento disparado pelo botão `menos`
   */
  @Emit('decrease')
  decrease() {
    // Função que emite evento
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  background-color: @background-tertiary;
  width: 97px;
  border-radius: @size-radius-x100;
  height: 40px;
}
button {
  all: unset;
  cursor: pointer;
}
.increase-decrease-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 48px;
  border-radius: @size-radius-x100;
  &:hover {
    background-color: @background-hover;
  }
  &[aria-disabled='true'] {
    pointer-events: none;
  }
}

.divider {
  border-left: @size-border-x400 solid @divider-opacity;
  background: @divider-opacity;
  opacity: 0.24;
  height: 24px;
  top: 8px;
  position: relative;
}
</style>
