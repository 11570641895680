<template>
  <div>
    <InitialAvatarFoundation
      v-if="shouldRenderImage"
      class="initial-avatar-image"
      :size="size"
      :border-radius="borderRadius"
    >
      <img
        class="initial-avatar-image--image"
        :src="computedAssetURL"
        :data-testid="`initial-avatar-image-${image}`"
        :alt="alt"
      />
    </InitialAvatarFoundation>
    <!-- @slot Componente renderizado caso não exista uma imagem com o valor passado -->
    <slot v-else name="fallback" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import InitialAvatarFoundation from '@/foundation/initial-avatar/InitialAvatarFoundation.vue';
import {
  InitialAvatarSizes,
  InitialAvatarBorderRadius
} from '@/foundation/types';
import { MapAssetCode } from './InitialavatarImageTypes';

@Component({
  name: 'InitialAvatarImage',
  components: { InitialAvatarFoundation }
})
export default class InitialAvatarImage extends Vue {
  /**
   * A imagem a ser exibida
   */
  @Prop({ type: String, required: true })
  readonly image!: string;

  /**
   * Define a descrição da imagem
   */
  @Prop({ type: String, required: true })
  readonly alt!: string;

  /**
   * Define o tamanho do InitialAvatar
   * @values extra-small, small, medium, large
   */
  @Prop({ type: String, default: InitialAvatarSizes.EXTRA_SMALL })
  readonly size!: string;

  /**
   * Define o border radius do InitialAvatar
   * @values none, extra-small, small, medium, large, extra-large, max
   */
  @Prop({ type: String, default: InitialAvatarBorderRadius.MAX })
  readonly borderRadius!: string;

  get computedAssetURL() {
    return MapAssetCode.get(this.image);
  }

  get hasFallbackSlot() {
    return !!this.$slots.fallback;
  }

  get shouldRenderImage() {
    return (
      this.computedAssetURL || (!this.hasFallbackSlot && !this.computedAssetURL)
    );
  }
}
</script>

<style lang="less" scoped>
.initial-avatar-image {
  overflow: hidden;

  &--image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
