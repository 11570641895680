<template>
  <p :class="computedClassName">
    {{ value }}
  </p>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

export enum BadgeCounterSizes {
  SMALL = 'small',
  REGULAR = 'regular'
}

@Component({
  name: 'BadgeCounter'
})
export default class BadgeCounter extends Vue {
  /**
   * Valor do BadgeCounter
   */
  @Prop({ type: Number, default: 0 })
  readonly value!: number;

  /**
   * Desativa o BadgeCounter
   */
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  /**
   * BadgeCounter ativado
   */
  @Prop({ type: Boolean, default: false })
  readonly active!: boolean;

  /**
   * Define o tamanho do BadgeCounter
   * @values small, regular
   */
  @Prop({
    type: String,
    default: BadgeCounterSizes.REGULAR
  })
  readonly size!: BadgeCounterSizes;

  /**
   * Define as cores do BadgeCounter
   */
  private get computedClassName() {
    const classes = ['badge-counter--container', `badge-counter--${this.size}`];
    if (this.disabled) classes.push('badge-counter--disabled');
    else if (this.active) classes.push('badge-counter--active');
    return classes;
  }
}
</script>

<style lang="less" scoped>
.badge-counter-- {
  &container {
    .text-p-6();
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: @size-radius-max;
    background-color: @base-select;
    color: @base-primary;
    font-weight: bold;
  }

  &regular {
    width: 24px;
    height: 24px;
  }

  &small {
    width: 16px;
    height: 16px;
  }

  &active {
    background-color: @base-primary;
    color: @base-select;
  }

  &disabled {
    background-color: @base-over-disabled;
    color: @base-disabled;
  }
}
</style>
