<template>
  <div ref="containerRef" class="skeleton-container">
    <slot>
      <div v-if="showAnimation" :style="{ height, width, borderRadius }">
        <div class="skeleton-container__animation" />
      </div>
    </slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';

@Component({
  name: 'SkeletonLoader'
})
export default class SkeletonLoader extends Vue {
  /**
   * Habilita a aparência de loader
   */
  @Prop({ type: Boolean, default: true })
  readonly showAnimation!: boolean;

  /**
   * Define a largura do loader
   */
  @Prop({ type: String, required: false })
  readonly width!: string;

  /**
   * Define a altura do loader
   */
  @Prop({ type: String, required: false })
  readonly height!: string;

  /**
   * Define a curva da borda do loader
   */
  @Prop({ type: String, required: false })
  readonly borderRadius!: string;

  @Ref()
  readonly containerRef!: HTMLDivElement;

  public mounted() {
    if (!this.$slots.default) return;

    let animationDelay = 0;

    const children = Array.from(this.containerRef.children) as HTMLElement[];

    children.forEach(child => {
      child.style.color = 'transparent';
      child.style.animationDelay = `${animationDelay}s`;

      if (child.children && child.children.length) {
        Array.from(child.children).forEach(
          grandChild => ((grandChild as HTMLElement).style.display = 'none')
        );
      }

      if (this.showAnimation) {
        const appendedChild = child.appendChild(document.createElement('div'));
        appendedChild.classList.add('skeleton-container__animation');
        appendedChild.style.animationDelay = `${animationDelay}s`;
        animationDelay += 0.15;
      }
    });
  }
}
</script>

<style lang="less" scoped>
.skeleton-container {
  > * {
    position: relative;
    overflow: hidden;
    opacity: 0.5;
    background-color: @background-tertiary;
  }

  ::v-deep &__animation {
    background: linear-gradient(
      90deg,
      transparent 0%,
      @background-secondary 50%,
      transparent 100%
    );
    width: 40%;
    height: 100%;
    position: relative;
    animation: skeleton-loader-animation cubic-bezier(0.1, -0.01, 0.68, 1.04)
      1.25s infinite;
  }
}

@keyframes skeleton-loader-animation {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}
</style>
