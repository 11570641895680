<template>
  <div class="progressbar--container">
    <progress :value="progress" max="1" class="progressbar--fallback" />
    <div class="progressbar--background" :style="computedBackgroundStyle">
      <div class="progressbar--status" :style="computedStatusStyle" />
    </div>
  </div>
</template>

<script lang="ts">
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ProgressBar'
})
export default class ProgressBar extends Vue {
  /**
   * O valor da barra de progresso, deve ser entre `0` e `1`.
   */
  @Prop({
    type: Number,
    required: true,
    validator: (value: number) => value >= 0 && value <= 1
  })
  public progress!: number;

  /**
   * A cor da barra de progresso.
   */
  @Prop({ type: String, default: NebraskaColors.progressPositive })
  public progressColor!: NebraskaColors;

  /**
   * A cor de fundo de área disponível para a barra.
   */
  @Prop({ type: String, default: NebraskaColors.progressBackground })
  public progressBackground!: NebraskaColors;

  /**
   * A altura da barra de progresso, em pixels.
   */
  @Prop({ type: Number, default: 4 })
  public height!: number;

  private get computedBackgroundStyle() {
    return {
      backgroundColor: this.progressBackground,
      borderRadius: `${this.height}px`,
      height: `${this.height}px`
    };
  }

  private get computedStatusStyle() {
    return {
      backgroundColor: this.progressColor,
      borderRadius: `${this.height}px`,
      width: `${this.progress * 100}%`
    };
  }
}
</script>

<style lang="less" scoped>
.progressbar {
  &--container {
    position: relative;
  }

  &--fallback {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }

  &--background {
    width: 100%;
  }

  &--status {
    max-width: 100%;
    height: 100%;
    transition: width 500ms ease-in-out 200ms,
      background-color 500ms ease-in-out;
  }
}
</style>
