<template>
  <ChipFoundation
    :class="computedChipClasses"
    :label="label"
    :icon="icon"
    :size="size"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ChipFoundation } from '@/foundation';
import { ChipSizes } from '@/foundation/types';
import { StatusColors } from './types';

@Component({
  name: 'ChipStatus',
  components: { ChipFoundation }
})
export default class ChipStatus extends Vue {
  /**
   * Texto exibido no Chip
   */
  @Prop({ type: String, required: true })
  readonly label!: string;

  /**
   * Nome do ícone utilizado no Chip
   * @see https://docs.dev.warren.com.br/nebraska/?path=/docs/assets-icons--all-icons
   */
  @Prop({ type: String, required: false })
  readonly icon?: string;

  /**
   * Define o tamanho do Chip
   * @values regular, large
   */
  @Prop({ type: String, default: ChipSizes.REGULAR })
  readonly size!: string;

  /**
   * Define a cor de status do Chip
   * @values positive, negative, alert, information
   */
  @Prop({ type: String, default: StatusColors.POSITIVE })
  readonly status!: string;

  private get computedChipClasses() {
    const chipClasses = ['chip-status'];
    switch (this.status) {
      case StatusColors.NEGATIVE:
      case StatusColors.ALERT:
      case StatusColors.INFORMATION:
        chipClasses.push(`chip-status--${this.status}`);
        break;
      default:
        chipClasses.push(`chip-status--${StatusColors.POSITIVE}`);
        break;
    }
    return chipClasses;
  }
}
</script>

<style lang="less" scoped>
.chip-status {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.chip-status--positive {
    background: @status-positive-background;
    color: @status-positive;
  }

  &.chip-status--negative {
    background: @status-negative-background;
    color: @status-negative;
  }

  &.chip-status--alert {
    background: @status-alert-background;
    color: @status-alert;
  }

  &.chip-status--information {
    background: @status-info-background;
    color: @status-info;
  }
}
</style>
