<template>
  <InputFoundation
    :id="id"
    v-model="innerValue"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :label="label"
    :type="inputType"
    :status-type="status"
    :disabled="disabled"
    v-bind="inputAttr"
    :required="required"
    v-on="$listeners"
  >
    <template #leading-slot>
      <BaseIcon icon="BC0020" :colors="['currentColor']" />
    </template>
    <template #trailing-slot>
      <VisibilityToggle
        :hidden="isTextHidden"
        :disabled="disabled"
        use-current-color
        @change="toggleType"
      />
    </template>
    <template #bottom-slot>
      <ProgressBar
        v-if="passwordStrength"
        :progress="passwordStrength"
        :progress-color="computedPasswordStrengthColor"
      />
      <HelperText
        v-if="helperText"
        :id="helperTextId"
        :text="helperText"
        :status-type="status"
      />
    </template>
  </InputFoundation>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { HelperText } from '@/components/helper-text';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import InputFoundation from '@/foundation/input/InputFoundation.vue';
import { InputStatusType } from '@/foundation/types';
import { VisibilityToggle } from '@/components/visibility-toggle';
import { ProgressBar } from '@/components/progress';

@Component({
  name: 'InputPassword',
  components: {
    BaseIcon,
    InputFoundation,
    ProgressBar,
    VisibilityToggle,
    HelperText
  }
})
export default class InputPassword extends Vue {
  /**
   * O texto de espera exibido quando o input não possui valor
   */
  @Prop({ type: String })
  public placeholder?: string;

  /**
   * Rótulo (label) exibido no input.
   */
  @Prop({ type: String })
  public label?: string;

  /**
   * O valor do input
   * @model
   */
  @Prop({ type: String, default: '' })
  public value!: string;

  /**
   * Para exibir a barra de progresso indicando a força de senha.
   */
  @Prop({
    type: Number,
    required: false,
    validator: (value: number) => value >= 0 && value <= 1
  })
  public passwordStrength?: number;

  /**
   * Define a cor da barra de progresso.
   */
  @Prop({
    type: String,
    required: false,
    default: NebraskaColors.progressPositive,
    validator: (value: NebraskaColors) =>
      Object.values(NebraskaColors).includes(value)
  })
  public passwordStrengthColor!: NebraskaColors;

  /**
   * O texto de ajuda abaixo do input.
   */
  @Prop({
    type: String,
    required: false
  })
  public helperText?: string;

  /**
   * Permite desativar o input; nenhuma interação do usuário é respondida
   */
  @Prop({
    type: Boolean,
    default: false
  })
  public disabled!: boolean;

  /**
   * Possibilita definir estados de sucesso e erro.
   * @values error, success
   */
  @Prop({
    type: String,
    default: InputStatusType.Default
  })
  public status!: InputStatusType;

  /**
   * Indica que a entrada do usuário é obrigatória.
   */
  @Prop({ type: Boolean, required: false, default: false })
  private required!: boolean;

  /**
   * O autocomplete para senhas possui uma configuração diferente no navegador quando é setado o "new-password".
   * @values new-password, current-password
   */
  @Prop({ type: String, required: false, default: 'current-password' })
  private autocomplete?: string;

  /**
   * Se não passado nenhum id, ele será gerado aleatóriamente.
   */
  @Prop({ type: String, required: false })
  private id?: string;

  private isTextHidden = true;

  /**
   * Retorna o valor atual do input, a cada mudança
   */
  @Emit('input')
  public input(value: string) {
    return value;
  }

  get helperTextId() {
    const randomId = Math.random().toString(16).slice(2);
    return `helper-text-${randomId}`;
  }

  private get computedPasswordStrengthColor(): NebraskaColors {
    return this.disabled
      ? NebraskaColors.progressDisabled
      : this.passwordStrengthColor;
  }

  private get inputType() {
    return this.isTextHidden ? 'password' : 'text';
  }

  private get innerValue() {
    return this.value;
  }

  // Como usamos o innerValue como nosso v-model, é necessário o setter dessa propriedade também
  private set innerValue(value) {
    this.input(value);
  }

  get inputAttr() {
    return this.helperText ? { 'aria-describedby': this.helperTextId } : '';
  }

  toggleType(newStatus: boolean) {
    this.isTextHidden = newStatus;
  }
}
</script>
