<template>
  <div :id="id">
    <p v-if="!hasErrorStatusType">
      {{ text }}
    </p>
    <p aria-live="assertive" aria-atomic="true">
      {{ errorText }}
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { InputStatusType } from '@/foundation/types';

@Component({
  name: 'HelperText'
})
export default class HelperText extends Vue {
  /**
   * Id para ser usado no HelperText que pode ser referenciado como descrição do Input
   */
  @Prop({ type: String, required: true })
  readonly id!: string;

  /**
   * Texto que vai ser renderizado como helper text
   */
  @Prop({ type: String, required: false })
  readonly text!: string;

  /**
   * Informa o status do Input em que o HelperText está localizado
   */
  @Prop({ type: String, required: false })
  readonly statusType?: InputStatusType;

  get hasErrorStatusType() {
    return this.statusType === InputStatusType.Error;
  }

  get errorText() {
    return this.hasErrorStatusType ? this.text : '';
  }
}
</script>

<style lang="less" scoped>
p {
  margin: @size-spacing-x300 0;
}
</style>
