<template>
  <header class="heading-display">
    <div class="heading-display__main-container">
      <div class="heading-display__head">
        <slot name="heading-component">
          <div class="heading-display__head-subhead">
            <BaseText
              v-for="(head, index) in parseHeadToArray"
              :key="index"
              :size="displaySize.text"
              class="heading-display__title"
              :color="NebraskaColors.elementPrimary"
            >
              <span class="heading-display__title-divider" />
              {{ head }}
            </BaseText>
          </div>
        </slot>
      </div>
      <Heading as="h1" :level="displaySize.titleLevel">
        <slot name="title-component">
          {{ title }}
        </slot>
      </Heading>
      <div class="heading-display__subtitle">
        <slot name="subtitle-component">
          <BaseText :size="displaySize.text">
            {{ subtitle }}
          </BaseText>
        </slot>
      </div>
    </div>
    <div
      v-if="shouldShowAvatarComponent"
      class="heading-display__avatar-container"
    >
      <component
        :is="dynamicAvatarComponent"
        :[avatarType]="avatarContent"
        :size="displaySize.initialAvatarSize"
        :alt="avatarAlt"
      >
        <template #fallback>
          <!-- @slot Componente renderizado caso não exista uma imagem com o valor passado -->
          <slot name="avatar-image-fallback" />
        </template>
      </component>
    </div>
  </header>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseText } from '@/components/base-text';
import Heading from '../heading/Heading.vue';
import {
  InitialAvatarChar,
  InitialAvatarIcon,
  InitialAvatarImage
} from '@/components/initial-avatar';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { HeadingDisplaySizes, AvatarTypes } from './types';

@Component({
  name: 'HeadingDisplay',
  components: {
    BaseText,
    Heading,
    InitialAvatarChar,
    InitialAvatarIcon,
    InitialAvatarImage
  }
})
export default class HeadingDisplay extends Vue {
  /**
   * Título exibido no Heading Display
   */
  @Prop({ type: String, required: true })
  readonly title!: string;

  /**
   * Texto a ser exibido no Head | Subhead (acima do title)
   */
  @Prop({ type: [Array, String], default: '' })
  readonly head!: string[] | string;

  /**
   * Texto a ser exibido no Subtitle (abaixo do title)
   */
  @Prop({ type: String, default: '' })
  readonly subtitle!: string;

  /**
   * Configura o tamanho do componente
   * @values extra-small, small, medium, large
   */
  @Prop({ type: String, default: HeadingDisplaySizes.MEDIUM })
  readonly size!: HeadingDisplaySizes;

  /**
   * Tipo do InitialAvatar
   * @values char, icon, image
   */
  @Prop({ type: String, required: false })
  readonly avatarType!: string;

  /**
   * Conteúdo do InitialAvatar
   * InitialAvatarChar => caractere
   * InitialAvatarIcon => código do ícone
   * InitialAvatarImage => código da imagem
   */
  @Prop({ type: String, required: false })
  readonly avatarContent!: string;

  /**
   * Texto alternativo para o avatar
   */
  @Prop({ type: String, required: false })
  readonly avatarAlt!: string;

  readonly NebraskaColors = NebraskaColors;

  public get parseHeadToArray() {
    return !Array.isArray(this.head) ? [this.head] : this.head;
  }

  public get displaySize() {
    let sizes = { titleLevel: 2, text: 'md', initialAvatarSize: 'medium' };
    switch (this.size) {
      case HeadingDisplaySizes.EXTRA_SMALL:
        sizes = { titleLevel: 4, text: 'xs', initialAvatarSize: 'small' };
        break;
      case HeadingDisplaySizes.SMALL:
        sizes = { titleLevel: 3, text: 'sm', initialAvatarSize: 'small' };
        break;
      case HeadingDisplaySizes.LARGE:
        sizes = { titleLevel: 1, text: 'lg', initialAvatarSize: 'medium' };
        break;
    }
    return sizes;
  }

  public get shouldShowAvatarComponent() {
    return Object.keys(AvatarTypes).includes(this.avatarType);
  }

  public get dynamicAvatarComponent() {
    const keyAvatarTypes = this.avatarType as keyof typeof AvatarTypes;
    return AvatarTypes[keyAvatarTypes];
  }
}
</script>

<style lang="less" scoped>
.heading-display {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: @size-spacing-x300;

  &__head {
    display: flex;
    margin-bottom: @size-spacing-x150;
  }

  &__subtitle {
    margin-top: @size-spacing-x150;
  }

  &__head-subhead {
    display: flex;
  }

  &__title {
    display: inherit;
  }

  &__title:nth-child(2) &__title-divider {
    width: 1px;
    background-color: @divider-primary;
    display: block;
    margin: 0 @size-spacing-x300;
  }

  &__avatar-container {
    display: inherit;
    align-items: center;
    justify-content: center;
  }
}
</style>
