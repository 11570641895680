<template>
  <Option :disabled="disabled" :icon="iconName" :reverse="reverse">
    <template #input>
      <input
        v-model="isChecked"
        data-testid="radio-input"
        type="radio"
        :disabled="disabled"
        :value="value"
      />
    </template>
    <template #label>
      <slot />
    </template>
  </Option>
</template>

<script lang="ts">
import { Component, Vue, Prop, ModelSync } from 'vue-property-decorator';
import Option from '@/foundation/option/Option.vue';

@Component({
  name: 'RadioButton',
  components: { Option }
})
export default class RadioButton extends Vue {
  /** O `checked` se relaciona diretamente com a `v-model` para que possamos informar que o elemento está checked ou não, mudando seu status. */
  @ModelSync('checked', 'change', { type: [String, Boolean, Number] })
  private isChecked!: string | boolean | number;

  /** value para receber valores específicos no radio button. */
  @Prop({ type: [String, Boolean, Number], required: false })
  private value!: string | boolean | number;

  /** disabled ativa a aparência desabilitado do radio button. */
  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  /** reverse ativa o css para trocar os elementos de lado, são para caso que o Label tenha que vir antes do checkbox. */
  @Prop({ type: Boolean, default: false })
  private reverse!: boolean;

  private get iconName(): string {
    if (this.isChecked === this.value) {
      return 'ic_radio_on';
    }

    return 'ic_radio_off';
  }
}
</script>
