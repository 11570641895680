<template>
  <div class="table-loader">
    <SkeletonLoader>
      <div v-if="!isMobile" class="header-loader" />
      <div v-for="row in rows" :key="row" :class="classes" />
    </SkeletonLoader>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import SkeletonLoader from '@/components/skeleton-loader/SkeletonLoader.vue';

@Component({
  name: 'TableLoader',
  components: { SkeletonLoader }
})
export default class TableLoader extends Vue {
  /**
   * Number of data rows to display
   */
  @Prop({ type: Number, default: 3 })
  readonly rows!: number;

  /**
   * If true, displays mobile version
   */
  @Prop({ type: Boolean, default: false })
  readonly isMobile!: boolean;

  get classes() {
    return {
      'row-loader': true,
      'row-loader--mobile': this.isMobile
    };
  }
}
</script>

<style lang="less" scoped>
.header-loader {
  max-width: 128px;
  border-radius: @size-radius-x200;
  height: 24px;
  margin: @size-spacing-x300 0;
}

.row-loader {
  border-radius: @size-radius-x200;
  height: 48px;
  margin-top: @size-spacing-x300;
}

.row-loader--mobile {
  height: 64px;
}
</style>
