<template>
  <div data-testId="card" class="card">
    <div data-testId="cardBackground" class="card__background">
      <!-- @slot Espaço para adicionar uma imagem ao background do card -->
      <slot name="background" />
    </div>
    <div class="card__content">
      <div>
        <!-- @slot Espaço para adicionar conteúdo na parte superior do card -->
        <slot name="header" />
      </div>
      <div>
        <!-- @slot Espaço para adicionar conteúdo na parte inferior do card -->
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Card'
})
export default class Card extends Vue {}
</script>

<style lang="less" scoped>
.card {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 1;
  border-radius: @size-radius-x400;
  padding: @size-spacing-x600;
  position: relative;
  display: flex;
}
.card__background {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
.card__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
</style>
