<template>
  <BaseCell class="text-p-5 loading-cell" v-bind="$attrs">
    <SkeletonLoader>
      <div class="loading-cell__child" />
    </SkeletonLoader>
  </BaseCell>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import BaseCell from './BaseCell.vue';
import SkeletonLoader from '@/components/skeleton-loader/SkeletonLoader.vue';

@Component({
  name: 'LoadingCell',
  components: { BaseCell, SkeletonLoader }
})
export default class LoadingCell extends Vue {}
</script>

<style lang="less" scoped>
.loading-cell {
  padding: @size-spacing-x400 @size-spacing-x300 0;
  &__child {
    height: 40px;
    border-radius: @size-radius-x200;
  }
}
</style>
