<template>
  <Option :reverse="reverse" :disabled="disabled" :icon="iconName">
    <template #input>
      <input
        v-model="isChecked"
        data-testid="checkbox-input"
        type="checkbox"
        :disabled="disabled"
        :value="value"
        :indeterminate.prop="indeterminate"
      />
    </template>
    <template #label>
      <!-- @slot renderiza o texto da label do checkbox -->
      <slot />
    </template>
  </Option>
</template>

<script lang="ts">
import { Component, Vue, Prop, ModelSync } from 'vue-property-decorator';
import Option from '@/foundation/option/Option.vue';

@Component({
  name: 'Checkbox',
  components: { Option }
})
export default class Checkbox extends Vue {
  /** O `checked` se releciona diretamente com a `v-model` para que possamos informar que o elemento está checked ou não, mudando seu status. */
  @ModelSync('checked', 'change', { type: [Boolean, Array], default: false })
  private isChecked!: boolean | string[];

  /** value para receber valores especificos na checkbox. */
  @Prop({ type: String, required: false })
  private value!: string;

  /** disabled ativa a aparência desabilitado da checkbox. */
  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  /** indeterminate ativa a aparência indeterminada da checkbox. */
  @Prop({ type: Boolean, default: false })
  private indeterminate!: boolean;

  /** reverse ativa o css para trocar os elementos de lado, são para caso que o Label tenha que vir antes do checkbox. */
  @Prop({ type: Boolean, default: false })
  private reverse!: boolean;

  private get iconName(): string {
    if (this.indeterminate === true) {
      return 'ic_checkbox_indeterminate';
    }

    if (
      this.isChecked === true ||
      (Array.isArray(this.isChecked) && this.isChecked.includes(this.value))
    ) {
      return 'ic_checkbox_checked';
    }

    return 'ic_checkbox_unchecked';
  }
}
</script>
