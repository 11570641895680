<template>
  <ChipFoundation
    :class="computedChipClasses"
    :label="label"
    :icon="icon"
    :size="size"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ChipFoundation } from '@/foundation';
import { ChipSizes } from '@/foundation/types';
import { ContextColors } from './types';

@Component({
  name: 'ChipContext',
  components: { ChipFoundation }
})
export default class ChipContext extends Vue {
  /**
   * Texto exibido no Chip
   */
  @Prop({ type: String, required: true })
  readonly label!: string;

  /**
   * Nome do ícone utilizado no Chip
   * @see https://docs.dev.warren.com.br/nebraska/?path=/docs/assets-icons--all-icons
   */
  @Prop({ type: String, required: false })
  readonly icon?: string;

  /**
   * Define o tamanho do Chip
   * @values regular, large
   */
  @Prop({ type: String, default: ChipSizes.REGULAR })
  readonly size!: string;

  /**
   * Define a cor de context do Chip
   * @values brand, neutral
   */
  @Prop({ type: String, default: ContextColors.NEUTRAL })
  readonly context!: string;

  private get computedChipClasses() {
    const contextColor =
      this.context === ContextColors.BRAND
        ? ContextColors.BRAND
        : ContextColors.NEUTRAL;
    return ['chip-context', `chip-context--${contextColor}`];
  }
}
</script>

<style lang="less" scoped>
.chip-context {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.chip-context--brand {
    background: @brand-secondary;
  }

  &.chip-context--neutral {
    background: @neutral-secondary;
  }
}
</style>
