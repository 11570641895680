<template>
  <ButtonLabel
    :tabindex="tabindex"
    :href="href"
    :disabled="disabled"
    :loading="loading"
    :colors="computedColors"
    :size="size"
    :icon="icon"
    :icon-position="iconPosition"
    data-testid="button-tertiary"
    :class="computedOverImageClass"
    v-on="$listeners"
  >
    {{ label }}
  </ButtonLabel>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonLabel from '@/foundation/button-label/ButtonLabel.vue';
import {
  ButtonSizes,
  IconPosition,
  ButtonApplication
} from '@/foundation/types';
import { tertiaryColors, tertiaryOverImage } from '@/styles/Button';
import { ButtonColors } from '@/foundation/models/Button';

@Component({
  name: 'ButtonTertiary',
  components: { ButtonLabel }
})
export default class ButtonTertiary extends Vue {
  /**
   * Label visível no ButtonTertiary
   */
  @Prop({ type: String, required: true })
  readonly label!: string;

  /**
   * Define o tamanho do ButtonTertiary
   * @values regular, large
   */
  @Prop({ type: String, default: ButtonSizes.REGULAR })
  readonly size!: ButtonSizes;

  /**
   * O código do ícone a ser exibido
   */
  @Prop({ type: String })
  readonly icon?: string;

  /**
   * A posição do ícone
   * @values left, right
   */
  @Prop({
    type: String,
    default: IconPosition.LEFT
  })
  readonly iconPosition!: IconPosition;

  /**
   * Define qual a posição do elemento durante a navegação pelo teclado. Na maioria dos casos, não é necessário alterar o valor padrão.
   * @see {@link https://developer.mozilla.org/pt-BR/docs/Web/HTML/Global_attributes/tabindex}
   */
  @Prop({ type: Number })
  readonly tabindex?: number;

  /**
   * Permite atribuir um link ao ButtonTertiary
   */
  @Prop({ type: String })
  readonly href?: string;

  /**
   * Coloca o ButtonTertiary em estado de carregamento
   */
  @Prop({ type: Boolean })
  readonly loading?: boolean;

  /**
   * Coloca o ButtonTertiary em estado de desabilitado
   */
  @Prop({ type: Boolean })
  readonly disabled?: boolean;

  /**
   * Define qual a superfície onde o botão está inserido
   * @values normal, overImage
   */
  @Prop({
    type: String,
    default: ButtonApplication.NORMAL
  })
  readonly appliedOn!: ButtonApplication;

  get computedColors(): ButtonColors {
    switch (this.appliedOn) {
      case ButtonApplication.OVER_IMAGE:
        return tertiaryOverImage;
      case ButtonApplication.NORMAL:
      default:
        return tertiaryColors;
    }
  }

  get computedOverImageClass(): string {
    return this.appliedOn === ButtonApplication.OVER_IMAGE
      ? 'button-container--over-image'
      : '';
  }
}
</script>

<style lang="less" scoped>
.button-label {
  padding-left: 0;
  padding-right: 0;
  color: @theme-primary;

  &.loading {
    color: @theme-primary !important;
  }

  &.disabled {
    :deep(.background) {
      background: none;
      box-shadow: none;
    }
  }

  &:not(.button-container--over-image) {
    &:hover,
    &:focus {
      :deep(.button--label) {
        color: @theme-primary-hover;
      }
    }
  }

  &:active {
    :deep(.button--label) {
      color: @theme-primary-active;
    }
  }
}
</style>
