<template>
  <component :is="computedAs" :class="computedClassName" class="heading">
    <slot />
  </component>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'Heading'
})
export default class Heading extends Vue {
  @Prop({ type: Number, default: 1 })
  private level!: number;

  @Prop({ type: Boolean, default: false })
  private isDisplay!: boolean;

  @Prop({ type: String, required: false })
  private as?: string;

  private get computedAs() {
    if (this.as) {
      return this.as;
    }

    return `h${this.level}`;
  }

  private get computedClassName() {
    const type = this.isDisplay ? 'd' : 'h';

    return `text-${type}-${this.level}`;
  }
}
</script>

<style lang="less" scoped>
.heading {
  color: @element-primary;
  margin: 0;
}
</style>
