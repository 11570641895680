<template>
  <div class="mfa-content">
    <Heading class="mfa-content__title" :level="4" data-testid="mfa-title-sms">
      Código enviado por SMS
    </Heading>
    <BaseText
      :color="NebraskaColors.textSecondary"
      data-testid="mfa-description-sms"
    >
      Enviamos um código por SMS para o seu dispositivo. Se você não recebeu,
      aguarde para reenviarmos.
    </BaseText>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { BaseText } from '@/components/base-text';
import { Heading } from '@/components/heading';

@Component({
  name: 'MFAContentSMS',
  components: { BaseText, Heading }
})
export default class MFAContentSMS extends Vue {
  readonly NebraskaColors = NebraskaColors;
}
</script>

<style lang="less" scoped>
.mfa-content {
  max-width: 370px;

  &__title {
    margin-bottom: @size-spacing-x400;
  }
}
</style>
