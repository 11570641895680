<template>
  <div :class="computedClassName">
    <span class="initial-avatar--slot">
      <!-- @slot Área padrão para receber char, imagem ou ícone -->
      <slot />
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InitialAvatarSizes, InitialAvatarBorderRadius } from '../types';

@Component({
  name: 'InitialAvatarFoundation'
})
export default class InitialAvatarFoundation extends Vue {
  /**
   * Define o tamanho do InitialAvatar
   * @values extra-small, small, medium, large
   */
  @Prop({ type: String, default: InitialAvatarSizes.EXTRA_SMALL })
  readonly size!: string;

  /**
   * Define o border radius do InitialAvatar
   * @values none, extra-small, small, medium, large, extra-large, max
   */
  @Prop({ type: String, default: InitialAvatarBorderRadius.MAX })
  readonly borderRadius!: string;

  public get computedClassName() {
    return [
      'initial-avatar',
      `initial-avatar--size-${this.size}`,
      `initial-avatar--border-radius-${this.borderRadius}`
    ];
  }
}
</script>

<style lang="less" scoped>
.initial-avatar {
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  &--size-small {
    width: 40px;
    height: 40px;
  }
  &--size-medium {
    width: 48px;
    height: 48px;
  }
  &--size-large {
    width: 64px;
    height: 64px;
  }
  &--border-radius-none {
    border-radius: 0;
  }
  &--border-radius-extra-small {
    border-radius: 8%;
  }
  &--border-radius-small {
    border-radius: 16%;
  }
  &--border-radius-medium {
    border-radius: 24%;
  }
  &--border-radius-large {
    border-radius: 32%;
  }
  &--border-radius-extra-large {
    border-radius: 48%;
  }
}
</style>
