<template>
  <div :class="computedStatusClass">
    <div class="input-container">
      <fieldset
        ref="fieldset"
        :class="[
          'input-wrapper',
          computedInputFilled,
          computedPlaceholderClass,
          computedWithValueClass,
          computedLeadingSlotClass,
          computedWithoutLabel,
          computedInputSizeClass
        ]"
      >
        <legend>
          <span class="text-p-6 float-label" aria-hidden>{{ label }}</span>
        </legend>
        <div
          v-if="$slots['leading-slot']"
          ref="leading-slot"
          class="leading-icon"
        >
          <!-- @slot Conteúdo à esquerda da área de escrita -->
          <slot name="leading-slot" />
        </div>
        <input
          :id="computedId"
          v-mask="mask"
          :value="value"
          :placeholder="placeholder"
          :type="type"
          :readonly="readonly"
          :disabled="disabled"
          :required="required"
          :aria-required="required"
          :aria-invalid="hasErrorStatusType"
          :autocomplete="autocomplete"
          :aria-label="ariaLabel"
          v-bind="$attrs"
          class="text-p-4"
          tabindex="0"
          v-on="computedInputListeners"
        />
        <label
          v-if="!!label"
          :class="['normal-label', 'text-p-4']"
          :style="{ left: `${initialLabelPosition}px` }"
          :for="computedId"
        >
          {{ label }}
        </label>
        <div v-if="$slots['trailing-slot']" class="trailing-icon">
          <!-- @slot Conteúdo à esquerda da área de escrita -->
          <slot name="trailing-slot" />
        </div>
      </fieldset>
    </div>
    <div v-if="$slots['bottom-slot']" class="text-p-6 bottom-helper">
      <!-- @slot Conteúdo abaixo do input (helper text) -->
      <slot name="bottom-slot" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import { InputStatusType, InputSizes } from '../types';
import { mask } from 'vue-the-mask';

@Component({
  name: 'InputFoundation',
  inheritAttrs: false,
  directives: {
    mask: (el, binding, vnode, oldVnode) => {
      if (!binding.value) return;
      mask(el, binding, vnode, oldVnode);
    }
  }
})
export default class InputFoundation extends Vue {
  @Ref('fieldset')
  readonly fieldset!: HTMLFieldSetElement;

  @Ref('leading-slot')
  readonly leadingSlot!: HTMLDivElement;

  /**
   * Rótulo (label) exibido no input.
   */
  @Prop({ type: String, required: false })
  readonly label?: string;

  /**
   * Valor inicial do input
   */
  @Prop({ type: [String, Number], required: false })
  readonly value?: string | number;

  /**
   * O tipo do `input`, conforme especificação HTML. O InputFoundation é uma abstração apenas para alguns tipos da especificação.
   * @values email, file, number, password, search, tel, text, url
   */
  @Prop({ type: String, required: true })
  readonly type!: string;

  /**
   * Caso o input seja somente leitura (permite selecionar o valor)
   */
  @Prop({ type: Boolean, required: false, default: false })
  readonly readonly!: boolean;

  /**
   * Caso o input esteja desativado
   */
  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;

  /**
   * O texto exibido dentro do input, quando não há valor digitado
   */
  @Prop({ type: String, required: false })
  readonly placeholder?: string;

  /**
   * O atributo usado para indicar que a entrada do usuário é obrigatória.
   */
  @Prop({ type: Boolean, required: false, default: false })
  readonly required?: boolean;

  /**
   * O id está relacionado diretamente com o autocomplete, se você optar por não passar um id, consequentemente não irá funcionar o autocomplete.
   * [Nessa documentação](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values) poderá ver alternativas do que passar no id para que as sugestões do autocomplete estejam de acordo com o desejado.
   * Você pode também utilizar o "off" para que não seja exibida as sugestões do autocomplete.
   * @values off
   */
  @Prop({ type: String, required: false, default: 'on' })
  readonly autocomplete?: string;

  /**
   * Se não passado nenhum id, ele será gerado aleatóriamente.
   */
  @Prop({ type: String, required: false })
  readonly id?: string;

  /**
   * Caso o usuário deseje passar uma máscara para o InputFoundation.
   */
  @Prop({ type: [String, Array], required: false })
  readonly mask?: string | string[];

  /**
   * Indica o status do input e muda sua aparência
   * @values default, error, success
   */
  @Prop({
    type: String,
    default: InputStatusType.Default
  })
  readonly statusType!: InputStatusType;

  /**
   * Caso não tenha um label visível é possível informar através dessa prop
   * um label invisível para dar contexto as tecnologias assistivas
   */
  @Prop({ type: String, required: false })
  readonly ariaLabel?: string;

  /**
   * Tamanhos para input
   * @values small, regular
   */
  @Prop({ type: String, default: InputSizes.REGULAR })
  readonly size!: string;

  private initialLabelPosition = 0;

  private observer?: IntersectionObserver;

  @Emit('input')
  public input(value: string | number) {
    return value;
  }

  mounted() {
    this.setInitialLabelPosition();
    if (this.observer) this.observer.observe(this.$el);
  }

  updated() {
    this.setInitialLabelPosition();
  }

  created() {
    if (typeof IntersectionObserver !== 'undefined') {
      this.observer = new IntersectionObserver(this.setInitialLabelPosition, {
        root: document.documentElement,
        threshold: 0
      });
    }
  }

  destroy() {
    if (this.observer) this.observer.unobserve(this.$el);
  }

  get hasErrorStatusType() {
    return this.statusType === InputStatusType.Error;
  }

  private setInitialLabelPosition() {
    if (!this.fieldset || !window) return;

    const fieldsetPadding = window.getComputedStyle(this.fieldset).paddingLeft;
    const leadingSlotWidth = this.leadingSlot
      ? this.leadingSlot.clientWidth
      : 0;
    this.initialLabelPosition = parseInt(fieldsetPadding) + leadingSlotWidth;
  }

  private get computedStatusClass() {
    let classes = 'default';
    if (this.disabled) classes = 'disabled';
    else if (this.readonly) classes = 'readonly';
    else if (this.statusType) classes = this.statusType;
    return `input-full-area--${classes}`;
  }

  private get computedPlaceholderClass() {
    return this.placeholder ? `input-with-placeholder` : '';
  }

  private get computedWithValueClass() {
    const classes = 'value';
    if (this.value) return `input-with-value--${classes}`;
    return classes;
  }

  get computedInputSizeClass() {
    if (this.size === 'small') return 'input-wrapper--small';

    return 'input-wrapper--regular';
  }

  private get computedLeadingSlotClass() {
    if (this.$slots['leading-slot']) {
      return 'input-with-leading-slot';
    }
    return 'input-without-leading-slot';
  }

  private get computedInputFilled() {
    const classes = {
      'input-filled': this.hasContent
    };
    return classes;
  }

  private get computedWithoutLabel() {
    return this.label ? `input-with-label` : `input-without-label`;
  }

  private get computedId() {
    if (this.id) return this.id;
    const randomId = Math.random().toString(16).slice(2);
    return `input-id-${randomId}`;
  }

  private get hasContent() {
    return Boolean(this.value) || this.value === 0;
  }

  private get computedInputListeners() {
    return {
      ...this.$listeners,
      input: (event: Event) => {
        const target = event.target as HTMLInputElement;
        this.input(target.value);
      }
    };
  }
}
</script>

<style lang="less" scoped>
*[loki-test] .input-wrapper {
  border: 0px !important;
}

.label-float-position {
  left: calc(@size-spacing-x400 - @size-spacing-x75) !important;
  top: -1em;
  background: @background-secondary;
  padding: @size-spacing-x150 @size-spacing-x150;
  .text-p-6();
}

.input-container {
  position: relative;
  display: block;
  text-align: left;
}
.input-full-area {
  &--default {
    color: @text-placeholder;
    border-color: @element-primary;
    .normal-label,
    .float-label {
      color: @text-placeholder;
    }
    .bottom-helper {
      color: @text-secondary;
    }
  }
  &--error {
    .input-wrapper {
      border-color: @element-on-error !important;
    }
    .normal-label,
    .float-label,
    .bottom-helper {
      color: @text-on-error !important;
    }
  }

  &--success {
    .input-wrapper {
      border-color: @element-on-success !important;
    }
    .normal-label,
    .float-label,
    .bottom-helper {
      color: @text-on-success !important;
    }
  }

  &--disabled {
    color: @text-disabled;
    .input-wrapper {
      border-color: @element-disabled !important;
    }
    .normal-label,
    .float-label,
    .bottom-helper,
    input,
    .input-with-value--value {
      color: @text-disabled !important;
    }
  }

  &--readonly {
    color: @text-disabled;
    .input-wrapper {
      border-color: @element-disabled !important;
      padding: 1px @size-spacing-x400;
      &:focus-within {
        color: @text-disabled;
        border: @size-border-x400 solid @element-primary;
        padding: 1px @size-spacing-x400;
      }
    }
    .normal-label {
      .label-float-position();
    }
    .normal-label,
    .float-label,
    .bottom-helper,
    input,
    .input-with-value--value {
      color: @text-disabled !important;
    }
  }
}

.input-wrapper {
  //Área referente ao container do input; Todos os elementos dentro do escopo do fieldset (com excessão de input-filled, normal-label e float-label).
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: @size-border-x400 solid @element-placeholder;
  border-radius: @size-radius-x300;
  padding: 1px calc(@size-spacing-x400 + 1px);
  margin: 0;
  box-sizing: border-box;

  &--regular {
    height: 64px;
  }

  &--small {
    height: 48px;
  }

  legend {
    padding: 0;
    height: 0;
    width: 0;
    visibility: hidden;
    display: block;
  }

  input {
    height: 100%;
    width: 100%;
    flex-grow: 1;
    background: transparent;
    border: @size-border-x400;
    outline: none;
    padding: 0;
    position: relative;
    z-index: 1;
    color: currentColor;

    &:focus-within {
      color: @text-primary;
    }
  }

  &:hover {
    border: @size-border-x400 solid @element-primary;
  }

  &:focus-within {
    color: @text-primary;
    border: @size-border-x400 solid @element-primary;
    padding: 0px @size-spacing-x400;
    .normal-label {
      .label-float-position();
      color: @text-primary;
    }
  }
}

.leading-icon {
  padding-right: @size-spacing-x350 !important;
}

.trailing-icon {
  margin-left: @size-spacing-x350 !important;
}

.trailing-icon-selectable {
  cursor: pointer;
  transition: 0.2s ease-in all;
}

.normal-label {
  position: absolute;
  margin-left: 0px;
  color: @text-placeholder;
  transition: 0.2s ease;
  top: 20px;
  z-index: 1;
}

.input-with-leading-slot {
  &.input-filled,
  &:focus-within {
    .normal-label {
      .label-float-position();
    }
  }
}
.input-without-leading-slot {
  &.input-filled,
  &:focus-within {
    .normal-label {
      .label-float-position();
    }
  }
}

.input-filled {
  .normal-label {
    .label-float-position();
    color: @text-placeholder;
  }
}

.input-with-value {
  &--value {
    color: @text-primary;
    .input-wrapper {
      border-color: @element-placeholder !important;
      padding: 1px calc(@size-spacing-x400);
      &:focus-within {
        border: @size-border-x500 solid @element-primary !important;
        padding: 0px @size-spacing-x400;
      }
    }
    .normal-label {
      .label-float-position();
    }
  }
}

.input-with-placeholder {
  &.input-with-label input::placeholder {
    opacity: 0;
  }
  &:focus-within input::placeholder {
    opacity: 1 !important;
    color: @text-placeholder;
  }
}

.bottom-helper {
  text-align: left;
  position: relative;
  margin-top: @size-spacing-x300;
  color: @text-secondary;
}

input:-webkit-autofill,
input:-webkit-autofill:first-line,
input:-webkit-autofill:focus-visible {
  -webkit-box-shadow: 0 0 0px 1000px @background-secondary inset;
  box-shadow: 0 0 0px 1000px @background-secondary inset;
  color: @element-primary !important;
}
</style>
