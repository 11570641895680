<template>
  <tr
    class="table-row"
    :class="classNames"
    v-on="$listeners"
    @click="onRowClick"
  >
    <component
      :is="getComponentName(column)"
      v-for="(column, index) in columns"
      v-bind="parseColumn(column)"
      :key="index"
      :index="index"
      :is-child="isChild"
      :disabled="disabled || column.disabled"
      v-on="$listeners"
    />
    <slot />
  </tr>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  InjectReactive,
  Provide,
  Prop,
  Vue
} from 'vue-property-decorator';

import { Column, DataTableStore } from './DataTableProps';
import ActionsCell from './TableCells/ActionsCell.vue';
import CheckboxCell from './TableCells/CheckboxCell.vue';
import CheckboxHeaderCell from './TableCells/CheckboxHeaderCell.vue';
import DefaultCell from './TableCells/DefaultCell.vue';
import HeaderCell from './TableCells/HeaderCell.vue';
import LoadingCell from './TableCells/LoadingCell.vue';
import ToggleCell from './TableCells/ToggleCell.vue';
import ButtonCell from './TableCells/ButtonCell.vue';
import IconCell from './TableCells/IconCell.vue';

@Component({
  name: 'TableRow'
})
export default class TableRow extends Vue {
  @InjectReactive('dataTableStore')
  private readonly dataTableStore!: DataTableStore;

  @Prop({ type: Boolean, default: false, required: false })
  private active!: boolean;

  @Prop({ type: Array, required: false, default: () => [] })
  private columns!: Column[];

  @Prop({ required: false, type: Boolean, default: false })
  private isChild!: boolean;

  @Prop({ type: Boolean, default: false, required: false })
  private isContent!: boolean;

  @Prop({ type: Boolean, default: false, required: false })
  private isHeader!: boolean;

  @Prop({ type: Boolean, default: false, required: false })
  private isLoading!: boolean;

  @Prop({ type: Boolean, default: false, required: false })
  private showDivider!: boolean;

  @Prop({ type: Boolean, default: false })
  private hasInteraction!: boolean;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  @Provide('disabled')
  private provideDisabled = this.disabled;

  @Emit('on-row-click')
  emitRowClick() {
    if (this.columns.length !== 0) {
      return this.dataTableStore.data.find(
        row => row._hash === this.columns[0]._hash
      );
    }
  }

  public onRowClick() {
    if (this.disabled) return;
    this.emitRowClick();
  }

  private get classNames() {
    return {
      'is-active': this.active,
      'not-hover': this.isContent || this.isLoading,
      'show-divider': !this.isLoading && this.showDivider,
      'has-interaction': this.disabled ? false : this.hasInteraction,
      'is-disabled': this.disabled
    };
  }

  private getComponentName({ type, component }: Column) {
    const isCheckboxType = type === 'checkbox';
    if (this.isHeader) {
      if (isCheckboxType && this.isChild) return CheckboxHeaderCell;
      return HeaderCell;
    }
    if (this.isLoading) return LoadingCell;
    if (component) return component;
    if (type === 'actions') return ActionsCell;
    if (isCheckboxType) return CheckboxCell;
    if (type === 'child') return ToggleCell;
    if (type === 'button') return ButtonCell;
    if (type === 'icon') return IconCell;
    return DefaultCell;
  }

  private parseColumn({ ...column }: Column) {
    return column;
  }
}
</script>

<style lang="less" scoped>
.table-row {
  &:not(.is-active).show-divider {
    > td {
      border-bottom: @size-border-x400 solid @divider-primary;
    }
  }
  &.has-interaction {
    &:not(.not-hover):hover {
      > td {
        background-color: @background-hover;
      }
    }
    &:hover {
      cursor: pointer;
    }
    &:active {
      opacity: 0.2;
    }
  }
  &.is-disabled {
    &:hover {
      cursor: default;
      td {
        background-color: @background-secondary;
      }
    }
  }
}
</style>
