<template>
  <InputFoundation
    :value="value"
    data-testid="input-pin"
    class="input-pin-wrapper"
    :disabled="disabled"
    :placeholder="placeholder"
    :required="required"
    :status-type="statusType"
    type="tel"
    :maxlength="1"
    @keypress="handleKeypress"
    v-on="inputListeners"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import InputFoundation from '@/foundation/input/InputFoundation.vue';
import { InputStatusType } from '@/foundation/types';

@Component({
  name: 'InputPin',
  components: {
    InputFoundation
  }
})
export default class InputPin extends Vue {
  /**
   * Valor inicial do InputPin
   */
  @Prop({ type: String, required: false, default: '' })
  private value!: string;

  /**
  /**
   * Caso o InputPin esteja desativado
   */
  @Prop({ type: Boolean, required: false, default: false })
  private disabled!: boolean;

  /**
   * O texto exibido dentro do InputPin, quando não há valor digitado
   */
  @Prop({ type: String, required: false })
  private placeholder?: string;

  /**
   * O atributo usado para indicar que a entrada do usuário é obrigatória.
   */
  @Prop({ type: Boolean, required: false, default: false })
  private required?: boolean;

  /**
   * O atributo utilizado para demonstrar feedback de status: default, error ou success
   */
  @Prop({ type: String, required: false, default: 'default' })
  private statusType?: InputStatusType;

  /**
   * Regex de números
   */
  private numbersRegex = /^\d$/i;

  /**
   * Retorna o valor atual do InputPin quando é valido
   */
  @Emit()
  public input(value: string) {
    return value;
  }

  /**
   * Função que evita que o usuário digite caracteres que não sejam números
   */
  handleKeypress(event: KeyboardEvent) {
    if (!this.isANumber(event.key)) {
      event.preventDefault();
    }
  }

  /**
   * Função que testa se o valor digitado é um número ou não
   */
  private isANumber(value: string): boolean {
    return this.numbersRegex.test(value);
  }

  private get inputListeners() {
    return {
      ...this.$listeners,
      input: this.input
    };
  }
}
</script>

<style lang="less" scoped>
.input-pin-wrapper {
  width: 48px;
  ::v-deep input {
    .text-p-3() !important;
    text-align: center;
  }
}
</style>
