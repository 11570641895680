<template>
  <InitialAvatarFoundation
    :size="size"
    :border-radius="borderRadius"
    class="initial-avatar-icon"
  >
    <BaseIcon
      :icon="icon"
      :width="computedIconSize"
      :height="computedIconSize"
    />
  </InitialAvatarFoundation>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import InitialAvatarFoundation from '@/foundation/initial-avatar/InitialAvatarFoundation.vue';
import {
  InitialAvatarSizes,
  InitialAvatarBorderRadius
} from '@/foundation/types';

@Component({
  name: 'InitialAvatarIcon',
  components: { InitialAvatarFoundation, BaseIcon }
})
export default class InitialAvatarIcon extends Vue {
  /**
   * O ícone a ser exibido
   */
  @Prop({ type: String, required: true })
  readonly icon!: string;

  /**
   * Define o tamanho do InitialAvatar
   * @values extra-small, small, medium, large
   */
  @Prop({ type: String, default: InitialAvatarSizes.EXTRA_SMALL })
  readonly size!: string;

  /**
   * Define o border radius do InitialAvatar
   * @values none, extra-small, small, medium, large, extra-large, max
   */
  @Prop({ type: String, default: InitialAvatarBorderRadius.MAX })
  readonly borderRadius!: string;

  get computedIconSize() {
    return this.size === InitialAvatarSizes.MEDIUM ||
      this.size === InitialAvatarSizes.LARGE
      ? '24'
      : '20';
  }
}
</script>

<style lang="less" scoped>
.initial-avatar-icon {
  color: @element-primary;
  background-color: @background-tertiary;
}
</style>
