<template>
  <footer v-if="footerLabel" :class="computedFooterClass">
    <ButtonSecondary
      v-if="shouldShowAsButtonSecondary"
      class="footer-button"
      :label="footerLabel"
      :href="footerHref"
      :disabled="footerDisabled"
      :loading="footerLoading"
      @click="footerEvent"
    />
    <Button
      v-else-if="shouldShowButtonAsButton"
      class="footer-button"
      :colors="ButtonColors"
      :href="footerHref"
      @click="footerEvent"
    >
      <div class="footer-button__inner">
        <BaseText size="sm">
          {{ footerLabel }}
        </BaseText>
        <BaseIcon :icon="computedButtonIcon" />
      </div>
    </Button>
    <BaseText v-else size="xs" :color="NebraskaColors.textSecondary">
      {{ footerLabel }}
    </BaseText>
  </footer>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { BaseText } from '@/components/base-text';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { ButtonSecondary } from '@/components/button';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import Button from '@/foundation/button/Button.vue';

import { FooterTypes, ContentAspect } from '@/components/content-display/types';

@Component({
  name: 'FooterDisplay',
  components: {
    BaseText,
    ButtonSecondary,
    Button,
    BaseIcon
  }
})
export default class FooterDisplay extends Vue {
  /**
   * Define o tipo do botão a ser exibido no footer
   * @values caption, link, navigation ou button
   * @defaults navigation
   */
  @Prop({ type: String, default: FooterTypes.NAVIGATION })
  readonly footerType!: string;

  /**
   * Label a ser exibida no button ou caption
   */
  @Prop({ type: String, required: false })
  readonly footerLabel?: string;

  /**
   * Informa se o botão (quando houver) deve estar desabilitado
   */
  @Prop({ type: Boolean, default: false })
  readonly footerDisabled!: boolean;

  /**
   * Informa o href para o button caso a navegação seja via url
   */
  @Prop({ type: String, required: false })
  readonly footerHref?: string;

  /**
   * Informa se o estado do botão deve ser de loading
   */
  @Prop({ type: Boolean, default: false })
  readonly footerLoading!: boolean;

  @Prop({ type: String, default: ContentAspect.INSIDE })
  readonly footerContentAspect!: string;

  /**
   * Evento de click do elemento do footer, quando existente
   */
  @Emit('click')
  private footerEvent() {
    // event emitter on button footer click
  }

  readonly NebraskaColors = NebraskaColors;

  readonly ButtonColors = {
    background: NebraskaColors.backgroundSecondary,
    content: NebraskaColors.textPrimary,
    alphas: {
      default: 1,
      hover: 0.9,
      active: 0.8
    }
  };

  public get shouldShowAsButtonSecondary() {
    return this.footerType === FooterTypes.BUTTON;
  }

  public get shouldShowButtonAsButton() {
    return (
      this.footerType === FooterTypes.LINK ||
      this.footerType === FooterTypes.NAVIGATION
    );
  }

  public get shouldShowAsCaption() {
    return this.footerType === FooterTypes.CAPTION;
  }

  public get computedButtonIcon() {
    return this.footerType === FooterTypes.LINK ? 'EA0080' : 'EA0170';
  }

  public get computedFooterClass() {
    return [
      {
        'footer-spacing': !this.shouldShowAsButtonSecondary,
        'footer-caption': this.shouldShowAsCaption
      },
      `footer__${this.footerContentAspect}`
    ];
  }
}
</script>

<style lang="less" scoped>
footer {
  border-top: @size-border-x400 solid @divider-primary;
  padding: @size-spacing-x150 @size-spacing-x300;

  .footer-button {
    width: 100%;
    font-weight: bold;

    &__inner {
      display: flex;
      justify-content: space-between;

      ::v-deep p {
        font-weight: bold;
      }
    }
  }

  &.footer-spacing {
    padding: @size-spacing-x400 @size-spacing-x600;
  }

  &.footer-caption {
    border-top: 0;
    background-color: @background-primary;
  }

  &.footer__outside {
    margin: @size-spacing-x400 @size-spacing-x900 @size-spacing-x800;

    &:not(.footer-caption) {
      padding: @size-spacing-x400 0 0;
    }
  }
}
</style>
