<template>
  <div
    data-testid="navbar-container"
    class="navbar-container navbar-container--sticky"
  >
    <div class="navbar-container__side navbar-container__left">
      <slot name="left" />
    </div>
    <div class="navbar-container__center">
      <div ref="filter" class="filters">
        <div
          v-for="(filter, index) in filters"
          :key="index"
          :data-testid="filter.key"
          :class="computedClassFilter(filter)"
          @click="handleOpenFilter(filter)"
        >
          <div class="filters__filter__container">
            <div class="filters__filter__container__title">
              {{ filter.title }}
            </div>
            <div class="filters__filter__container__selected">
              {{ filter.selected.title }}
            </div>
          </div>
          <BaseIcon
            class="icon"
            icon="EA0165"
            :colors="NebraskaColors.elementSecondary"
          />

          <ul
            v-if="openedFilter === filter.key"
            class="filters__filter__dropdown"
            data-testid="dropdown"
          >
            <li
              v-for="item in filter.options"
              :key="item.key"
              :data-testid="item.key"
              :class="computedClassOption(filter, item)"
              @click="handleSelectOption(filter, item)"
            >
              <div class="content">
                <div>{{ item.title }}</div>
                <div class="description-content">
                  <div
                    v-if="item.descriptionFirstValue"
                    :class="`description-content__right-value ${item.statusDescriptionSecondValue}`"
                  >
                    {{ item.descriptionFirstValue }}
                  </div>
                  <div
                    v-if="item.descriptionSecondValue"
                    class="description-content__center-value"
                  >
                    {{ item.descriptionSecondValue }}
                  </div>
                </div>
              </div>
              <BaseIcon
                class="check-icon"
                icon="EF0070"
                :colors="NebraskaColors.elementPrimary"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container__side navbar-container__right">
      <slot name="right" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import NavBar from '@/components/navbar/NavBar.vue';
import { BaseIcon } from '@/foundation';
import { IFilter, IOption } from './types';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';

@Component({
  name: 'NavbarFilter',
  components: {
    NavBar,
    BaseIcon
  }
})
export default class NavbarFilter extends Vue {
  readonly NebraskaColors = NebraskaColors;
  /**
   * Opções de filtros
   */
  @Prop({ type: Array, required: true })
  private filters!: IFilter[];

  /**
   * Filtro aberto
   */
  private openedFilter = '';

  /**
   * Evento disparado quando alguma seleção muda
   */
  @Emit('update')
  public update() {
    // evento disparado com update
  }

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  }

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  computedClassFilter(filter: IFilter) {
    return [
      'filters__filter',
      this.openedFilter === filter.key ? 'opened' : '',
      filter?.disabled ? 'disabled' : ''
    ];
  }

  computedClassOption(filter: IFilter, option: IOption) {
    return [
      this.filters.find(item => item.key === filter.key)!.selected.key ===
      option.key
        ? 'selected'
        : '',
      option?.disabled ? 'disabled' : ''
    ];
  }

  handleOpenFilter(filter: IFilter) {
    this.openedFilter = this.openedFilter === filter.key ? '' : filter.key;
  }

  handleSelectOption(filter: IFilter, option: IOption) {
    this.filters.find(item => item.key === filter.key)!.selected = option;
    this.$emit('update', this.filters);
  }

  handleClickOutside(event: MouseEvent) {
    if (
      this.openedFilter.length > 0 &&
      this.$refs.filter instanceof Element &&
      !this.$refs.filter.contains(event.target as Node)
    ) {
      this.openedFilter = '';
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/_medias';

.navbar-container {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: @element-primary;
  background-color: @background-secondary;
  padding: 0px @size-spacing-x500;
  border-bottom: @size-border-x400 solid @divider-primary;

  &--sticky {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 20;
  }

  .to-md ({
    height: 56px;
    padding: 0 @size-spacing-x350;
  });;
}

.navbar-container__side {
  flex: 1 0 24px;
  white-space: nowrap;
}

.navbar-container__left {
  text-align: left;
}

.navbar-container__right {
  text-align: right;
}

.navbar-container__center {
  width: 100%;
  max-width: 1008px;
  flex: 1 1 auto;
  padding: 0;
  text-align: center;
  .text-p-6();
  height: 100%;

  .filters {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    &__filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      padding: 0 @size-spacing-x500;
      cursor: pointer;
      position: relative;

      height: 100%;
      width: 100%;
      border-left: @size-border-x400 solid @divider-primary;

      &.disabled {
        pointer-events: none;
        cursor: none;
        color: @element-disabled;

        .filters__filter__container {
          &__title {
            color: @element-disabled;
          }
        }
      }

      &:last-child {
        border-right: @size-border-x400 solid @divider-primary;
      }

      .icon {
        transition: all 0.15s linear;
      }

      &.opened {
        .icon {
          rotate: 180deg;
        }
      }

      &__container {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        &__title {
          color: @element-secondary;
          font-weight: 400;
        }

        &__selected {
          font-weight: 600;
        }
      }

      &__dropdown {
        cursor: default;
        position: absolute;
        top: 100%;
        left: 0;
        margin: 0;
        margin-top: @size-spacing-x300;
        padding: 0;
        width: 100%;
        border-radius: @size-radius-x300;
        background-color: @background-secondary;

        box-shadow: @elevation-300;

        li {
          cursor: pointer;
          list-style: none;
          .text-p-5();

          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          height: 74px;
          padding: 0 @size-spacing-x500;
          border-bottom: @size-border-x400 solid @divider-primary;

          .content {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: center;
          }

          .check-icon {
            opacity: 0;
          }

          &:hover {
            background-color: @background-hover;

            &:first-child {
              border-top-left-radius: @size-radius-x300;
              border-top-right-radius: @size-radius-x300;
            }

            &:last-child {
              border-bottom-left-radius: @size-radius-x300;
              border-bottom-right-radius: @size-radius-x300;
            }
          }

          .description-content {
            display: flex;
            align-items: center;
            gap: @size-spacing-x400;

            .to-md({
              display: none;
            });

            &__center-value {
              .text-p-6();
              color: @element-secondary;
            }

            &__right-value {
              .text-p-6();
              color: @element-secondary;

              &.positive {
                color: @status-positive;
              }

              &.negative {
                color: @status-negative;
              }
            }
          }

          &.disabled {
            pointer-events: none;
            cursor: none;
            color: @element-disabled;

            .description-content__center-value,
            .description-content__right-value {
              color: @element-disabled;
            }
          }

          &.selected {
            background-color: @background-hover;

            &:first-child {
              border-top-left-radius: @size-radius-x300;
              border-top-right-radius: @size-radius-x300;
            }

            &:last-child {
              border-bottom-left-radius: @size-radius-x300;
              border-bottom-right-radius: @size-radius-x300;
            }

            .check-icon {
              opacity: 1;
            }
          }

          &:last-child {
            border-bottom: 0;
            border-bottom-left-radius: @size-radius-x300;
            border-bottom-right-radius: @size-radius-x300;
          }
        }
      }
    }
  }
}
</style>
