<template>
  <button
    :class="computedClassName"
    :disabled="disabled"
    type="button"
    @click="handleClick"
  >
    <slot v-if="isLoading" name="icon" />
    <slot v-else />
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * @deprecated
 */
@Component({
  name: 'Button'
})
export default class Button extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @Prop({ type: Function, default: () => {} })
  private onClick!: () => unknown;

  @Prop({ type: String, default: 'default' })
  private size!: string;

  @Prop({ type: Boolean, default: false })
  private isLoading!: boolean;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  private isRounded!: boolean;

  private get computedClassName() {
    const classNames = [this.size];
    if (this.disabled) classNames.push('button--disabled');
    if (this.isRounded) classNames.push('rounded');
    return classNames.join(' ');
  }

  private handleClick() {
    if (this.isLoading || this.disabled) return;

    this.onClick();
    this.$emit('click');
  }
}
</script>

<style lang="less" scoped>
@active-scale: 0.95;
@hover-focus-scale: 1.05;

button {
  cursor: pointer;
  background-color: @theme-primary;
  color: @theme-over-primary;
  border: none;
  border-radius: @size-radius-max;
  transition: 0.2s cubic-bezier(0.55, 0, 0.1, 1);

  &.button--disabled {
    background-color: @base-disabled;
    color: @base-over-disabled;
    cursor: default;

    &:hover {
      transform: none;
      filter: none;
    }
  }

  &:focus,
  &:hover {
    transform: scale(@hover-focus-scale);
  }

  &:active {
    transform: scale(@active-scale);
  }
}

.xs {
  padding: @size-spacing-x300 @size-spacing-x400;
  min-width: 84px;
  min-height: 18px;
}

.sm {
  padding: @size-spacing-x300 @size-spacing-x500;
  min-width: 115px;
  min-height: 40px;
}

.md {
  padding: @size-spacing-x500 @size-spacing-x600;
  min-width: 131px;
  min-height: 72px;
}

.lg {
  padding: @size-spacing-x600 @size-spacing-x600;
  min-width: 131px;
  min-height: 88px;
}

.default {
  border-radius: 0;
  padding: @size-spacing-x500 @size-spacing-x600;
  min-width: 140px;
  min-height: 72px;
}

.rounded {
  border-radius: @size-radius-x200;
}
</style>
