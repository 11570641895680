<template>
  <BaseCell class="actions-cell" v-bind="$attrs" @click.stop="disarm">
    <ButtonIconSecondary
      data-testid="icon-button"
      class="actions-cell__icon"
      size="small"
      icon="ic_dots_vertical"
      label="Abrir menu opções"
      :disabled="isDisabled"
      @click.stop.prevent="toggle"
    />
    <div class="actions-cell__disarm" :class="handleActionBoxActiveClass" />
    <ul
      class="actions-cell__options text-p-5"
      data-testid="ul-actions-cell"
      :class="handleActionBoxActiveClass"
      :style="positionListStyles"
    >
      <li v-for="(item, key) of options" :key="key">
        <a v-if="item.href" v-bind="getActionProps(item)">
          {{ item.text }}
        </a>
        <button
          v-else
          v-bind="getActionProps(item)"
          type="button"
          @click="item.action"
        >
          {{ item.text }}
        </button>
      </li>
    </ul>
  </BaseCell>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import ButtonIconSecondary from '@/components/button-icon/button-icon-secondary/ButtonIconSecondary.vue';
import BaseCell from './BaseCell.vue';
import { Action } from '../DataTableProps';
import { PageTableOverflow } from './interfaces';

@Component({
  name: 'ActionsCell',
  components: { BaseCell, ButtonIconSecondary }
})
export default class ActionsCell extends Vue {
  @Prop({ type: Array, default: () => [] })
  private options!: Action[];

  private isActionBoxActive = false;

  private topPositionActionBox = 0;

  private leftPositionActionBox = 0;

  private get positionListStyles(): Partial<CSSStyleDeclaration> {
    return {
      top: `${this.topPositionActionBox}px`,
      left: `${this.leftPositionActionBox}px`
    };
  }

  @Watch('isActionBoxActive')
  private handlePageOverflow(isActive: boolean) {
    if (isActive) this.changePageOverflow(PageTableOverflow.HIDDEN);
    else this.changePageOverflow();
  }

  private get iconColors() {
    if (this.isDisabled) return NebraskaColors.elementDisabled;
    return NebraskaColors.elementPrimary;
  }

  private get handleActionBoxActiveClass() {
    if (this.isActionBoxActive) return 'is-active';
    return '';
  }

  private get isDisabled() {
    return this.options.length === 0;
  }

  private changePageOverflow(
    styleOverflow: PageTableOverflow = PageTableOverflow.AUTO
  ): void {
    document.body.style.overflow = styleOverflow;
  }

  private getActionProps({ ...item }: Action) {
    return item;
  }

  private disarm() {
    if (this.isDisabled) return;
    this.isActionBoxActive = false;
  }

  private beforeDestroy() {
    this.changePageOverflow();
  }

  private sizeActionBox(): number {
    const ACTION_BOX_OPTIONS_LINE_HEIGHT = 47;
    const ACTION_BOX_HEIGHT = 32;

    return (
      ACTION_BOX_OPTIONS_LINE_HEIGHT * this.options.length + ACTION_BOX_HEIGHT
    );
  }

  private isPageBoundary(
    elementPositionTop: number,
    boxHeight: number
  ): boolean {
    return elementPositionTop + boxHeight > window.innerHeight;
  }

  private toggle(event: Event) {
    if (this.isDisabled || !event || !event.target) return;
    const elemet = event.target as HTMLElement;

    const elementPositionTop = elemet.getBoundingClientRect().top;
    const actionBoxHeight = this.sizeActionBox();
    this.leftPositionActionBox = elemet.getBoundingClientRect().left;

    if (this.isPageBoundary(elementPositionTop, actionBoxHeight)) {
      this.topPositionActionBox = elementPositionTop - actionBoxHeight;
    } else {
      this.topPositionActionBox = elementPositionTop;
    }

    if (this.isDisabled) return;
    this.isActionBoxActive = !this.isActionBoxActive;
  }
}
</script>

<style lang="less" scoped>
::v-deep .actions-cell {
  &__icon {
    &:not(.disabled) {
      cursor: pointer;
    }
    > * {
      display: block;
      transform: rotate(90deg);
    }
  }
}

.actions-cell {
  padding: 0;
  width: 40px;
  position: relative;
  &__disarm {
    display: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: @background-overlay;
    &.is-active {
      display: block;
    }
  }
  &__options {
    display: none;
    position: fixed;
    text-align: left;
    z-index: 2;
    background-color: @element-negative;
    border: @size-border-x400 solid @divider-primary;
    border-radius: @size-radius-x200;
    padding: @size-spacing-x300 0;
    min-width: 170px;
    transform: translateX(-100%);
    top: @size-spacing-x600;

    li {
      list-style: none;
      padding: 0;
      &:before {
        content: '';
        display: block;
        border-bottom: @size-border-x400 solid @divider-primary;
        width: calc(100% - @size-spacing-x400);
        margin-left: @size-spacing-x400;
      }
      &:first-of-type {
        &:before {
          border-bottom: none;
        }
      }
      > * {
        display: block;
        cursor: pointer;
        text-align: left;
        padding: @size-spacing-x400;
        transition: background-color ease 0.3s;
        color: @text-primary;
        appearance: none;
        background: transparent;
        border: none;
        outline: none;
        width: 100%;
        &:hover {
          background-color: @background-hover;
        }
      }
    }
    &.is-active {
      display: block;
    }
  }
}
</style>
