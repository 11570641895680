<script lang="ts">
import { CreateElement } from 'vue';
import { Component, Vue, Inject } from 'vue-property-decorator';
import { TabsProps } from './TabsProps';

@Component({
  name: 'TabPanels'
})
export default class TabPanels extends Vue {
  @Inject('tabsProps')
  private readonly tabsProps!: TabsProps;

  public render(createElement: CreateElement) {
    const { selectedIndex } = this.tabsProps;

    const children = this.$slots
      .default!.filter(vNode => vNode.tag && vNode.componentOptions)
      .map((vNode, index) => {
        if (vNode.componentOptions) {
          return createElement(
            vNode.componentOptions.Ctor,
            {
              props: {
                actived: selectedIndex === index
              }
            },
            vNode.componentOptions.children
          );
        } else {
          return vNode;
        }
      });

    return createElement('div', children);
  }
}
</script>
