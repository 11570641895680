<template>
  <div class="feedback-message">
    <BaseText
      class="feedback-message__title"
      size="lg"
      :color="NebraskaColors.textPrimary"
    >
      {{ title }}
    </BaseText>
    <BaseText
      class="feedback-message__description"
      size="sm"
      :color="NebraskaColors.textSecondary"
    >
      {{ description }}
    </BaseText>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseText from '@/components/base-text/BaseText.vue';

@Component({
  name: 'DataTableFeedbackMessage',
  components: { BaseText }
})
export default class DataTableFeedbackMessage extends Vue {
  @Prop({
    required: false,
    type: String,
    default: 'Nenhum dado pode ser mostrado'
  })
  readonly title!: string;

  @Prop({
    required: false,
    type: String,
    default: 'Isso pode ter ocorrido devido à um erro'
  })
  readonly description!: string;

  private get NebraskaColors() {
    return NebraskaColors;
  }
}
</script>

<style lang="less" scoped>
.feedback-message {
  padding: @size-spacing-x500;
  text-align: center;
  &__title {
    font-weight: bold;
  }
}
</style>
