<template>
  <InputFoundation
    :id="id"
    v-model.number="computedValue"
    :mask="mask"
    role="spinbutton"
    data-testid="input-number"
    type="tel"
    :label="label"
    :disabled="disabled"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :status-type="statusType"
    v-bind="inputAttr"
    :required="required"
    :aria-valuenow="value"
    :aria-valuemin="minValue"
    :aria-valuemax="maxValue"
    @keydown.up="increaseValue"
    @keydown.down="decreaseValue"
    v-on="inputListeners"
    @keypress="handleKeypress"
  >
    <template #leading-slot>
      <BaseIcon v-if="leadingIcon" :icon="leadingIcon" colors="currentColor" />
    </template>
    <template #trailing-slot>
      <IncreaseDecreaseButton
        class="increase-decrease"
        :disabled="isIncreaseDecreaseDisabled"
        @increase="increaseValue"
        @decrease="decreaseValue"
      />
    </template>
    <template #bottom-slot>
      <HelperText
        v-if="helperText"
        :id="helperTextId"
        :text="helperText"
        :status-type="statusType"
      />
    </template>
  </InputFoundation>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { HelperText } from '@/components/helper-text';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import InputFoundation from '@/foundation/input/InputFoundation.vue';
import {
  DisabledStatus,
  IncreaseDecreaseButton
} from '@/components/increase-decrease-button';

@Component({
  name: 'InputNumber',
  components: {
    InputFoundation,
    BaseIcon,
    IncreaseDecreaseButton,
    HelperText
  }
})
export default class InputNumber extends Vue {
  /**
   * Incrementa ao valor do input
   */

  @Prop({ type: Number, default: 1 })
  public incrementBy!: number;

  /**
   * Rótulo (label) exibido no input.
   */
  @Prop({ type: String, required: false })
  private label?: string;

  /**
   * Valor inicial do input
   */

  @Prop({ type: Number, required: false })
  private value!: number;

  /**
   * Valor mínimo do input
   */
  @Prop({ type: Number, required: false, default: Number.MIN_SAFE_INTEGER })
  private minValue!: number;

  /**
   * Valor máximo do input
   */
  @Prop({ type: Number, required: false, default: Number.MAX_SAFE_INTEGER })
  private maxValue!: number;

  /**
   * Caso o usuário deseje passar uma máscara para o InputNumber. A máscara impede que alguns caracteres especiais não cobertos pelo regex de só numeros sejam digitados no Mac. No windows esse problema não acontece.
   */
  @Prop({ type: [String, Array], default: '###############' })
  private mask?: string | string[];

  /**
   * Caso o input esteja desativado
   */
  @Prop({ type: Boolean, required: false, default: false })
  private disabled!: boolean;

  /**
   * O texto exibido dentro do input, quando não há valor digitado
   */
  @Prop({ type: String, required: false })
  private placeholder?: string;

  /**
   * A mensagem de apoio exibida abaixo do input utilizado dentro do bottomSlot.
   * É possível passar outro tipo de elemento dentro também.
   */
  @Prop({ type: String, required: false })
  private helperText?: string;

  /**
   * Indica o status do input e muda sua aparência. Tanto na borda do Input quanto na cor dos ícones.
   * @values default, error, success
   */
  @Prop({ type: String })
  private statusType!: string;

  /**
   * O ícone exibido à esquerda dentro do input utilizado dentro do leadingSlot.
   * É possível passar outro tipo de elemento dentro também.
   */
  @Prop({ type: String, required: false })
  private leadingIcon?: string;

  /**
   * Indica que a entrada do usuário é obrigatória.
   */
  @Prop({ type: Boolean, required: false, default: false })
  private required!: boolean;

  /**
   * O id está relacionado diretamente com o autocomplete, se você optar por não passar um id, consequentemente não irá funcionar o autocomplete.
   * [Nessa documentação](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values) poderá ver alternativas do que passar no id para que as sugestões do autocomplete estejam de acordo com o desejado.
   * Você pode também utilizar o "off" para que não seja exibida as sugestões do autocomplete.
   * @values off
   */
  @Prop({ type: String, required: false, default: 'on' })
  private autocomplete?: string;

  /**
   * Se não passado nenhum id, ele será gerado aleatóriamente.
   */
  @Prop({ type: String, required: false })
  private id?: string;

  /**
   * Regex de números
   */
  private numbersRegex = /^\d$/i;

  private NebraskaColors = NebraskaColors;

  get helperTextId() {
    const randomId = Math.random().toString(16).slice(2);
    return `helper-text-${randomId}`;
  }

  private get isIncreaseDecreaseDisabled(): DisabledStatus {
    if (this.disabled) {
      return DisabledStatus.ALL;
    }
    if (this.computedValue >= this.maxValue) {
      return DisabledStatus.INCREASE;
    }
    if (this.computedValue <= this.minValue) {
      return DisabledStatus.DECREASE;
    }
    return DisabledStatus.NONE;
  }

  get inputAttr() {
    return this.helperText ? { 'aria-describedby': this.helperTextId } : '';
  }

  increaseValue() {
    if (this.value) {
      this.computedValue = this.validateValue(this.value + this.incrementBy);
    } else {
      this.computedValue = this.validateValue(this.incrementBy);
    }
  }

  decreaseValue() {
    if (this.value) {
      this.computedValue = this.validateValue(this.value - this.incrementBy);
    } else {
      this.computedValue = this.validateValue(-this.incrementBy);
    }
  }

  private validateValue(value: number): number {
    return Math.max(this.minValue, Math.min(this.maxValue, value));
  }

  /**
   * Retorna o valor atual do input, a cada mudança
   */
  @Emit('input')
  public input(value: number) {
    return value;
  }

  private get computedValue() {
    return this.value;
  }

  private set computedValue(value) {
    this.input(value);
  }

  /**
   * Função que evita que o usuário digite caracteres que não sejam números
   */
  handleKeypress(event: KeyboardEvent) {
    if (!this.isANumber(event.key)) {
      event.preventDefault();
    }
  }

  /**
   * Função que testa se o valor digitado é um número ou não
   */
  private isANumber(value: string): boolean {
    return this.numbersRegex.test(value);
  }

  private get inputListeners() {
    return {
      ...this.$listeners,
      input: this.input
    };
  }
}
</script>
