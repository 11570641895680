<template>
  <component
    :is="componentName"
    class="cell"
    :class="classNames"
    :style="styles"
    v-on="$listeners"
  >
    <slot />
    {{ text }}
  </component>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Align } from '../DataTableProps';

@Component({
  name: 'BaseCell'
})
export default class BaseCell extends Vue {
  @Prop({ type: String, default: Align.Left })
  private align!: Align;

  @Prop({ type: Boolean, default: false, required: false })
  private isHeader!: boolean;

  @Prop({ type: [String, Number], default: '' })
  private text!: string | number;

  @Prop({ type: [String, Number], default: '' })
  private width!: string | number;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  private get componentName() {
    return this.isHeader ? 'th' : 'td';
  }

  private get classNames() {
    const align = this.$attrs.align || this.align;
    const alignClassName = `cell__align-${align}`;
    const isDisabled = this.disabled ? 'is-disabled' : '';
    return [alignClassName, isDisabled];
  }

  private get styles() {
    if (typeof this.width === 'number') return { width: `${this.width}px` };
    return { width: this.width };
  }
}
</script>

<style lang="less" scoped>
.cell {
  box-sizing: border-box;
  padding: @size-spacing-x350 @size-spacing-x400;
  &:first-of-type {
    border-top-left-radius: @size-radius-x200;
    border-bottom-left-radius: @size-radius-x200;
  }
  &:last-child {
    border-top-right-radius: @size-radius-x200;
    border-bottom-right-radius: @size-radius-x200;
  }
  &__align-left {
    text-align: left;
  }
  &__align-right {
    text-align: right;
  }

  [type='checkbox'] + & {
    padding-left: @size-spacing-x300 !important;
  }
}
.is-disabled {
  /deep/ p {
    color: @element-disabled !important;
  }
}
</style>
