<template>
  <component :is="getComponent" class="checkbox-header-cell">
    <Checkbox
      :checked="checked"
      :indeterminate="indeterminate"
      @change="toogleAll"
    />
  </component>
</template>

<script lang="ts">
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import Checkbox from '@/components/checkbox/Checkbox.vue';
import HeaderCell from './HeaderCell.vue';
import { DataTableStore } from '../DataTableProps';

@Component({
  name: 'CheckboxHeaderCell',
  components: { Checkbox }
})
export default class CheckboxHeaderCell extends Vue {
  @InjectReactive('dataTableStore')
  private readonly dataTableStore!: DataTableStore;

  @Prop({ required: false, type: Boolean, default: true })
  private isHeader!: boolean;

  get checked() {
    return this.dataTableStore
      ? this.dataTableStore.checkeds.length > 0
      : false;
  }

  private get getComponent() {
    if (this.isHeader) return HeaderCell;
    return 'div';
  }

  private get indeterminate() {
    const checkedItems = this.dataTableStore.checkeds.length;
    const hasCheckedItems = checkedItems > 0;
    const hasNotAllItemsChecked =
      checkedItems < this.dataTableStore.data.length;

    return hasCheckedItems && hasNotAllItemsChecked;
  }

  private toogleAll() {
    if (!this.checked || this.indeterminate) this.setAll();
    else this.clearAll();
  }

  private clearAll() {
    this.dataTableStore.setCheckeds([]);
  }

  private setAll() {
    this.dataTableStore.setCheckeds(
      this.dataTableStore.data.map(({ _hash }) => _hash)
    );
  }
}
</script>

<style lang="less" scoped>
.checkbox-header {
  &-cell {
    .checkbox-container {
      width: 56px;
    }
  }
}
</style>
