<template>
  <BaseCell class="text-p-5 base-cell" v-bind="$attrs">
    <BaseText v-if="text" size="sm" :color="color">{{ text }}</BaseText>
    <ChipStatus
      v-if="tag"
      :class="{ 'is-disabled': disabled }"
      :label="tag.text"
      :status="chipStatus"
      :icon="tag.icon"
    />
    <BaseText
      v-if="description"
      size="xs"
      :color="NebraskaColors.textSecondary"
      >{{ description }}</BaseText
    >
    <BaseText v-if="subtitle" size="xs" :color="NebraskaColors.textSecondary">
      {{ subtitle }}
    </BaseText>
    <a v-if="link" v-bind="linkProps">{{ link.text }}</a>
  </BaseCell>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseCell from './BaseCell.vue';
import BaseText from '@/components/base-text/BaseText.vue';
import { Tag } from '../DataTableProps';
import ChipStatus from '@/components/chip/chip-status/ChipStatus.vue';

@Component({
  name: 'DefaultCell',
  components: { BaseCell, ChipStatus, BaseText }
})
export default class DefaultCell extends Vue {
  @Prop({ type: [String, Number], required: false, default: '' })
  private text!: string | number;

  @Prop({ type: String, required: false })
  private description?: string;

  @Prop({ type: String, required: false })
  private subtitle?: string;

  @Prop({ type: Object, required: false })
  private tag?: Tag;

  @Prop({ type: Object, required: false })
  private link?: HTMLAnchorElement;

  @Prop({ type: String, required: false, default: NebraskaColors.textPrimary })
  private color!: NebraskaColors;

  @Inject({ from: 'disabled', default: false })
  private readonly disabled!: boolean;

  readonly NebraskaColors = NebraskaColors;

  private get linkProps() {
    const { ...props } = this.link || {};
    return props;
  }

  get chipStatus() {
    if (this.tag) {
      if (this.tag.error) {
        return 'negative';
      } else if (this.tag.info) {
        return 'information';
      } else if (this.tag.alert) {
        return 'alert';
      } else {
        return 'positive';
      }
    } else {
      return '';
    }
  }
}
</script>

<style lang="less" scoped>
.base-cell {
  vertical-align: middle;

  .is-disabled {
    background: @base-disabled;
    color: @base-over-disabled;
  }
}
</style>
