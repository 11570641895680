<template>
  <div ref="scrollContainer" :class="classes" @scroll.capture="toggleShadow">
    <div class="scroll-shadow__content">
      <!-- @slot Recebe o conteúdo que terá a rolagem e as sombras -->
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'ScrollShadow'
})
export default class ScrollShadow extends Vue {
  public showTopShadow = false;

  public showBottomShadow = false;

  private get classes() {
    return {
      'scroll-shadow': true,
      'scroll-shadow--top': this.showTopShadow,
      'scroll-shadow--bottom': this.showBottomShadow
    };
  }

  mounted() {
    this.toggleShadow();
  }

  public toggleShadow() {
    // Scroll start value
    const hasVerticalScrollbar = this.$el.clientHeight < this.$el.scrollHeight;

    const distanceFromTop = this.$el.scrollTop;
    const isAtTop = distanceFromTop === 0;
    this.showTopShadow = hasVerticalScrollbar && !isAtTop;

    const scrollEndPosition = this.$el.scrollHeight - this.$el.clientHeight - 1;
    const isAtBottom = distanceFromTop >= scrollEndPosition;
    this.showBottomShadow = hasVerticalScrollbar && !isAtBottom;
  }
}
</script>

<style lang="less" scoped>
@shadow-height: 1em;

.shadow() {
  content: '';
  display: block;
  position: sticky;
  visibility: hidden;
  pointer-events: none;
  height: @shadow-height;
  border-radius: 0 0 @size-radius-max @size-radius-max;
  background-image: linear-gradient(
    rgba(85, 85, 85, 0.1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.scroll-shadow {
  position: relative;
  height: 100%;
  overflow: auto;

  &::before,
  &::after {
    .shadow();
  }

  &--top::before {
    visibility: visible;
    top: 0;
  }

  &--bottom::after {
    visibility: visible;
    bottom: 0;
    transform: rotate(180deg);
  }

  &__content {
    margin: -@shadow-height 0;
    overflow: hidden;
    min-height: 100%;
  }
}
</style>
