<template>
  <div :class="computedClassName">
    <slot />
  </div>
</template>

<script lang="ts">
import {
  getContextColorClass,
  ContextTypes
} from '@warrenbrasil/nebraska-tokens-web';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'ContextColorProvider'
})
export default class ContextColorProvider extends Vue {
  /**
   * Define o contexto de cor a ser repassado para todos os elementos/componentes filhos.
   * @values brand, business, cash, neutral, trade, wealth, usa
   */
  @Prop({
    type: String,
    default: ContextTypes.NEUTRAL,
    validator: (context: ContextTypes) => {
      const validContexts = Object.values(ContextTypes);
      return validContexts.includes(context);
    }
  })
  readonly context!: ContextTypes;

  private get computedClassName() {
    return (
      getContextColorClass(this.context) ||
      getContextColorClass(ContextTypes.NEUTRAL)
    );
  }
}
</script>
