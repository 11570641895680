<template>
  <component
    :is="as"
    :class="computedClassName"
    :tabindex="tabindex"
    :href="href"
    :style="{ color: colors.content }"
    :aria-disabled="disabled"
    data-testid="button"
    v-on="!disabled && !loading && $listeners"
  >
    <span class="button--label">
      <!-- @slot Área padrão para receber o label ou outros componentes -->
      <slot />
    </span>
    <span
      :class="`button--ui background ${isBlur ? 'blur' : ''}`"
      :style="computedBackgroundStyle"
    />
    <span class="button--ui status--loading">
      <BaseIcon
        :spin="loading"
        :class="computedIconStyle"
        icon="ic_spinner"
        colors="currentColor"
        :width="computedIconSize"
        :height="computedIconSize"
      />
    </span>
  </component>
</template>

<script lang="ts">
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import { ButtonColors } from '../models/Button';
import { ButtonIconSizes } from '@/foundation/types';

@Component({
  name: 'Button',
  components: { BaseIcon }
})
export default class Button extends Vue {
  /**
   * Permite atribuir um link ao botão
   */
  @Prop({ type: String })
  readonly href?: string;

  /**
   * Desativa o botão
   */
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  /**
   * Coloca o botão no estado "carregando"
   */
  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean;

  /**
   * Define o tamanho do ícone
   * @values extra-small, small
   */
  @Prop({ type: String, default: ButtonIconSizes.SMALL })
  readonly iconSize!: string;

  /**
   * Define as cores do botão
   */
  @Prop({
    type: Object,
    default: (): ButtonColors => ({
      background: NebraskaColors.neutralPrimary,
      content: NebraskaColors.neutralOverPrimary,
      alphas: {
        default: 1,
        hover: 0.9,
        active: 0.8
      }
    })
  })
  readonly colors!: ButtonColors;

  /** Define qual a posição do elemento durante a navegação pelo teclado. Na maioria dos casos, não é necessário alterar o valor padrão.
   * @see {@link https://developer.mozilla.org/pt-BR/docs/Web/HTML/Global_attributes/tabindex}
   */
  @Prop({
    type: Number,
    default: 0
  })
  readonly tabindex!: number;

  /**
   * Estilo de Blur
   */
  @Prop({
    type: Boolean,
    default: false
  })
  readonly isBlur?: boolean;

  private get computedClassName() {
    return [
      'button--container',
      {
        disabled: this.disabled,
        loading: this.loading,
        blur: this.isBlur
      }
    ];
  }

  private get computedBackgroundStyle() {
    const { background, alphas } = this.colors;

    return {
      color: background,
      '--default': alphas.default,
      '--hover': alphas.hover,
      '--active': alphas.active
    };
  }

  public get computedIconStyle() {
    return [
      'icon',
      { 'icon--extra-small': this.iconSize === ButtonIconSizes.EXTRA_SMALL }
    ];
  }

  public get computedIconSize() {
    return this.iconSize === ButtonIconSizes.EXTRA_SMALL ? '16' : '24';
  }

  private get isHyperlink(): boolean {
    return !!this.href;
  }

  private get as(): string {
    return this.isHyperlink ? 'a' : 'button';
  }
}
</script>

<style lang="less" scoped>
@transition: 100ms cubic-bezier(0.55, 0, 0.1, 1);

.button {
  &--container {
    all: unset;
    .text-p-4();
    font-weight: 600;
    background: transparent;
    background-clip: content-box;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    transition: transform @transition;
    z-index: 1;

    &:focus-visible {
      border: 1px solid @base-select;
    }

    &:hover {
      transform: scale(1.0175);

      .background {
        opacity: var(--hover);
      }
    }

    &:active {
      transform: scale(0.985);

      .background {
        opacity: var(--active);
      }
    }

    &.loading {
      pointer-events: none;

      .button--label {
        opacity: 0;
      }
      .status--loading {
        display: flex;
        transform: translateY(0);
      }
    }

    &.disabled {
      color: @base-over-disabled !important;
      pointer-events: none;

      .background {
        color: @base-disabled !important;
      }
    }
  }

  &--label {
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 5;
  }

  &--ui {
    border-radius: inherit;
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    &.status--loading {
      display: none;
      justify-content: center;
      align-items: center;
      transform: translateY(100%);
      z-index: 1;

      .icon {
        display: block;
        line-height: 1em;
        width: 24px;
        height: 24px;
      }

      .icon--extra-small {
        width: 16px;
        height: 16px;
      }
    }

    &.background {
      background-color: currentColor;
      box-shadow: 0 0 1px 0px currentColor inset, 0 0 1px 0px currentColor;
      transition: opacity @transition;
      opacity: var(--default);
    }

    &.blur {
      backdrop-filter: blur(5px) saturate(1.4) brightness(1.01);
      background: rgba(255, 255, 255, 0.1);
      border: 0;

      &:hover {
        transition: all ease 0.5s;
        transform: none;
      }
    }
  }
}
</style>
