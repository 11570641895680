<template>
  <div v-if="hasHeading" class="data-table__heading">
    <div v-if="hasCheckbox" class="data-table__heading-container">
      <CheckboxHeaderCell
        class="data-table__heading-checkbox"
        :is-header="false"
      />
      <span
        v-if="dataTableStore.checkeds.length === 0"
        class="text-p-5 data-table__heading-checkbox__placeholder"
      >
        Selecionar todos
      </span>
      <slot v-else name="left" />
    </div>
    <div>
      <slot name="right" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import { DataTableStore } from './DataTableProps';
import CheckboxHeaderCell from './TableCells/CheckboxHeaderCell.vue';

@Component({
  name: 'DataTableHeading',
  components: { CheckboxHeaderCell }
})
export default class DataTableHeading extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  private hasCheckbox!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private hasHeading!: boolean;

  @InjectReactive('dataTableStore')
  private readonly dataTableStore!: DataTableStore;
}
</script>

<style lang="less" scoped>
.data-table {
  &__heading {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: @size-border-x400 solid @divider-primary;
    padding: @size-spacing-x500 0;

    &-container {
      align-items: center;
      display: flex;
      flex-direction: row;
    }
  }
  &__heading-checkbox {
    display: inline-block;
    margin-right: @size-spacing-x300;
    width: 48px;

    &__placeholder {
      color: @element-secondary;
    }
  }
}
</style>
