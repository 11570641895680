<template>
  <ButtonIcon
    :tabindex="tabindex"
    :href="href"
    :disabled="disabled"
    :loading="loading"
    :label="label"
    :colors="computedColors"
    :size="size"
    :icon="icon"
    :char="char"
    data-testid="button-icon-secondary"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonIcon from '@/foundation/button-icon/ButtonIcon.vue';
import { ButtonApplication, ButtonSizes } from '@/foundation/types';
import { secondaryColors, secondaryOverImage } from '@/styles/Button';
import { ButtonColors } from '@/foundation/models/Button';

@Component({
  name: 'ButtonIconSecondary',
  components: { ButtonIcon }
})
export default class ButtonIconSecondary extends Vue {
  /**
   * O rótulo do botão.
   * A label não é exibida, mas é adicionada por questão de acessibilidade
   */
  @Prop({ type: String, required: true })
  readonly label!: string;

  /**
   * Permite atribuir um link ao botão
   */
  @Prop({ type: String })
  readonly href?: string;

  /**
   * Desativa o botão
   */
  @Prop({ type: Boolean })
  readonly disabled?: boolean;

  /**
   * Coloca o botão no estado "carregando"
   */
  @Prop({ type: Boolean })
  readonly loading?: boolean;

  /**
   * Define qual a posição do elemento durante a navegação pelo teclado. Na maioria dos casos, não é necessário alterar o valor padrão.
   * @see {@link https://developer.mozilla.org/pt-BR/docs/Web/HTML/Global_attributes/tabindex}
   */
  @Prop({ type: Number })
  readonly tabindex?: number;

  /**
   * Define o tamanho do botão
   * @values small, regular, large
   */
  @Prop({
    type: String,
    default: ButtonSizes.REGULAR
  })
  readonly size!: string;

  /**
   * O ícone a ser exibido
   * O `icon` tem precedência ao `char`
   */
  @Prop({
    type: String
  })
  readonly icon?: string;

  /**
   * O caractere a ser exibido
   */
  @Prop({
    type: String
  })
  readonly char?: string;

  /**
   * Define qual a superfície onde o botão está inserido
   * @values normal, overImage
   */
  @Prop({
    type: String,
    default: ButtonApplication.NORMAL
  })
  readonly appliedOn!: ButtonApplication;

  private get computedColors(): ButtonColors {
    switch (this.appliedOn) {
      case ButtonApplication.OVER_IMAGE:
        return secondaryOverImage;
      case ButtonApplication.NORMAL:
      default:
        return secondaryColors;
    }
  }
}
</script>
