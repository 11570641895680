<template>
  <div :class="['button-container', computedDirectionClass, computedRefClass]">
    <div class="button-wrapper">
      <!-- @slot Slot do primeiro botão do container (required) -->
      <slot name="button-primary" />
      <!-- @slot Slot do segundo botão do container (optional) -->
      <slot name="button-secondary" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

export enum Direction {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL'
}

@Component({ name: 'ButtonBoxLaunch' })
export default class ButtonContainer extends Vue {
  /**
   * Define a disposição dos botões no mobile (Desktop será sempre lado a lado)
   */
  @Prop({
    type: String,
    default: Direction.HORIZONTAL,
    validator: value =>
      value === Direction.HORIZONTAL || value === Direction.VERTICAL
  })
  readonly mobileDirection!: Direction;

  get computedDirectionClass() {
    if (this.mobileDirection === Direction.VERTICAL) {
      return 'button-container--vertical';
    }

    return 'button-container--horizontal';
  }

  get computedRefClass() {
    if (this.$slots['button-secondary']) {
      return 'button-container--dual';
    }

    return 'button-container--single';
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/_medias';

.button-container {
  display: block;
  width: 100%;
  border-top: @size-border-x400 solid @divider-primary;
  padding: @size-spacing-x400;
  box-sizing: border-box;

  .button-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 0 auto;
  }

  &--dual {
    button:first-of-type {
      margin-right: @size-spacing-x300;
    }
  }

  .to-sm({
    .button-wrapper {
      flex-direction: column;

      button {
        width: 100%;
        &:first-of-type {
          margin-bottom: @size-spacing-x300;
          margin-right: 0;
        }
      }
    }

    &--horizontal&--dual {
      .button-wrapper {
        flex-direction: row;
      }

      button:first-of-type {
        margin-right: @size-spacing-x300;
        margin-bottom: 0;
      }
    }
  });
}
</style>
