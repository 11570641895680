<template>
  <button
    class="copy-button"
    type="button"
    :aria-label="ariaLabel"
    @click="onCopy"
  >
    <transition name="fade" mode="out-in">
      <div v-if="isCopied" key="tooltip" class="copy-button__tooltip">
        <p class="text-p-5">Copiado</p>
        <BaseIcon
          :colors="[
            NebraskaColors.statusPositiveBackground,
            NebraskaColors.statusPositive
          ]"
          icon="ic_check"
          width="24"
          height="24"
        />
      </div>
      <BaseIcon
        v-else
        key="button"
        :colors="NebraskaColors.elementPrimary"
        icon="ic_copy"
        width="24"
        height="24"
      />
    </transition>
  </button>
</template>

<script lang="ts">
import { Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { mixins } from 'vue-class-component';
import CopyMixin from './CopyMixin';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';

@Component({
  name: 'CopyButton',
  components: {
    BaseIcon
  }
})
export default class CopyButton extends mixins(CopyMixin) {
  /**
   * Texto que será copiado
   */
  @Prop({ type: String })
  readonly value!: string;

  /**
   * Informa se o texto foi copiado
   */
  @Prop({ type: Boolean, default: false })
  readonly isCopiedOut!: boolean;

  /**
   * Texto utilizado para informar tecnologias assistivas a função do botão
   * @example Copiar nome da carteira
   */
  @Prop({ type: String, default: 'Copiar' })
  readonly ariaLabel!: string;

  private isCopied = false;

  private NebraskaColors = NebraskaColors;

  @Watch('isCopiedOut')
  private onCopiedOut() {
    if (this.isCopiedOut) {
      this.toggleTooltip();
    }
  }

  @Emit('click')
  private onCopy() {
    this.copyToClipboard(this.value);
    this.toggleTooltip();

    return this.value;
  }

  private toggleTooltip() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 2000);
  }
}
</script>

<style lang="less" scoped>
.copy-button {
  background-color: transparent;
  border: unset;
  cursor: pointer;

  &:focus-visible {
    outline: @size-border-x500 solid @element-primary;
    border-radius: @size-radius-x200;
  }

  &__tooltip {
    display: flex;

    p {
      color: @text-secondary;
      padding-right: @size-spacing-x300;
      margin: 0;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
