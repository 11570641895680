<template>
  <div :class="computedFullAreaClass" @click="onInputClick">
    <fieldset ref="fieldset" :class="computedInputWrapperClass">
      <legend aria-hidden="true">
        <span class="text-p-6 float-label">{{ label }}</span>
      </legend>
      <label class="normal-label text-p-4" :for="id">
        {{ label }}
      </label>
      <div v-if="allowInput" class="input-select">
        <input
          :id="id"
          ref="input"
          v-model="inputValue"
          class="text-p-4"
          :name="id"
          :required="required"
          :disabled="disabled"
          tabindex="0"
          @input="onInput"
          @blur="onBlur"
          @focus="onFocus"
        />
        <ul v-if="filteredOptions.length && inputFocused" class="options-list">
          <li
            v-for="option in filteredOptions"
            :key="option.value"
            :class="{
              'disabled-option': option.disabled,
              'selected-option': option.value === selectedValues
            }"
            @mousedown.prevent="selectOption(option)"
          >
            {{ option.label }}
          </li>
        </ul>
      </div>
      <select
        v-else
        :id="id"
        v-model="selectedValues"
        :name="id"
        :required="required"
        :disabled="disabled"
        tabindex="0"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :disabled="option.disabled"
        >
          {{ option.label }}
        </option>
      </select>
      <span v-if="!allowInput" class="text-p-4" aria-hidden="true">
        {{ selectedLabels }}
      </span>
      <div class="icons">
        <BaseIcon icon="ic_chevron_down" colors="currentColor" />
        <StatusTypeIcon
          v-if="hasStatusTypeIcon"
          :status-type="statusType"
          class="status-icon"
        />
      </div>
    </fieldset>
    <div
      v-if="helperText"
      class="text-p-6 bottom-helper"
      data-testid="helper-text"
    >
      <p>{{ helperText }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import { StatusTypeIcon } from '@/components/status-type-icon';
import { InputStatusType } from '@/foundation/types';

type InputValue = string | number | null;

@Component({
  name: 'InputSelect',
  components: { BaseIcon, StatusTypeIcon },
  inheritAttrs: false,
  model: {
    event: 'change',
    prop: 'selected'
  }
})
export default class InputSelect extends Vue {
  @Ref('fieldset')
  readonly fieldset!: HTMLFieldSetElement;

  @Ref('input')
  readonly input!: HTMLInputElement;

  /**
   * Rótulo (label) exibido no input.
   */
  @Prop({ type: String, required: false })
  readonly label?: string;

  /**
   * Valor inicial do input
   */
  @Prop({ type: [String, Number, Array], required: false })
  readonly selected!: InputValue;

  /**
   * A lista de opções do select
   */
  @Prop({ type: Array, required: true })
  readonly options!: Array<{
    value: string | number;
    label: string;
    disabled?: boolean;
  }>;

  /**
   * Para desativar o select
   */
  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;

  /**
   * Indica que a seleção é obrigatória
   */
  @Prop({ type: Boolean, required: false, default: false })
  readonly required?: boolean;

  /**
   * Para indicar que o campo está habilitado, porém não é possível selecionar outra opção
   */
  @Prop({ type: Boolean, required: false, default: false })
  readonly readonly?: boolean;

  /**
   * O texto de ajuda abaixo do input
   */
  @Prop({ type: String, required: false })
  public helperText?: string;

  /**
   * Indica o status do input e muda sua aparência
   * @values default, error, success
   */
  @Prop({
    type: String,
    default: InputStatusType.Default,
    validator(statusName: InputStatusType) {
      const validStatus = Object.values(InputStatusType);
      return validStatus.includes(statusName);
    }
  })
  readonly statusType!: InputStatusType;

  /**
   * Habilita a entrada de texto no select + comportamento de autoselect
   */
  @Prop({ type: Boolean, required: false, default: false })
  readonly allowInput!: boolean;

  private inputValue = this.selectedLabels;
  private inputFocused = false;
  private filteredOptions = this.options;

  private get computedFullAreaClass() {
    let classes = 'default';
    if (this.disabled) classes = 'disabled';
    else if (this.readonly) classes = 'readonly';
    else if (this.statusType) classes = this.statusType;
    return [`input-full-area`, `input-full-area--${classes}`];
  }

  private get computedInputWrapperClass() {
    return [
      'input-wrapper',
      this.computedInputFilled,
      this.computedWithoutLabel
    ];
  }

  private get computedInputFilled() {
    return this.hasSelectedValues || (this.allowInput && this.inputFocused)
      ? 'input-filled'
      : '';
  }

  private get computedWithoutLabel() {
    return this.label ? 'input-with-label' : 'input-without-label';
  }

  private get id() {
    const randomId = Math.random().toString(16).slice(2);
    return `input-id-${randomId}`;
  }

  @Emit('change')
  public change(value: InputValue): InputValue {
    return value;
  }

  private get selectedLabels(): string {
    return this.options
      .filter(option => option.value === this.selected)
      .map(option => option.label)
      .join(', ');
  }

  private get selectedValues(): InputValue {
    return this.selected;
  }

  private set selectedValues(value: InputValue) {
    this.change(value);
  }

  private get hasSelectedValues(): boolean {
    return !!this.selectedValues;
  }

  private get hasStatusTypeIcon(): boolean {
    return this.statusType !== InputStatusType.Default;
  }

  private onInputClick() {
    if (!this.allowInput) {
      return;
    }
    this.inputFocused = true;
    this.input.focus();
  }

  private onInput(event: Event) {
    this.inputFocused = true;
    const target = event.target as HTMLInputElement;
    this.inputValue = target.value;
    this.filteredOptions = this.options.filter(option =>
      option.label.toLowerCase().includes(this.inputValue.toLowerCase())
    );
  }

  private onFocus() {
    this.filteredOptions = this.options;
  }

  private onBlur() {
    this.inputFocused = false;
    if (
      !this.filteredOptions.some(option => option.label === this.inputValue)
    ) {
      this.inputValue = '';
      this.selectedValues = null;
    }
  }

  private selectOption(option: {
    value: InputValue;
    label: string;
    disabled?: boolean;
  }) {
    if (option.disabled) {
      return;
    }
    this.inputValue = option.label;
    this.selectedValues = option.value;
    this.filteredOptions = this.options;
    this.inputFocused = false;
  }
}
</script>

<style lang="less" scoped>
.label-float-position {
  top: -1.05em;
  left: @size-spacing-x400 !important;
  font-size: 12px;
}
.input-full-area {
  position: relative;
  text-align: left;

  &--default {
    color: @element-secondary;
  }
  &--error {
    .input-wrapper {
      border-color: @element-on-error !important;
    }
    .normal-label,
    .float-label,
    .bottom-helper {
      color: @text-on-error !important;
    }
  }

  &--success {
    .input-wrapper {
      border-color: @element-on-success !important;
    }
    .normal-label,
    .float-label,
    .bottom-helper {
      color: @text-on-success !important;
    }
  }

  &--disabled {
    color: @element-disabled;

    .input-wrapper {
      border-color: @element-disabled !important;
    }
    .normal-label,
    .float-label,
    .bottom-helper,
    select,
    fieldset {
      color: @text-disabled !important;
    }
    select {
      pointer-events: none;
    }
  }

  &--readonly {
    color: @element-disabled;
    .input-wrapper {
      border-color: @element-disabled !important;
      padding: 1px @size-spacing-x400;
      &:focus-within {
        color: @element-disabled;
        border: @size-border-x400 solid @element-primary;
        padding: 1px @size-spacing-x400;
      }
    }
    .normal-label {
      .label-float-position();
    }
    legend {
      display: inline-block !important;
      height: 0px !important;
    }
    .normal-label,
    .float-label,
    .bottom-helper,
    input {
      color: @text-disabled !important;
    }
  }
}

.input-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: @size-border-x400 solid @base-over-disabled;
  border-radius: @size-radius-x100;
  height: 64px;
  padding: 1px @size-spacing-x400;
  margin: 0;
  box-sizing: border-box;

  legend {
    margin-left: calc(-1 * @size-spacing-x150);
    padding: 0;
    padding-right: 6px;
    display: none;
    transform: translate(0px, -10px);
  }

  select {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: @background-secondary;
    color: @text-primary;
  }

  &:hover {
    border: @size-border-x400 solid @element-primary;
  }

  &:focus-within {
    color: @element-primary;
    border: @size-border-x500 solid @element-primary;
    padding: 0px calc(@size-spacing-x400 - 1px);
  }

  .icons {
    display: flex;
    align-items: center;
  }

  .status-icon {
    margin-left: @size-spacing-x300;
  }
}

.normal-label {
  position: absolute;
  margin-left: 0px;
  color: @text-placeholder;
  transition: 0.2s ease;
  top: 19px;
}

.float-label {
  visibility: hidden;
}

.input-filled {
  color: @text-primary;

  .normal-label {
    .label-float-position();
    color: @text-primary;
  }
  legend {
    display: inline-block;
    height: 0px;
  }
}

.input-without-label {
  legend {
    display: none !important;
  }
}

.bottom-helper {
  margin-top: @size-spacing-x300;
  color: @text-secondary;
}

.input-select {
  width: 100%;

  input {
    width: 100%;
    padding: 0;
    border: none;
    margin: 0;
    background: none;
    appearance: none;
    box-shadow: none;
    outline: none;
    box-sizing: border-box;
    color: @text-primary;
  }
}

.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: @size-radius-x100;
  background-color: @background-secondary;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  list-style: none;
}

.options-list li {
  padding: @size-spacing-x300;
  cursor: pointer;

  &.disabled-option {
    color: @text-disabled;
    cursor: not-allowed;

    &:hover {
      background-color: @background-secondary;
    }
  }

  &.selected-option {
    background-color: @background-hover;
  }
}

.options-list li:hover {
  background-color: @background-hover;
}
</style>
