<template>
  <TableCell
    :scope="scope"
    :is-header="true"
    :class="classes"
    @click.native="click"
  >
    <!-- @slot Header content -->
    <slot />
    <BaseIcon
      v-if="isSorting"
      :icon="sortArrowIcon"
      :colors="NebraskaColors.textPrimary"
      class="header-cell__sorting-icon"
    />
  </TableCell>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import TableCell from '../table-cell/TableCell.vue';

type SortOrder = 'ascending' | 'descending';

@Component({
  name: 'HeaderCell',
  components: { BaseIcon, TableCell }
})
export default class HeaderCell extends Vue {
  /**
   * Column identifier, that is used by the Table component to control the sorting.
   * ** *It is required if the header identifies a sortable column.**
   */
  @Prop({ type: String, required: false })
  readonly columnKey?: string;

  /**
   * Defines the cells that the header element relates to.
   */
  @Prop({ type: String, default: 'col' })
  readonly scope!: string;

  /**
   * If true, indicates that the element is a header of a sortable column.
   */
  @Prop({ type: Boolean, default: false })
  readonly sortable!: boolean;

  /**
   * If true, indicates that the headers column is sorting the table data.
   */
  @Prop({ type: Boolean, default: false })
  readonly isSorting!: boolean;

  /**
   * Indicates the current sorting order.
   * Can be `ascending` or `descending`.
   */
  @Prop({ type: String, default: 'ascending' })
  readonly sortingOrder!: SortOrder;

  @Inject()
  readonly changeSorting!: (key: string, sortingOrder?: SortOrder) => void;

  private NebraskaColors = NebraskaColors;

  private click() {
    if (this.sortable && this.columnKey) {
      if (this.isSorting) {
        this.changeSorting(this.columnKey, this.sortingOrder);
      } else {
        this.changeSorting(this.columnKey);
      }
    }
  }

  get classes() {
    return {
      'header-cell': true,
      'header-cell--sorting': this.isSorting,
      'header-cell--sortable': this.sortable
    };
  }

  get sortArrowIcon() {
    return this.sortingOrder === 'ascending'
      ? 'ic_filled_arrow'
      : 'ic_filled_arrowDown';
  }
}
</script>

<style lang="less" scoped>
.header-cell {
  position: relative;
  font-weight: bold;
  color: @element-secondary;
  height: auto;
}

.header-cell--sorting {
  color: @element-primary;
}

.header-cell__sorting-icon {
  position: absolute;
  transform: translate(0, -10%);
}

.header-cell--sortable:active {
  transform: translateY(2px);
}

.header-cell--sortable:hover {
  cursor: pointer;
  color: @element-primary;
  user-select: none;
}
</style>
