<template>
  <div class="actionable-helper-view">
    <HelperView
      :title="getMessages.title"
      :description="getMessages.description"
      :button-label="buttonText"
      @click="onRetry"
    >
      <template #icon>
        <BaseIcon
          width="64"
          height="64"
          :colors="iconColors"
          :icon="getMessages.icon"
          data-testid="helper-view-icon"
        />
      </template>
    </HelperView>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import HelperView from '../helper-view/HelperView.vue';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';

interface MessageType {
  icon: string;
  title: string;
  description: string;
}

const messages: { [key: string]: MessageType } = {
  generic: {
    icon: 'ic_alert_error',
    title: 'Ops! Algo deu errado',
    description: 'Aguarde alguns instantes e tente novamente.'
  },
  connection: {
    icon: 'ic_wifi_error',
    title: 'Erro de conexão',
    description: 'Verifique sua conexão com a internet e tente novamente.'
  },
  instability: {
    icon: 'ic_progress_rounded',
    title: 'Tente mais tarde',
    description:
      'Estamos passando por uma instabilidade, por favor tente novamente mais tarde.'
  },
  expired: {
    icon: 'ic_alert_rounded',
    title: 'Sua sessão expirou',
    description: 'Realize o login novamente para voltar a acessar a plataforma'
  }
};

/**
 * @deprecated Use apenas o HelperView.
 */
@Component({
  name: 'ActionableHelperView',
  components: {
    BaseIcon,
    HelperView
  }
})
export default class ActionableHelperView extends Vue {
  /**
   * Para definir qual o tipo do alerta e assim trocar ícone e cores
   * @values generic, connection, instability, expired
   */
  @Prop({ type: String, default: 'generic' })
  private type!: keyof typeof messages;

  /**
   * Método chamado no clique do botão
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @Prop({ type: Function, required: true, default: () => {} })
  private onRetry!: () => unknown;

  /**
   * Permite customizar as cores do ícone
   */
  @Prop({
    type: Array,
    default: () => [
      NebraskaColors.statusAlertBackground,
      NebraskaColors.statusAlert
    ]
  })
  private iconColors?: string[];

  /**
   * Define o rótulo do botão
   */
  @Prop({ type: String, default: 'Tentar novamente' })
  private buttonText!: string;

  private get getMessages() {
    return messages[this.type];
  }
}
</script>
