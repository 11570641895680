<template>
  <div :style="computedStyles">
    <slot :nebraska-colors="NebraskaColors" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
/**
 * @deprecated
 */
@Component({
  name: 'ThemeProvider'
})
export default class ThemeProvider extends Vue {
  @Prop()
  private primary!: string;

  @Prop()
  private overPrimary!: string;

  private NebraskaColors = NebraskaColors;

  private get computedStyles() {
    return {
      '--theme-primary': `${this.primary}`,
      '--theme-over-primary': `${this.overPrimary}`
    };
  }
}
</script>
