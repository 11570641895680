<template>
  <div>
    <NavBar :transparent="isTransparent" sticky class="input-step__nav-bar">
      <template #left>
        <ButtonIcon
          :colors="closeButtonColors"
          label="Voltar"
          icon="EA0030"
          @click="emitBack"
        />
      </template>
      <template #center>
        <p>{{ pageTitle }}</p>
      </template>
    </NavBar>
    <section class="input-step__container">
      <div class="input-step__content">
        <HeadingDisplay
          class="input-step__header"
          :title="title"
          :head="head"
          :subtitle="subtitle"
        >
          <template #heading-component>
            <slot name="heading-component" />
          </template>
          <template #subtitle-component>
            <slot name="subtitle-component" />
          </template>
        </HeadingDisplay>
        <slot />
      </div>
    </section>
    <section class="input-step__button-container">
      <slot name="button-container" />
    </section>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { NavBar } from '@/components/navbar';
import { HeadingDisplay } from '@/components/heading';
import ButtonIcon from '@/foundation/button-icon/ButtonIcon.vue';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import throttle from 'lodash.throttle';

@Component({
  components: {
    NavBar,
    ButtonIcon,
    HeadingDisplay
  }
})
export default class InputStep extends Vue {
  /**
   * Título da página a ser exibido na NavBar
   */
  @Prop({ type: String, default: '' })
  readonly pageTitle!: string;

  /**
   * Título exibido no Header
   */
  @Prop({ type: String, required: true })
  readonly title!: string;

  /**
   * Texto a ser exibido no Head | Subhead (acima do title)
   */
  @Prop({ type: [Array, String], default: '' })
  readonly head!: string[] | string;

  /**
   * Texto a ser exibido no Subtitle (abaixo do title)
   */
  @Prop({ type: String, default: '' })
  readonly subtitle!: string;

  /**
   * Evento disparado ao clicar no botão de 'Voltar' na NavBar
   */
  @Emit('back')
  emitBack() {
    // Função que emite evento back
  }

  scrollY = 0;

  scrolledThrottled: (() => void) | null = null;

  get isTransparent() {
    return this.scrollY === 0;
  }

  get closeButtonColors() {
    return {
      background: NebraskaColors.elementSecondary,
      content: NebraskaColors.elementPrimary,
      alphas: {
        default: 0,
        hover: 0.08,
        active: 0.08
      }
    };
  }

  scrolled() {
    this.scrollY = window ? window.scrollY : 0;
  }

  created() {
    this.scrolledThrottled = throttle(this.scrolled, 150);
    window.addEventListener('scroll', this.scrolledThrottled);
  }

  destroyed() {
    if (this.scrolledThrottled) {
      window.removeEventListener('scroll', this.scrolledThrottled);
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/_medias';

.input-step {
  &__nav-bar {
    margin-bottom: @size-spacing-x1000;
  }

  &__container {
    padding: 0 @size-spacing-x500;

    .to-lg({ padding: 0 @size-spacing-x400;});
  }

  &__content {
    max-width: 592px;
    margin: 0 auto @size-spacing-x1800;
  }

  &__header {
    margin-bottom: @size-spacing-x600;
  }

  &__button-container {
    background-color: @background-secondary;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 20;
  }
}
</style>
