<template>
  <section>
    <div
      v-for="step in steps"
      :key="step.title"
      class="progress-step--container"
      data-testid="progress-step"
    >
      <div class="progress-step--background">
        <div class="progress-step__status" :style="getProgressWidth(step)" />
      </div>
      <div class="progress-step__text" :class="computedTextAlignment">
        <BaseText
          v-if="step.title && step.progress === EProgress.PROGRESS"
          class="progress-step__text-bold"
          size="sm"
        >
          {{ step.title }}
        </BaseText>
        <BaseText v-else size="sm">{{ step.title }}</BaseText>
        <BaseText
          v-if="step.description"
          data-testid="step-description"
          :color="NebraskaColors.elementSecondary"
          size="sm"
          >{{ step.description }}</BaseText
        >
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseText from '@/components/base-text/BaseText.vue';
import { IStep, EAlignment, EProgress } from './types';

@Component({
  name: 'ProgressStep',
  components: {
    BaseText
  }
})
export default class ProgressStep extends Vue {
  /**
   * Passos do fluxo que será representado. Essa propriedade é um vetor
   * de objetos, em que cada objeto pode possuir 3 propriedades: \n
   * 1. `progress`: Propriedade **obrigatória** para o funcionamento
   * correto do componente. Representa o estado do passo atual, podendo ir de 0 até 1, representando o percentual preenchido do componente. \n
   * 2. `title`: Propriedade opcional. Contém o texto de título que virá acompanhado
   * de cada step. \n
   * 3. `description`: Propriedade opcional. Contém o texto de descrição
   * explicativa do passo.
   */
  @Prop({
    type: Array,
    required: true,
    validator: (arr: Array<unknown>) => arr.length > 1 && arr.length <= 6
  })
  readonly steps!: IStep[];

  /**
   * Alinhamento do texto de título e descrição. Valores aceitos `LEFT`
   * (default), `RIGHT`, `CENTER`.
   */
  @Prop({
    type: String,
    default: EAlignment.LEFT
  })
  readonly alignment!: EAlignment;

  readonly NebraskaColors = NebraskaColors;

  readonly EProgress = EProgress;

  getProgressWidth(step: IStep) {
    const progress = this.isNumberInRange(step.progress) ? step.progress : 0;
    return `width: ${progress * 100}%`;
  }

  isNumberInRange(progress: EProgress | number) {
    return !isNaN(progress) && progress >= 0 && progress <= 1;
  }

  get computedTextAlignment() {
    if ([EAlignment.CENTER, EAlignment.RIGHT].includes(this.alignment)) {
      const stringify = (this.alignment as string).toLowerCase();
      return `progress-step__text-${stringify}`;
    }
    return '';
  }
}
</script>

<style lang="less" scoped>
section {
  display: flex;
  gap: @size-spacing-x150;
}

.progress-step {
  &--container {
    flex: 1;
  }

  &__text {
    margin-top: @size-spacing-x400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    word-break: break-word;

    &-bold {
      font-weight: 600;
    }

    &-center {
      align-items: center;
    }

    &-right {
      align-items: flex-end;
    }
  }

  &--background {
    width: 100%;
    border-radius: @size-radius-x100;
    height: 4px;
    background-color: @theme-secondary;
  }

  &__status {
    max-width: 100%;
    height: 100%;
    border-radius: @size-radius-x100;
    transition: width 500ms ease-in-out 200ms,
      background-color 500ms ease-in-out;
    background-color: @theme-primary;
  }
}
</style>
