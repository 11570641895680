<template>
  <label :class="containerClasses">
    <!-- @slot Espaço para receber o elemento input de cada especificação -->
    <slot name="input" />
    <span class="icon">
      <BaseIcon :icon="icon" colors="currentColor" />
    </span>
    <span v-if="$slots.label" class="label">
      <!-- @slot O label da opção -->
      <slot name="label" />
    </span>
  </label>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';

@Component({
  name: 'Option',
  components: { BaseIcon }
})
export default class Option extends Vue {
  /** disabled ativa a aparência desabilitado da checkbox. */
  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  /** reverse ativa o css para trocar os elementos de lado, são para caso que o Label tenha que vir antes do checkbox. */
  @Prop({ type: Boolean, default: false })
  private reverse!: boolean;

  /** o ícone a ser exibido, representando o tipo de input */
  @Prop({ type: String, required: true })
  private icon!: string;

  private get iconColor() {
    return this.disabled
      ? NebraskaColors.elementDisabled
      : NebraskaColors.elementPrimary;
  }

  private get containerClasses() {
    return {
      container: true,
      disabled: this.disabled,
      reverse: this.reverse
    };
  }
}
</script>

<style lang="less" scoped>
.container {
  .text-p-4();
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  color: @text-primary;
  z-index: 0;

  &.reverse {
    flex-direction: row-reverse;
  }

  &.disabled {
    color: @text-disabled;
    cursor: default;
    pointer-events: none;
  }
}

input {
  all: unset;
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:focus-visible {
    outline: none;

    & + .icon {
      background-color: @background-hover;
      border-color: @element-primary;
    }
  }
}

.label {
  position: relative;
  padding: 0 @size-spacing-x300;
  cursor: pointer;
  display: inline-block;
}

.icon {
  border-radius: @size-radius-max;
  border: @size-border-x500 solid transparent;
  cursor: pointer;
  display: inline-flex;
  line-height: 1em;
  padding: 10px;

  &:hover {
    background-color: @background-hover;
  }
}
</style>
