<template>
  <RoundedIcon
    v-if="hasStatus"
    data-testid="status-icon"
    :icon-name="iconConfig.name"
    :text-color="iconConfig.color"
    :aria-label="iconConfig.ariaLabel"
    main-color="transparent"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import RoundedIcon from '@/components/legacy/rounded-icon/RoundedIcon.vue';
import { InputStatusType as StatusType } from '@/foundation/types';

type IconConfig = {
  name: string;
  color: NebraskaColors;
  ariaLabel: string;
};

@Component({
  name: 'StatusTypeIcon',
  components: {
    RoundedIcon
  }
})
export default class StatusTypeIcon extends Vue {
  /**
   * Indica o status do componente e muda sua aparência.
   * @values default, error, success
   */
  @Prop({
    type: String
  })
  readonly statusType!: StatusType;

  private get hasStatus(): boolean {
    return (
      this.statusType === StatusType.Success ||
      this.statusType === StatusType.Error
    );
  }

  private get iconConfig(): IconConfig {
    switch (this.statusType) {
      case StatusType.Error:
        return {
          name: 'ic_alert_error',
          color: NebraskaColors.elementOnError,
          ariaLabel: 'erro'
        };
      case StatusType.Success:
      default:
        return {
          name: 'ic_check',
          color: NebraskaColors.elementOnSuccess,
          ariaLabel: 'sucesso'
        };
    }
  }
}
</script>
