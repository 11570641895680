<template>
  <section class="infinite-scroll-items-container">
    <div v-if="items.length" class="container-items">
      <slot v-for="item in items" v-bind="item" />
    </div>
    <div v-else-if="items.length === 0 && !showLoader" class="notfound-message">
      <BaseText size="lg" color="NebraskaColors.textSecondary">
        {{ notFoundItemsMessage }}
      </BaseText>
    </div>
    <footer>
      <div ref="infiniteScrollTrigger" class="scroll-trigger" />
      <BaseLoader
        :show-loader="showLoader"
        :whitelabel="whitelabel"
        min-height="85"
        :color="loaderColor"
      />
    </footer>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { BaseLoader } from '@/components/base-loader';
import { BaseText } from '@/components/base-text';

@Component({
  name: 'InfiniteScroll',
  components: { BaseText, BaseLoader }
})
export default class InfiniteScroll extends Vue {
  @Prop({ required: true, type: Function })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public request!: () => Promise<any>;

  @Prop({ type: Number, default: 10 })
  public maxPerPage!: number;

  @Prop({ type: String, default: 'Nenhum item encontrado!' })
  public notFoundItemsMessage!: string;

  @Prop({ type: Boolean, default: false })
  public whitelabel!: boolean;

  @Prop({ type: String })
  public loaderColor!: string;

  @Ref('infiniteScrollTrigger')
  private readonly infiniteScrollTrigger!: HTMLDivElement;

  @Prop({ type: Function })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public actionFilter!: (items: any) => any[];

  @Prop({ type: Array, default: () => [] })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public itemList!: any[];

  @Prop({ type: Number, default: 0 })
  public initialPage!: number;

  private showLoader = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private payloadItems: any[] = [];

  public currentPage!: number;

  get items() {
    const items = [...this.itemList, ...this.payloadItems];

    return this.actionFilter ? this.actionFilter(items) : items;
  }

  private registerObserver() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(async entry => {
        const hasMaxItemsPerPage =
          this.items.length >= this.currentPage * this.maxPerPage;

        if (
          !this.showLoader &&
          entry.intersectionRatio > 0 &&
          hasMaxItemsPerPage
        ) {
          await this.handleLoad();
        }
      });
    });

    observer.observe(this.$refs.infiniteScrollTrigger as Element);
  }

  private async handleLoad() {
    this.showLoader = true;
    const response = await this.request();

    if (response) {
      this.payloadItems = [...this.payloadItems, ...response];
    }

    this.showLoader = false;
    this.currentPage++;
  }

  public async beforeMount() {
    this.currentPage = this.initialPage;

    await this.handleLoad();
  }

  public updated() {
    this.registerObserver();
  }
}
</script>

<style lang="less" scoped>
.container-items {
  margin: 0 auto;
}

.notfound-message {
  display: flex;
  height: 96px;
  justify-content: center;
  align-items: center;
}

footer {
  position: relative;
}
</style>
