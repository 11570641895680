<template>
  <HelperViewFoundation
    :class="computedHelperProps"
    :icon="icon"
    :title="title"
    :description="description"
    :button-label="buttonLabel"
    :button-disabled="buttonDisabled"
    :button-loading="buttonLoading"
    @click="helperViewButtonEvent"
  />
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import HelperViewFoundation from '@/foundation/helper-view/HelperViewFoundation.vue';
import { ContextColors } from './types';

@Component({
  name: 'HelperViewContextual',
  components: {
    HelperViewFoundation
  }
})
export default class HelperViewContextual extends Vue {
  /**
   * Ícone a ser exibido no componnte
   */
  @Prop({ type: String, required: false })
  readonly icon?: string;

  /**
   * O título principal do componente
   */
  @Prop({ type: String, required: false })
  readonly title?: string;

  /**
   * O texto de apoio do componente
   */
  @Prop({ type: String, required: false })
  readonly description?: string;

  /**
   * Label do botão de ação
   */
  @Prop({ type: String, required: false })
  readonly buttonLabel?: string;

  /**
   * Habilita ou desabilita o botão de ação
   */
  @Prop({ type: Boolean, default: false })
  readonly buttonDisabled!: boolean;

  /**
   * Coloca o botão no estado de carregamento
   */
  @Prop({ type: Boolean, default: false })
  readonly buttonLoading!: boolean;

  /**
   * Define a cor de status do HelperView
   * @values neutral e brand
   */
  @Prop({ type: String, default: ContextColors.NEUTRAL })
  readonly context!: string;

  /**
   * Evento de click do botão, quando existente
   */
  @Emit('click')
  private helperViewButtonEvent() {
    // event emitter on button footer click
  }

  private get computedHelperProps() {
    return ['helper-status', `helper-status--${this.context}`];
  }
}
</script>

<style lang="less" scoped>
.initialAvatar(@background, @color) {
  ::v-deep .initial-avatar-icon {
    background: @background;
    color: @color;
  }
}

.helper-status {
  &--brand {
    .initialAvatar(@brand-secondary, @brand-primary);
  }
  &--neutral {
    .initialAvatar(@neutral-secondary, @neutral-primary);
  }
}
</style>
