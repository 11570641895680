<template>
  <div class="bank-account">
    <div
      v-if="!!document"
      class="bank-account__button"
      data-testid="bank-button-document"
      @click="onCopy(EBankField.DOCUMENT, document)"
    >
      <Row
        :left-title="parseDocument(document)"
        :left-subtitle="documentName"
        has-interaction
        :has-line="hasDivider"
      >
        <template #icon-right>
          <CopyButton
            :is-copied-out="isCopiedField(EBankField.DOCUMENT)"
            :value="parseValues(document)"
            aria-label="Copiar número do documento CNPJ ou CPF"
          />
        </template>
      </Row>
    </div>

    <div
      v-if="!!name"
      class="bank-account__button"
      data-testid="bank-button-name"
      @click="onCopy(EBankField.NAME, name)"
    >
      <Row
        :left-title="name"
        :left-subtitle="accountName"
        has-interaction
        :has-line="hasDivider"
      >
        <template #icon-right>
          <CopyButton
            :is-copied-out="isCopiedField(EBankField.NAME)"
            :value="parseValues(name)"
            aria-label="Copiar nome do titular"
          />
        </template>
      </Row>
    </div>

    <Row
      :left-title="`${bankCode} - ${bankName || getBankName}`"
      left-subtitle="Banco"
      :has-line="hasDivider"
    >
      <template v-if="showBankIcon" #icon-left>
        <BankIcon :bank-code="bankCode" />
      </template>
    </Row>

    <div
      v-if="!!agency"
      class="bank-account__button"
      data-testid="bank-button-agency"
      @click="onCopy(EBankField.AGENCY, agency)"
    >
      <Row
        :left-title="agency"
        left-subtitle="Agência"
        has-interaction
        :has-line="hasDivider"
      >
        <template #icon-right>
          <CopyButton
            :is-copied-out="isCopiedField(EBankField.AGENCY)"
            :value="parseValues(agency)"
            aria-label="Copiar número da agência"
          />
        </template>
      </Row>
    </div>

    <div
      v-if="!!account"
      class="bank-account__button"
      data-testid="bank-button-account"
      @click="onCopy(EBankField.ACCOUNT, account)"
    >
      <Row
        :left-title="account"
        left-subtitle="Conta e Dígito"
        has-interaction
        :has-line="hasDivider"
      >
        <template #icon-right>
          <CopyButton
            :is-copied-out="isCopiedField(EBankField.ACCOUNT)"
            :value="parseValues(account)"
            aria-label="Copiar número da conta e dígito"
          />
        </template>
      </Row>
    </div>

    <Row
      v-if="showAccountType"
      :left-title="accountType"
      left-subtitle="Tipo de Conta"
      :has-line="hasDivider"
    />

    <Row v-if="showTransferInfo" :has-line="false">
      <template #top-left>
        <p class="bank-account__description text-p-5">
          {{ transferInfo }}
        </p>
      </template>
    </Row>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { CopyMixin, CopyButton } from '@/components/copy-button';
import { Row } from '@/components/row';
import { BankIcon } from '@/components/legacy';
import {
  EBankCode,
  MapBankName,
  MapBankIcon,
  EBankField
} from '@/typings/Bank';

const isCPF = 11;

@Component({
  name: 'BankAccount',
  components: {
    CopyButton,
    Row,
    BankIcon
  }
})
export default class BankAccount extends mixins(CopyMixin) {
  @Prop({ type: String, required: true })
  private bankCode!: EBankCode;

  @Prop({ type: String })
  private account!: string;

  @Prop({ type: String })
  private agency!: string;

  @Prop({ type: String })
  private bankName?: string;

  @Prop({ type: String })
  private document?: string;

  @Prop({ type: String })
  private name?: string;

  @Prop({ type: Boolean, default: true })
  private showAccountType!: boolean;

  @Prop({ type: Boolean, default: true })
  private showTransferInfo!: boolean;

  @Prop({ type: String, default: 'Conta corrente' })
  private accountType!: string;

  @Prop({ type: Boolean, default: true })
  private hasDivider!: boolean;

  @Prop({
    type: String,
    default:
      '*Só é possível receber transferências de uma conta da mesma titularidade.'
  })
  private transferInfo!: string;

  private EBankField = EBankField;

  private copiedField: EBankField | string = '';

  private async onCopy(field: EBankField, value = '') {
    const parsedValue = this.parseValues(value);

    /**
     * Copied value event. Example:
     * `<BankAccount @copy="onAccountInfoCopy" />`
     * `onAccountInfoCopy(value: string, field: string) { ... }`
     * @event copy
     */
    this.$emit('copy', value, field);
    await this.copyToClipboard(parsedValue);
    this.toggleCopyAnimation(field);

    return value;
  }

  private toggleCopyAnimation(field: EBankField) {
    this.copiedField = field;
    setTimeout(() => {
      this.copiedField = '';
    }, 500);
  }

  private get getBankName() {
    return MapBankName.get(this.bankCode);
  }

  private isCopiedField(field: EBankField) {
    return this.copiedField === field;
  }

  private get showBankIcon() {
    return !!MapBankIcon.get(this.bankCode);
  }

  private get documentName() {
    if (this.document) {
      if (this.document.length === isCPF) {
        return 'Seu CPF';
      }

      return 'CNPJ';
    }

    return '';
  }

  private get accountName() {
    if (this.name) {
      if (this.document && this.document.length > isCPF) {
        return 'Razão Social';
      }
      return 'Títular';
    }
    return '';
  }

  private parseValues(str: string) {
    return str.replace(/[-/.]/g, '');
  }

  private parseDocument(str: string) {
    if (str.length === isCPF) {
      const testCpf = /(\d{3})(\d{3})(\d{3})(\d{2})/g;
      // eslint-disable-next-line no-useless-escape
      return str.replace(testCpf, '\$1.\$2.\$3\-\$4');
    } else {
      const testCnpj = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g;
      // eslint-disable-next-line no-useless-escape
      return str.replace(testCnpj, '\$1.\$2.\$3\/\$4\-\$5');
    }
  }
}
</script>

<style lang="less" scoped>
.bank-account {
  .bank-account__button {
    border: none;
    background: none;
    padding: 0px;
    width: 100%;
    margin: 0px;
  }
  ::v-deep .icon {
    margin-top: 0px;
  }
  .bank-account__logo {
    width: 56px;
  }
  ::v-deep .text-p-5 {
    color: @text-secondary;
  }
}
</style>
