<template>
  <div class="helper-view">
    <div v-if="icon" class="helper-view__icon">
      <InitialAvatarIcon :icon="icon" size="large" />
    </div>
    <div class="helper-view__title">
      <Heading as="p" :level="3">{{ title }}</Heading>
    </div>
    <div class="helper-view__description">
      <BaseText size="md" :color="NebraskaColors.elementSecondary">
        {{ description }}
      </BaseText>
    </div>
    <div v-if="buttonLabel" class="helper-view__button">
      <ButtonPrimary
        :label="buttonLabel"
        :disabled="buttonDisabled"
        :loading="buttonLoading"
        @click="helperViewButtonEvent"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { InitialAvatarIcon } from '@/components/initial-avatar';
import { Heading } from '@/components/heading';
import { BaseText } from '@/components/base-text';
import { ButtonPrimary } from '@/components/button';

@Component({
  name: 'HelperViewFoundation',
  components: {
    Heading,
    InitialAvatarIcon,
    BaseText,
    ButtonPrimary
  }
})
export default class HelperViewFoundation extends Vue {
  /**
   * Ícone a ser exibido no componnte
   */
  @Prop({ type: String, required: false })
  readonly icon?: string;

  /**
   * O título principal do componente
   */
  @Prop({ type: String, required: false })
  readonly title?: string;

  /**
   * O texto de apoio do componente
   */
  @Prop({ type: String, required: false })
  readonly description?: string;

  /**
   * Label do botão de ação
   */
  @Prop({ type: String, required: false })
  readonly buttonLabel?: string;

  /**
   * Habilita ou desabilita o botão de ação
   */
  @Prop({ type: Boolean, default: false })
  readonly buttonDisabled!: boolean;

  /**
   * Coloca o botão no estado de carregamento
   */
  @Prop({ type: Boolean, default: false })
  readonly buttonLoading!: boolean;

  readonly NebraskaColors = NebraskaColors;

  /**
   * Evento de click do botão, quando existente
   */
  @Emit('click')
  private helperViewButtonEvent() {
    // Função que emite evento click
  }
}
</script>

<style lang="less" scoped>
.helper-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 368px;

  &__icon {
    margin-bottom: @size-spacing-x400;
  }

  &__title {
    margin-bottom: @size-spacing-x300;
  }

  &__button {
    margin-top: @size-spacing-x400;
  }
}
</style>
